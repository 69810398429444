import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../../utils/helpers';
import CustomLink from './../../../components/common/CustomLink';

const TextBlock = memo(({ slice, ...props }) => {
  const {
    primary: { title, content, button_text, button_link },
    slice_type
  } = slice;

  return (
    <section className={`container ${slice_type}`}>
      <h3>{title}</h3>
      <RichText render={content.richText} serializeHyperlink={serializeHyperlink} />
      <CustomLink className="btn-activeskin" link={button_link} text={button_text} />
    </section>
  );
});

export default TextBlock;

export const query = graphql`
  fragment HomeSalonDataBodyTextBlock on PrismicHomeSalonDataBodyTextBlock {
    primary {
      title
      content {
        richText
      }
      button_text
      button_link {
        url
        type
        uid
        link_type
      }
    }
  }
`;
