import React, { memo } from 'react';
import { MenuItemWrapper } from './navbar.style';
import MenuItem from './menu-item';
import './menu.scss'

type MenuProps = {
  items: MenuItemsProps[];
  className?: string;
};

type MenuItemsProps = {
  item?: string
  item_link?: any
};

const Menu: React.FunctionComponent<MenuProps> = memo(({ items, className, ...props }) => {
  // Add all classs to an array
  const addAllClasses = ['menu-mega-salon'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <MenuItemWrapper className={addAllClasses.join(' ')} {...props}>
      {items.map(({ item, item_link }, index) => {

        return (
          <MenuItem key={index} className={`menu-item`} link={item_link} text={item} />
        )
      })}
    </MenuItemWrapper>
  )
})

export default Menu;
