import React, { memo, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { formatPrice, getModelUrl } from '../../utils/helpers';
import { Link } from 'gatsby';
import { useStore } from '../../hooks';
import { getDataSwym, removeVariantFromWishlist } from '../../utils/wishlist';
import loadable from '@loadable/component';

const ButtonAddToCart = loadable(() => import('./ButtonAddToCart'));
const AddToWishlist = loadable(() => import('./AddToWishlist'));
const Actions = loadable(() => import('./Actions'));

const GridProducts = () => {
  const { swymLoading } = useStore();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (!swymLoading) {
      getDataSwym().then((res) => {
        setProducts(res.products);
      });
    }
  }, [swymLoading]);

  return (
    <div className="wishlist__grid-products">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h6 className="text-uppercase mb-0">My Wishlist ({products.length})</h6>
        </div>

        <div className="col-md-6 text-right">
          <Actions products={products} />
        </div>
      </div>

      <div className="row my-4">
        <div className="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
          <div className="row">
            {products.map((product) => {
              const index = product?.du?.lastIndexOf('/');
              const handle = product?.du?.substr(index + 1);

              const removeFromWishlist = async () => {
                const { regid, sessionid } = await getDataSwym();
                await removeVariantFromWishlist({
                  empi: product?.empi,
                  epi: product?.epi,
                  handle,
                  regid,
                  sessionid,
                });
                setProducts((_state) => _state.filter((v) => v.empi !== product?.empi));
              };

              return (
                <div className="col-xl-3 col-lg-4 col-6 p-lg-3 p-0">
                  <LazyLoad className="h-100" throttle={500} height={300} scroll={true}>
                    <div className="wishlist__product">
                      <AddToWishlist removeFromWishlist={removeFromWishlist} />

                      <figure className="wishlist__product--image image">
                        <Link to={getModelUrl('buy', handle)} title={product?.dt}>
                          <img src={product?.iu} />
                        </Link>
                      </figure>

                      <div className="wishlist__product--name">
                        <Link to={getModelUrl('buy', handle)} title={product?.dt}>
                          {product?.dt}
                        </Link>
                      </div>

                      <div className="wishlist__product--price">
                        <span>{formatPrice(Number(product?.pr || 0))}</span>
                      </div>

                      <div className="wishlist__product--button">
                        <ButtonAddToCart product={{ ...product, handle }} />
                      </div>
                    </div>
                  </LazyLoad>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(GridProducts);
