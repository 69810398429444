import * as React from "react"
import loadable from "@loadable/component";

const Newsletter = loadable(() => import("../../../components/blog/newsletter/newsletter"))
const Posts = loadable(() => import("./posts"))
const BlogWrapper = loadable(() => import("./style"))
const Intro = loadable(() => import("./intro"))

type ArchiveBlogProps = {
  posts?: {
    edges?: [],
    pageInfo?: {
      "currentPage"?: number,
      "hasNextPage"?: boolean,
      "hasPreviousPage"?: boolean,
      "itemCount"?: number,
      "pageCount"?: number,
      "perPage"?: number,
      "totalCount"?: number
    }
  }
  data?: {
    name?: string,
    description?: any
    image?: any
    cuid: string
  },
  link?: string,
}

const ArchiveBlog: React.FunctionComponent<ArchiveBlogProps> = React.memo(({ data, posts, link, ...props }) => {
  return (
    <BlogWrapper {...props}>
      {/* <Intro data={data} /> */}
      {/* <FeaturedPosts /> */}
      <Posts title={data?.name || ""} cuid={data?.cuid} posts={posts} link={link} />
      {/* <Newsletter /> */}
    </BlogWrapper>
  )
})

export default ArchiveBlog
