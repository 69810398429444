import React, { useCallback, useEffect, useState } from 'react';
import { encode as base64_encode } from 'base-64';
import { useStore } from '../../hooks';
import debounce from 'lodash/debounce';
import { getVariantById } from '../../services/productVariant';
import { formatPrice } from '../../utils/helpers';

const LineItem = ({ line_item }) => {
  const [state, setState] = useState({
    click: false,
    show: false,
    availableQty: 0,
    inventoryPolicy: -1,
  });
  const [quantity, setQuantity] = useState(0);
  const { removeLineItemInCart, updateQuantityInCart } = useStore();

  const _collections = JSON.parse(
    line_item?.customAttributes?.filter((attr) => attr.key === '_collections')[0]?.value || '[]'
  );

  const isGiftVouchers = _collections?.findIndex((value) => value === 'Gift Vouchers') !== -1;
  // const vendors = _collections?.filter(
  //   (value) => value?.indexOf('Brands/') === 0 && value?.split('/')?.length === 2
  // );
  // const vendor = vendors?.length > 0 ? vendors[0]?.split('/')[1] : '';

  useEffect(() => {
    if (!state?.click) setQuantity(line_item.quantity);
  }, [line_item]);

  const debounceDecrementQuantity = useCallback(
    debounce((currentQty) => {
      updateQuantityInCart(line_item, currentQty);
    }, 300),
    []
  );

  function decrementQuantity() {
    const currentQty = quantity - 1;
    if (currentQty > 0) {
      setQuantity(currentQty);
      (state?.show || !state?.click) &&
        setState((_state) => ({ ..._state, show: false, click: true }));
      debounceDecrementQuantity(currentQty);
    }
  }

  const debounceIncrementQuantity = useCallback(
    debounce((currentQty, isGiftVouchers) => {
      if (isGiftVouchers) {
        updateQuantityInCart(line_item, currentQty);
        return;
      }

      if (state.inventoryPolicy !== -1) {
        if (state.inventoryPolicy === true || currentQty <= state.availableQty) {
          updateQuantityInCart(line_item, currentQty);
        } else {
          !state.show && setState((_state) => ({ ..._state, show: true }));
          setQuantity(currentQty - 1);
        }
        return;
      }

      getVariantById(base64_encode(line_item.variant.id)).then(({ data }) => {
        const { inventoryPolicy, inventoryQuantity } = data?.productVariant;
        const checkInventoryPolicy = inventoryPolicy === 'CONTINUE';

        if (checkInventoryPolicy || currentQty <= (inventoryQuantity || 0)) {
          updateQuantityInCart(line_item, currentQty);
          setState((_state) => ({
            ..._state,
            availableQty: inventoryQuantity || 0,
            inventoryPolicy: checkInventoryPolicy,
          }));
        } else {
          setState({
            availableQty: inventoryQuantity || 0,
            inventoryPolicy: checkInventoryPolicy,
            show: true,
          });
          setQuantity(currentQty - 1);
        }
      });
    }, 300),
    [state]
  );

  function incrementQuantity(isGiftVouchers = false) {
    const currentQty = quantity + 1;
    setQuantity(currentQty);
    (state?.show || !state?.click) &&
      setState((_state) => ({ ..._state, show: false, click: true }));
    debounceIncrementQuantity(currentQty, isGiftVouchers);
  }

  return (
    <li className="Line-item">
      <div className="Line-item__img">
        {line_item.variant.image ? (
          <img src={line_item.variant.image.src} alt={`${line_item.title} product shot`} />
        ) : null}
      </div>
      <div className="Line-item__content">
        <div className="Line-item__content-row d-flex">
          <h4 className="Line-item__title">
            {/* <Link className="Line-item__title__brand" to={getModelUrl('brands', vendor, true)}>
                {vendor}
              </Link> */}
            {/* <span className="title-format">{line_item.title.replace(vendor, '')}</span> */}
            <span className="title-format">{line_item.title}</span>
            {line_item?.variant?.title != 'Default Title' && (
              <div className="Line-item__sub-title">
                <small>{line_item?.variant?.title}</small>
              </div>
            )}
          </h4>

          <div className="Line-item__price">
            {formatPrice(quantity * line_item?.variant?.price?.amount)}
          </div>
        </div>
        <div className="Line-item__content-row">
          <div className="Line-item__quantity-container">
            {/* {line_item.variant.price > 0 && ( */}
            <button className="Line-item__quantity-update" onClick={decrementQuantity}>
              -
            </button>
            {/* )} */}

            <span className="Line-item__quantity">{quantity}</span>

            {/* {line_item.variant.price > 0 && ( */}
            <button
              className="Line-item__quantity-update"
              onClick={() => incrementQuantity(isGiftVouchers)}
            >
              +
            </button>
            {/* )} */}
          </div>

          <button className="Line-item__remove" onClick={() => removeLineItemInCart(line_item)}>
            X <u>Remove</u>
          </button>
          {state.show && (
            <span className="text-danger d-block">
              Can only be purchased up to {state.availableQty} products
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default React.memo(LineItem);
