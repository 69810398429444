import React, { memo, useEffect } from 'react';
import loadable from '@loadable/component';
import './style.scss';
import Breadcrumb from './Breadcrumb';
import BrandsDirectory from './BrandsDirectory';
import BrandsSiteMap from './BrandsSiteMap';

const FeaturedBrands = loadable(() => import('./FeaturedBrands'));

const BrandsContainer = (props) => {
  const { brands, page } = props;

  useEffect(() => {
    if (location?.hash) {
      setTimeout(() => {
        const element = document.getElementById(`${location?.hash?.replace('#', '')}`);
        element?.scrollIntoView();
      }, 200);
    }
  }, []);

  return (
    <div className="container-xl">
      <Breadcrumb />

      <h1 className="brands-title">{page.h1_header}</h1>

      <BrandsDirectory brands={brands} title={page?.brands_directory_header} />

      <BrandsSiteMap brands={brands} title={page?.brands_directory_header} />

      <FeaturedBrands title={page?.featured_brands_header} brands={page?.featured_brands || []} />
    </div>
  );
};

export default memo(BrandsContainer);
