import React from 'react';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';
import loadable from '@loadable/component';

const FilterCollapse = loadable(() => import('../archive-product/FilterCollapse'))

const ListHierarchicalMenu = ({ items, refine, createURL }) => (
    <ul>
        {items.map(item => (
            <li key={item.label}>
                <a
                    href={createURL(item.value)}
                    style={{ fontWeight: item.isRefined ? 'bold' : '' }}
                    onClick={event => {
                        event.preventDefault();
                        refine(item.value);
                    }}
                >
                    {item.label} ({item.count})
                </a>
                {item.items && (
                    <ListHierarchicalMenu
                        items={item.items}
                        refine={refine}
                        createURL={createURL}
                    />
                )}
            </li>
        ))}
    </ul>
);

const CustomHierarchicalMenu = ({ items, refine, createURL }) => {
    if (items.length <= 0) return null
    return (
        <FilterCollapse show={true} title="Category">
            <ListHierarchicalMenu
                items={items}
                refine={refine}
                createURL={createURL}
            />
        </FilterCollapse>
    )
}



const HierarchicalMenu = connectHierarchicalMenu(CustomHierarchicalMenu);
export default HierarchicalMenu