import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BlogPostsWrapper = styled.div`
  margin: 30px auto 0;
  position: relative;

  @media (min-width: 992px) {
      max-width: 992px !important;
  }
  
  .pagination {
    margin: 30px 0;
  }
`

export const SecTitle = styled.div`
  color: ${themeGet("colors.textColor", "#292929")};
  font-size: 16px;
  font-weight: 500;
  font-family: ${themeGet("fontFamily.0", "'Fira Sans',sans-serif")};
  letter-spacing: 0.17em;
  position: relative;
  margin-bottom: 30px;

  &:after {
    content: "";
    width: 68px;
    height: 1px;
    background: #292929;
    display: block;
    margin-top: 8px;
  }
`

export const PostRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

export const PostCol = styled.div`
  padding: 0 15px;
  .image {
    height: 330px;

    > div {
      display: block;
      overflow: unset;
      position: unset;
    }

    @media (max-width: 424.98px) {
      height: 250px;
    }
  } 

  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &:nth-child(3n + 1) {
    @media (min-width: 992px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  @media (max-width: 991.98px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
    padding: 0
  }
`

export default BlogPostsWrapper
