import React from 'react'
import { formatPrice } from '../../utils/helpers'

const KlarnaPlacement = React.memo(({ price }) => {

    return (
        <div className="afterpay-installments-amount-klarna">
            <img width={100} height={36} src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" />
            <br />4 interest free payments of {formatPrice(Math.round((price / 4) * 100) / 100)}
        </div>
    )
})

export default KlarnaPlacement