import React from 'react';

/**
 * template mail contact
 * @param {*} origin
 * @param {*} name
 * @returns
 */
const mailContact = (origin, name) =>
  `<table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        width="100%"
        style="max-width: 640px; display: block;"
    >
        <tbody className="revice-mail-body" style="text-align: center; padding: 30px; color: rgb(153, 153, 153); display: block;">
            <tr style="display: block;">
                <td style="display: block;">
                    <img
                        src="https://www.activeskin.com.au/assets/images/activeskin-logo-black.webp"
                        alt="activskin"
                    />
                </td>
            </tr>
            <tr style="padding: 10px 0px; border-bottom: 1px solid rgb(233, 226, 226); display: block;">
                <td style="display: block;">
                <ul style="list-style: none; padding-left: 0px; margin-bottom: 0px; display: flex; justify-content: space-around; font-weight: 400;">
                    <li style="width: 17%">
                        <a href="${origin}/brands" style="color: rgb(153, 153, 153);text-decoration:none;" target="_blank">
                            BRANDS
                        </a>
                    </li>
                    <li style="width: 17%">
                        <a href="${origin}/skincare" style="color: rgb(153, 153, 153);text-decoration:none;" target="_blank">
                            SKINCARE
                        </a>
                    </li>
                    <li style="width: 17%">
                        <a href="${origin}/makeup" style="color: rgb(153, 153, 153);text-decoration:none;" target="_blank">
                            MAKEUP
                        </a>
                    </li>
                    <li style="width: 17%">
                        <a href="${origin}/hair" style="color: rgb(153, 153, 153);text-decoration:none;" target="_blank">
                            HAIR
                        </a>
                    </li>
                    <li style="width: 17%">
                        <a href="${origin}/blog" style="color: rgb(153, 153, 153);text-decoration:none;" target="_blank">
                            BEATY BLOG
                        </a>
                    </li>
                </ul>
                </td>
            </tr>
            <tr style="padding-bottom: 15px; display: block;">
                <td style="display: block;">
                <ul style="list-style: none; padding-left: 0px; margin-bottom: 0px; display: flex; justify-content: space-around;">
                    <li style="width: 50%">Free Delivery over $50</li>
                    <li style="width: 50%">Sam Day Dispach*</li>
                </ul>
                </td>
            </tr>
            <tr style="display: block;">
                <td style="padding: 50px 60px 40px; font-size: 40px; color: white; background: rgb(142, 206, 198); display: block; text-transform: uppercase; font-weight: bold;">
                your contact has been received
                </td>
            </tr>
            <tr style="padding: 30px; font-size: 20px; display: block;">
                <td style="display: block;">
                <span style="color: rgb(51, 51, 51); margin-bottom: 15px; font-weight: 500;display: block;">Hi, ${name}</span>
                <span style="color: rgb(102, 102, 102); margin-bottom: 0px; font-size: 13px;display: block;">
                    We've got your contact, will contact you soon!
                </span>
                </td>
            </tr>
        </tbody>
    </table>`;

export default mailContact;
