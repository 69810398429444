import React from 'react';
import loadable from '@loadable/component';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Link } from 'gatsby';

const Layout = loadable(() => import('../layout'));
import styled from 'styled-components';
import Seo from '../components/common/Seo';

const Title = styled.h1`
  font-size: 12rem;
`;
const SubTitle = styled.p`
  font-size: 18px;
  padding-bottom: 30px;
`;

const BtnToHome = styled.button`
  background: transparent;
  border: 1px solid #989898;
  padding: 20px 60px;
  color: #333;
  text-decoration: none;
  a {
    color: #333;
    text-decoration: none;
  }
  &:hover {
    background: #333;
    color: #fff;
    a {
      color: #fff;
    }
  }
`;

const NotFoundPage = ({ location }) => (
  <Layout className="text-center 404-page" location={location}>
    <div style={{ padding: '60px 0 100px' }}>
      <Title>404</Title>
      <SubTitle style={{ fontSize: '18px' }}>
        Sorry, we couldn’t find the page you’re looking for. <br /> Let’s find a better place for
        you to go.
      </SubTitle>
      <BtnToHome>
        <Link to="/">Go to homepage</Link>
      </BtnToHome>
    </div>
  </Layout>
);

export default withPrismicUnpublishedPreview(NotFoundPage);

export const Head = ({ location, params, data, pageContext }) => {
  const seo = { title: '404: Not found', location };

  return <Seo {...seo} />;
};
