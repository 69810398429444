import * as React from "react"
import Masonry from "react-masonry-component"
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import BlogTreatmentsWrapper, {
  TreatmentsRow,
  TreatmentsCol,
  Treatment,
  TreatmentAvatar,
  TreatmentTitle,
  SecTitle
} from "./style"

type TreatmentsProps = {
  title?: string,
  treatments?: []
}

const Treatments: React.FunctionComponent<TreatmentsProps> = ({ title, treatments }) => {

  return (
    <BlogTreatmentsWrapper className="container-xl">
      {title && <SecTitle>{title}</SecTitle>}
      <TreatmentsRow>
        <Masonry className="showcase">
          {treatments.map(({ uid, url, data: { title, avatar: { gatsbyImageData, alt } } }) => {
            if (gatsbyImageData) {
              return (
                <TreatmentsCol key={uid} >
                  <Link to={url}>
                    <Treatment>
                      <TreatmentAvatar>
                        <GatsbyImage image={gatsbyImageData} alt={alt || title} />
                      </TreatmentAvatar>
                      <TreatmentTitle>
                        <h3>{title}</h3>
                      </TreatmentTitle>
                    </Treatment>
                  </Link>
                </TreatmentsCol>
              )
            }
            return null
          })}
        </Masonry>
      </TreatmentsRow>
    </BlogTreatmentsWrapper >
  )
}

export default Treatments