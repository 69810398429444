import React from 'react';
import BrandProduct from './BrandProduct';
import SendMail from './SendMail';
import { useState } from 'react';
import { setPwBrands } from '../../utils/product-password';

function EnterPassword(props) {
  const { brand, password, setState: _setState } = props;
  const [senMail, setSendMail] = useState(false);
  const [state, setState] = useState({ password: '', success: '', error: '' });

  if (senMail) return <SendMail {...props} />;

  function checkPassword() {
    event.preventDefault();
    if (password === state.password) {
      setPwBrands(brand?.data?.name, password);
      //   setState({ ...state, success: 'Success', error: '' });
      _setState((_state) => ({ ..._state, password: true }));
    } else {
      setState({ ...state, success: '', error: 'Incorrect password' });
    }
  }

  return (
    <div className="product-password">
      <div className="row">
        <div className="offset-xl-3 col-xl-6">
          <p>
            It is a request of {brand?.data?.name} to have their products password protected.
            If you're an existing customer and already have your unique password, simply enter your
            password below to repurchase at your convenience.
            <br />
            <br />
            If you are a new customer, welcome! To place an order with us please click{' '}
            <a href="#" onClick={() => setSendMail(true)}>
              here
            </a>{' '}
            and our Skin Experts will help you.
          </p>

          <form onSubmit={checkPassword} className="product-password__form text-center">
            <input
              className="product-password__form--input"
              type="text"
              name="password"
              placeholder="Enter your password to shop"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
            <input className="product-password__form--btn-enter" type="submit" value="Enter" />
            {state?.error && (
              <span className="d-block text-center text-danger">{state?.error}</span>
            )}
            {state?.success && (
              <span className="d-block text-center text-success">{state?.success}</span>
            )}
          </form>

          <div className="product-password__brand">
            <BrandProduct brand={brand} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterPassword;
