import React, { memo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { mapEdgesToNodes, serializeHyperlink } from '../../utils/helpers';
import { RichText } from 'prismic-reactjs';
import SlideDown from 'react-slidedown';

const ProductContentTabs = memo((props) => {
  const [state, setState] = useState([1]);
  const { allPrismicStaticContentBlock } = useStaticQuery(query);
  const blocks = mapEdgesToNodes(allPrismicStaticContentBlock);
  const { product, variant, isFreeGift } = props;
  const { metafields } = product;
  const isGiftCard = product?.productType === 'Gift Cards';

  const changeState = (value) => {
    event.preventDefault();
    if (!state.includes(value)) setState([...state, value]);
    else setState(state.filter((v) => v !== value));
  };

  return (
    <div className="tabs">
      <div className="main__product-view__tab-content tab-content">
        {/* content tab description */}
        {(((isGiftCard || isFreeGift) && product.descriptionHtml.length > 0) ||
          (!isGiftCard && !isFreeGift)) && (
          <>
            <div
              onClick={() => changeState(1)}
              className={`tab-title ${state.includes(1) ? 'active' : ''}`}
            >
              Description
            </div>
            <SlideDown>
              {state.includes(1) && (
                <div
                  className={`tab-pane fade show d-block`}
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />

                  {!isGiftCard && !isFreeGift && <p className="pt-3">SKU: {variant.sku}</p>}
                </div>
              )}
            </SlideDown>
          </>
        )}
        {/* ./ content tab description */}

        {!isGiftCard && !isFreeGift && (
          <>
            {/* content tab aplication */}
            <div
              onClick={() => changeState(2)}
              className={`tab-title ${state.includes(2) ? 'active' : ''}`}
            >
              Application
            </div>
            <SlideDown>
              {state.includes(2) && (
                <div
                  className={`tab-pane fade show d-block`}
                  id="application"
                  role="tabpanel"
                  aria-labelledby="application-tab"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        metafields.filter(({ key }) => key === 'application')?.[0]?.value || '...',
                    }}
                  />
                </div>
              )}
            </SlideDown>
            {/* ./ content tab aplication */}

            {/* content tab active-ingredients */}
            <div
              onClick={() => changeState(3)}
              className={`tab-title ${state.includes(3) ? 'active' : ''}`}
            >
              Active Ingredients
            </div>
            <SlideDown>
              {state.includes(3) && (
                <div
                  className={`tab-pane fade show d-block`}
                  id="active-ingredients"
                  role="tabpanel"
                  aria-labelledby="active-ingredients-tab"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        metafields.filter(({ key }) => key === 'active_ingredients')?.[0]?.value ||
                        '...',
                    }}
                  />
                </div>
              )}
            </SlideDown>
            {/* ./ content tab active-ingredients */}

            {/* content tab shipping */}
            <div
              onClick={() => changeState(4)}
              className={`tab-title ${state.includes(4) ? 'active' : ''}`}
            >
              Shipping
            </div>
            <SlideDown>
              {state.includes(4) && (
                <div
                  className={`tab-pane fade show d-block`}
                  id="shipping"
                  role="tabpanel"
                  aria-labelledby="shipping-tab"
                >
                  <RichText
                    render={
                      blocks.filter(({ data }) => data.block_id === 'product_shipping_tab')?.[0]
                        ?.data?.content?.richText || []
                    }
                    serializeHyperlink={serializeHyperlink}
                  />
                </div>
              )}
            </SlideDown>
            {/* ./ content tab shipping */}

            {/* content tab returns */}
            <div
              onClick={() => changeState(5)}
              className={`tab-title ${state.includes(5) ? 'active' : ''}`}
            >
              Returns
            </div>
            <SlideDown>
              {state.includes(5) && (
                <div
                  className={`tab-pane fade show d-block`}
                  id="returns"
                  role="tabpanel"
                  aria-labelledby="returns-tab"
                >
                  <RichText
                    render={
                      blocks.filter(({ data }) => data.block_id === 'product_returns_tab')?.[0]
                        ?.data?.content?.richText || []
                    }
                    serializeHyperlink={serializeHyperlink}
                  />
                </div>
              )}
            </SlideDown>
            {/* ./ content tab returns */}
          </>
        )}
      </div>
    </div>
  );
});

export default ProductContentTabs;

const query = graphql`
  {
    allPrismicStaticContentBlock {
      edges {
        node {
          data {
            block_id
            content {
              richText
            }
          }
        }
      }
    }
  }
`;
