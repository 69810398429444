import React, { memo } from 'react';
import { StringParam, useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { getToken } from '../../services/customer';
import loadable from '@loadable/component';
import { Link } from 'gatsby';

const AccountIcon = loadable(() => import('../../images/svg/AccountIcon'));
const WishlistIcon = loadable(() => import('../../images/svg/WishlistIcon'));

const FormNewsletter = memo((props) => {
  const [email, setEmail] = React.useState('');

  const { footer_newsletter_label, footer_newsletter_sub_label } = props;

  const [params] = useQueryParams({
    hNewsletter: withDefault(NumberParam, 0),
    result: withDefault(StringParam, ''),
  });

  const { firstName } = getToken();

  return (
    <form
      id="header-newsletter"
      action={`https://${process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT}/contact`}
      method="POST"
      className="form -form-subscribe d-lg-none d-block"
      onSubmit={() => {
        if (email.trim() == '' || email.indexOf('@') == -1) {
          alert('Please enter your email address');
          event.preventDefault();
          return;
        }
      }}
    >
      {typeof window != `undefined` && (
        <>
          <input type="hidden" name="ReturnURL" value={`${location.origin}?hNewsletter=1`} />
          <input type="hidden" name="ci_consenturl" value={location.origin} />
        </>
      )}

      <ul className="header__nav__links">
        <li className="header__nav__link">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT}/account`}
            className="image"
          >
            <AccountIcon white={true} witdh={32} height={32} />
            {firstName ? <span>Hi {firstName}</span> : <span>Login</span>}
          </a>
        </li>
        <li className="header__nav__link">
          <Link to="/wishlist" className="image">
            <WishlistIcon white={true} witdh={32} height={32} />
            <span>Wishlist</span>
          </Link>
        </li>
      </ul>

      <div className="form__header">
        <label htmlFor="input-header-newsletter">{footer_newsletter_label}</label>
        <p className="des-newletter">{footer_newsletter_sub_label}</p>
      </div>
      {params.result === 'success' && params.hNewsletter ? (
        <p style={{ fontSize: '14px', color: '#6acac6', fontWeight: 'bold' }}>
          Hooray! You're on the a-list
        </p>
      ) : (
        <div className="form__main">
          <input type="hidden" name="form_type" value="customer" />
          <input type="hidden" name="contact[tags]" defaultValue="newsletter" />
          <input
            id="input-header-newsletter"
            placeholder="Enter your email here"
            name="contact[email]"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="btn--subscribe">
            subscribe
          </button>
        </div>
      )}
    </form>
  );
});
export default FormNewsletter;
