import React from 'react'

const Banner = React.memo(({ title }) => {

    return (
        <div className="salon-static-page__banner">
            <div className='container-xl'>
                <h1 className='salon-static-page__banner__title'>{title}</h1>
            </div>
        </div>
    )
})

export default Banner