import React, { useState, memo, useEffect } from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import { formatNameSubBrand } from '../../utils/helpers';

const ReactSlick = loadable(() => import('./../common/ReactSlick'));

const SubCategorySlider = memo(({ subcategories = [], ...props }) => {
  const [state, setState] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const len = subcategories.length;

  const settings = {
    arrows: true,
    dots: false,
    slidesToShow: 6,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    if (isBrowser) {
      const w = window.innerWidth;
      if (w < 576) setState(2);
      if (w < 768) setState(3);
      if (w < 1024) setState(4);
      else setState(0);
    }
  }, []);

  const renderItem = () => {
    if (state <= 0 || len <= state) {
      return subcategories?.map(({ name, url }, categoryIndex) => (
        <div key={categoryIndex}>
          <Link to={`/${url}`} activeClassName="active">
            <div className="sub-categories__item">{formatNameSubBrand(name, props.name)}</div>
          </Link>
        </div>
      ));
    }

    const count = Math.ceil(len / 2);
    const top = subcategories?.filter((v, i) => i < count);
    const bottom = subcategories?.filter((v, i) => i >= count);
    const data = [];

    for (let i = 0; i < count; i++) {
      data.push(
        <div className="item" key={i}>
          {top[i] && (
            <div>
              <Link to={`/${top[i]?.url}`} activeClassName="active">
                <div className="sub-categories__item">
                  {formatNameSubBrand(top[i]?.name, props.name)}
                </div>
              </Link>
            </div>
          )}

          {bottom[i] && (
            <div>
              <Link to={`/${bottom[i]?.url}`} activeClassName="active">
                <div className="sub-categories__item">
                  {formatNameSubBrand(bottom[i]?.name, props.name)}
                </div>
              </Link>
            </div>
          )}
        </div>
      );
    }

    return data;
  };

  return (
    <div className="sub-categories">
      <div className="offset-lg-1 col-lg-10 px-0">
        {state != null && len > 0 && <ReactSlick {...settings}>{renderItem()}</ReactSlick>}
      </div>
    </div>
  );
});

export default SubCategorySlider;
