import React, { memo, useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { navigate } from 'gatsby-link';
import { checkProductHasCollection, getModelUrl, overwriteHidePrice } from '../../utils/helpers';
import { getProductById, getVariantById } from '../../services/productVariant';

const ButtonAddToCart = memo(({ product: { epi, empi, handle, dt } }) => {
  const { checkout, addVariantToCart, isCartOpen, metafields } = useStore();
  const [state, setState] = useState('Add To Cart');
  const variantId = `gid://shopify/ProductVariant/${epi}`;

  const no_dispatch =
    metafields[empi]?.filter(({ key }) => key === 'no_dispatch')?.[0]?.value || '';

  const purchase_method =
    metafields[empi]?.filter(({ key }) => key === 'purchase_method')?.[0]?.value || '';

  const [inventory, setInventory] = useState({
    policy: null,
    quantity: -1,
    comingSoon: null,
    collections: [],
    productType: '',
    vendor: '',
  });

  let hide_price = metafields[empi]?.filter(({ key }) => key === 'hide_price')?.[0]?.value || false;
  hide_price = overwriteHidePrice(inventory.vendor, hide_price, purchase_method);

  const isRidirectDetail = checkProductHasCollection(inventory?.collections, 'title', [
    'Free Gifts',
    'Special Price',
    'Special Gifts',
  ]);

  const isGiftCards = inventory?.productType === 'Gift Cards';

  useEffect(() => {
    if (empi) {
      getProductById(`gid://shopify/Product/${empi}`).then((data) => {
        if (!data) return;

        const { productType, variant, collections, vendor } = data || {};
        setInventory((_state) => ({
          ..._state,
          vendor,
          productType,
          collections,
          quantity: variant?.quantityAvailable || 0,
        }));
      });
    }
  }, [empi]);

  useEffect(() => {
    if (isCartOpen && state === 'Adding...') {
      setTimeout(() => {
        setState('Added');
      }, 1000);
      return () => {
        setTimeout(() => {
          setState('Add To Cart');
        }, 3000);
      };
    }
  }, [isCartOpen, state]);

  async function handleGetVariantById() {
    return await getVariantById(variantId).then(({ data }) => {
      const { inventoryPolicy, inventoryQuantity, product } = data?.productVariant || {};
      const active = product?.status === 'ACTIVE';
      const policy = inventoryPolicy === 'CONTINUE';
      const comingSoon =
        active &&
        policy &&
        (no_dispatch?.indexOf('business days') !== -1 ||
          ['Pre-Order', 'Ready'].includes(no_dispatch));

      setInventory({
        policy,
        quantity: inventoryQuantity,
        comingSoon,
      });

      return { active, policy, comingSoon, quantity: inventoryQuantity };
    });
  }

  const handleAddToCart = async () => {
    event.preventDefault();

    if (
      purchase_method === 'Contact to Order' ||
      purchase_method === 'Hide Price' ||
      purchase_method === 'Phone To Order' ||
      hide_price ||
      purchase_method === 'Book a Consult' ||
      isRidirectDetail
    ) {
      navigate(getModelUrl('buy', handle));
    } else if (state === 'Add To Cart') {
      if (isGiftCards) {
        addVariantToCart(inventory, variantId, 1);
      } else {
        let data;
        if (inventory.policy === null) {
          data = await handleGetVariantById();
        } else {
          data = inventory;
        }
        const { policy, comingSoon, quantity } = data;

        if (policy || comingSoon) {
          let status = 'Dispatches in 3 - 5 business days';
          if (no_dispatch) {
            if (no_dispatch.indexOf('business days') !== -1) {
              status = `Dispatches in ${no_dispatch}`;
            } else status = no_dispatch;
          }
          addVariantToCart(inventory, variantId, 1, false, status);
        } else if (quantity > 0) {
          const item = checkout.lineItems.find((v) => v.variant.id === variantId);
          const realQuantity = quantity - Number(item?.quantity || 0);

          if (realQuantity >= 1) {
            setState('Adding...');
            addVariantToCart(inventory, variantId, 1, false, 'Ready to dispatch');
          } else {
            navigate(getModelUrl('buy', handle));
          }
        } else {
          navigate(getModelUrl('buy', handle));
        }
      }
    }
  };

  return (
    <a href="#" onClick={handleAddToCart} title={dt} className="text-uppercase">
      {state}
    </a>
  );
});

export default ButtonAddToCart;
