const axios = require('axios');
const { storeFontApi, accessToken } = require('../constants/shopify');

const request = axios.create({
  baseURL: storeFontApi,
  timeout: 5000,
  headers: {
    'Content-type': 'application/json',
    'X-Shopify-Storefront-Access-Token': accessToken,
  },
});

exports.fetchCheckout = async function (checkoutId, dmpt = false) {
  const query = `mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        id
        lineItems(first: 250) {
            nodes {
                id
                customAttributes {
                    key
                    value
                }
                variant {
                    id
                }
            }
        }
      }
      checkoutUserErrors {
        code
      }
    }
  }`;

  const {
    data: { data, errors },
  } = await request.post(
    '/',
    JSON.stringify({
      query,
      variables: { checkoutId, input: { customAttributes: [{ key: 'id', value: checkoutId }] } },
    })
  );

  if (dmpt) return { data, errors };

  return data?.checkoutAttributesUpdateV2;
};

exports.checkoutLineItemsRemove = async function (checkoutId, lineItemIds = []) {
  const query = `mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
      }
    }
  }`;

  const {
    data: { data },
  } = await request.post(
    '/',
    JSON.stringify({
      query,
      variables: { checkoutId, lineItemIds },
    })
  );

  return data?.checkoutLineItemsRemove;
};
