import React, { memo } from 'react';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

const AddToWishlist = memo(({ removeFromWishlist }) => {
  return (
    <div className="wishlist__product--icon">
      <span className={'text-info'} onClick={removeFromWishlist}>
        <FavoriteOutlinedIcon fontSize="large" />
      </span>
    </div>
  );
});

export default AddToWishlist;
