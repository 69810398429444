import React, { forwardRef, memo, useCallback, useState } from 'react';
import Slick from 'react-slick';

const ReactSlick = forwardRef(({ children, ...props }, ref) => {
  const settings = {
    centerMode: false,
    arrows: false,
    dots: false,
    infinite: false,
    swipeToSlide: true,
    lazyLoad: true,
    slidesToScroll: 1,
    initialSlide: 0,
    focusOnSelect: true,
    ...props,
  };

  const [swiped, setSwiped] = useState(false);

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (swiped) {
        e.stopPropagation();
        e.preventDefault();
        setSwiped(false);
      }
    },
    [swiped]
  );

  return (
    <Slick ref={ref} onSwipe={handleSwiped} {...settings}>
      {React.Children.map(children, (child) => (
        <div onClickCapture={handleOnItemClick}>{child}</div>
      ))}
    </Slick>
  );
});

export default memo(ReactSlick);
