import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createPrismicLink } from 'apollo-link-prismic';

const OverrideCache = new Proxy(new InMemoryCache(), {
  get(target, name, receiver) {
    // console.info(`Accessing ${name} on ${target}`);
    return Reflect.get(target, name, receiver);
  },
});

const apolloClient = new ApolloClient({
  link: createPrismicLink({
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    // Provide your access token if your repository is secured.
    // accessToken: "YOUR_ACCESS_TOKEN",
    fetch: fetch,
  }),
  cache: OverrideCache,
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
