import React, { memo, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { StringParam, useQueryParams, withDefault, NumberParam } from 'use-query-params';

const Alist = memo((props) => {
    const [dataForm, setDataForm] = useState({ first_name: "", email: "" });

    const [params,] = useQueryParams({
        pageNewsletter: withDefault(NumberParam, 0),
        result: withDefault(StringParam, "")
    });

    return (
        <div className="page static_page page-alist container">
            <div className="page__banner">
                <div className="page__banner__image">
                    <StaticImage src="../../../images/alist.jpg" alt="banner alist page" />
                </div>
            </div>

            <div className="page__newsletter m-auto">
                <form
                    id="page-newsletter"
                    action="https://mail.activeskin.com.au/signup.ashx"
                    method="POST"
                    onSubmit={() => {
                        const { email, first_name } = dataForm

                        if (first_name.trim() == '') {
                            alert('Please enter your first name.');
                            event.preventDefault();
                            return;
                        }

                        if (email.trim() == '' || email.indexOf('@') == -1) {
                            alert('Please enter your email address.');
                            event.preventDefault();
                            return;
                        }
                    }}
                >
                    <h3>Join the  Activeskin A-list</h3>

                    <div>
                        {typeof window != `undefined` && <div>
                            <input type="hidden" name="ReturnURL" value={`${location.origin}${location.pathname}?pageNewsletter=1`} />
                            <input type="hidden" name="ci_consenturl" value={`${location.origin}${location.pathname}`} />
                        </div>}
                    </div>
                    <input type="hidden" name="userid" defaultValue={269233} />
                    <input type="hidden" name="SIGce9446fb862b99d2dd6ceea1023fef6fdf5f8d8c1adba3d50d78fcc6f9ddf09d" defaultValue="" />
                    <input type="hidden" name="addressbookid" defaultValue={2061434} />
                    <div>
                        {
                            params.result === "success" && params.pageNewsletter ?
                                <p style={{ fontSize: "14px", color: "#6acac6", fontWeight: "bold" }}>Hooray! You're on the a-list</p>
                                : <div>
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder="*First Name"
                                        value={dataForm.email}
                                        onChange={(e) => setDataForm({ ...dataForm, first_name: e.target.value })}
                                        required
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="*Email"
                                        value={dataForm.email}
                                        onChange={(e) => setDataForm({ ...dataForm, email: e.target.value })}
                                        required
                                    />
                                    <button type="submit" className="page__newsletter__btn-submit btn-page">SUBSCRIBE</button>
                                </div>
                        }
                    </div>
                </form>
            </div>

            <div className="page__content single-content">
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            We're Rolling out the red
                            <span style={{ textDecoration: 'line-through' }}>carpet</span> lipstick for YOU!<br />
                            <br /> - Member only access to all sales &amp; promotions
                            <br /> - Insider access to new product launches
                            <br /> - Professional beauty advice from our team of Skin Experts
                            <br /> - Receive Birthday Bucks on us
                            <br />- First access to tips &amp; inspiration on our Beauty Blog
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Alist
