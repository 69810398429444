import React, { memo } from "react";
import { Link } from "gatsby";

const Breadcrumb = ({ label }) => {

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white pl-0">
                <li className="breadcrumb-item">
                    <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to='/salon'>Salon</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">{label}</li>
            </ol>
        </nav>
    )

}

export default memo(Breadcrumb)