import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { customerResetPassowd } from '../../services/customer';

const ResetPassword = (props) => {
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({ email: '' });
  const [message, setMessage] = useState({ success: '', errors: [], error: '' });

  const handleResetPassword = async () => {
    event.preventDefault();
    if (!submit) {
      setSubmit(true);
      const { status, data, message } = await customerResetPassowd(form.email);
      if (message === 'success') {
        setMessage({ success: 'Please check your email.' });
      } else if (status === 500) {
        setMessage({ error: message });
      } else {
        setMessage({ error: 'Error! Please try again later.' });
      }
      setSubmit(false);
    }
  };

  const setValueField = (e) => {
    setForm((_form) => ({ ..._form, [e.target.name]: e.target.value }));
  };

  const messageError = (field) => {
    if (message?.errors?.length > 0) {
      const error = message.errors.find(
        (error) => error?.field?.length >= 2 && error.field[1] === field
      );
      if (error) return <span className="d-block text-danger">{error?.message}</span>;
    }
    return;
  };

  return (
    <div className="customer --reset-password">
      <h4 className="customer--title">Reset password</h4>
      <div className="customer--sub-title">We will send you an email to reset your password</div>
      <div className="customer__innner">
        {message?.success ? (
          <span className="d-block text-success">{message?.success}</span>
        ) : (
          <form onSubmit={handleResetPassword} className="customer__form">
            <div className="form-group">
              <label>
                Email address <span className="text-danger">*</span>
              </label>
              <input
                onChange={setValueField}
                name="email"
                className="form-control"
                type="email"
                required
                readOnly={submit}
              />
              {messageError('email')}
            </div>
            <div className="customer__btn-submit d-flex align-items-center ">
              <button className="btn-activeskin primary" type="submit" disabled={submit}>
                Submit
              </button>
              {submit && <CircularProgress className="ml-2" size={20} />}
            </div>
            {message?.error && <span className="d-block text-danger">{message?.error}</span>}
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
