import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useStore } from '../../hooks';
import {
  customerAccessTokenCreate,
  setToken,
  getUrlMultypass,
  isLogged,
  getInfoCustomer,
} from '../../services/customer';
import { dmptSetEmail } from '../../utils/dmpt';

import { getCheckoutId, setCheckoutId } from '../../utils/firebase';
import { getDataSwym, guestValidateSync } from '../../utils/wishlist';

const Login = (props) => {
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({ email: '', password: '' });
  const [message, setMessage] = useState({ success: '', error: '' });
  const {
    checkout: { lineItems },
  } = useStore();

  const handleLogin = async () => {
    event.preventDefault();
    if (!submit) {
      setSubmit(true);
      const data = await customerAccessTokenCreate(form);
      if (!data?.customerAccessToken?.accessToken) {
        setMessage({ error: 'Incorrect email or password!' });
      } else {
        try {
          const info = await getInfoCustomer(data?.customerAccessToken?.accessToken);
          setToken({ ...data?.customerAccessToken, ...info });
          dmptSetEmail(form.email);

          const { regid, products } = await getDataSwym();
          if (regid && products?.length > 0) {
            await guestValidateSync(regid, form.email);
          }

          console.log('checkout: get checkoutId');
          const checkoutId = await getCheckoutId(form.email);
          if (checkoutId) {
            console.log('checkout: exist checkoutId ==> save checkoutId to localstorage');
            if (lineItems?.length > 0) {
              console.log('checkout: save checkout products current to localstorage');
              localStorage.setItem('checkout_products', JSON.stringify(lineItems));
            }
            localStorage.setItem('checkoutId', checkoutId);
            setCookieHasDomain('gatsby-checkout-id', checkoutId);
          } else {
            console.log('checkout: not exist checkoutId ==> set checkoutId to firebase');
            await setCheckoutId({
              id: localStorage.getItem('checkoutId'),
              email: form.email,
            });
          }
        } catch (error) {
          console.log('Login sync error:', error);
        }

        const url = await getUrlMultypass(form.email);
        setMessage({ success: 'Logged in successfully!' });
        if (url && isLogged()) {
          const hash = location.hash || '';
          if (hash?.indexOf('return_url') !== -1 && hash?.indexOf('account') !== -1) {
            location.href = 'https://shop.activeskin.com.au/account';
            return;
          }
          location.href = url;
        }
      }
      setSubmit(false);
    }
  };

  const setValueField = (e) => {
    setForm((_form) => ({ ..._form, [e.target.name]: e.target.value }));
  };

  return (
    <div className="customer --login">
      <h4 className="customer--title">Existing customers</h4>
      <div className="customer__inner">
        <form onSubmit={handleLogin} className="customer__form" style={{ marginBottom: '15px' }}>
          <div className="form-group">
            <label>
              Email address <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              name="email"
              onChange={setValueField}
              type="email"
              required
              readOnly={submit}
            />
          </div>
          <div className="form-group">
            <label>
              Password <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              name="password"
              onChange={setValueField}
              type="password"
              required
              readOnly={submit}
            />
          </div>

          <div className="customer__btn-submit d-flex align-items-center mb-0">
            <button disabled={submit} type="submit" className="btn-activeskin primary">
              Continue
            </button>
            {submit && <CircularProgress className="ml-2" size={20} />}
          </div>
          {message?.error && <span className="d-block text-danger">{message?.error}</span>}
          {message?.success && <span className="d-block text-success">{message?.success}</span>}
        </form>
        <div className="customer__links-more">
          <a href="#register">Create an account</a>
          <a href="#reset-password">Forgot your password?</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
