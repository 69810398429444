import { connectRefinementList } from 'react-instantsearch-dom';
import React from 'react'
import loadable from "@loadable/component";

const FilterCollapse = loadable(() => import('./../archive-product/FilterCollapse'))

const CustomRefinementList = ({ items, refine, searchForItems, title, show, attribute, ...props }) => {
    if (items.length <= 0) return null
    if (attribute === "colour") {
        return (
            <FilterCollapse className='--colour' title={title}>
                <ul>
                    <li className='d-none'>
                        <input
                            type="search"
                            onChange={event => searchForItems(event.currentTarget.value)}
                        />
                    </li>
                    {
                        items.map((item, index) => (
                            <li key={index}>
                                <span
                                    className={`color`}
                                    style={{ background: item.value.color }}
                                    onClick={event => {
                                        event.preventDefault();
                                        refine(item.value);
                                    }}
                                />
                                <span className={`${item.isRefined ? 'active' : ''}`}>
                                    {item.label}<span className='num'>({item.count})</span>
                                </span>
                            </li>
                        ))
                    }
                </ul>
            </FilterCollapse>
        )
    }

    return (
        <FilterCollapse className='--checkbox' title={title}>
            <ul>
                <li className='d-none'>
                    <input
                        type="search"
                        onChange={event => searchForItems(event.currentTarget.value)}
                    />
                </li>
                {
                    items.map((item, index) => (
                        <li key={index}>
                            <span
                                className={`${item.isRefined ? 'active' : ''}`}
                                onClick={event => {
                                    event.preventDefault();
                                    refine(item.value);
                                }}
                            >
                                {item.label}<span className='num'>({item.count})</span>
                            </span>
                        </li>
                    ))
                }
            </ul>
        </FilterCollapse>
    )
}

const RefinementList = connectRefinementList(CustomRefinementList);
export default RefinementList