import React, { memo } from 'react';
import loadable from '@loadable/component';

const Product = loadable(() => import('./Product'));

const GridProducts = memo(({ currentPage, totalItem, sortBy, products, setCounter }) => {
  /**
   * hanlle render products
   */
  const handleRenderProducts = () => {
    if (!sortBy) return [];

    let newProducts = JSON.parse(JSON.stringify(products));
    switch (sortBy[0]) {
      case 'best_sellers':
        newProducts?.sort((a, b) => {
          return a.best_sellers - b.best_sellers;
        });
        break;
      case 'newest':
        newProducts?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        break;
      case 'in_stock':
        newProducts?.sort((a, b) => {
          return b.totalInventory - a.totalInventory;
        });
        break;
      case 'name':
        newProducts?.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
        break;
      case 'price_asc':
        newProducts?.sort((a, b) => {
          return a.variants[0].price - b.variants[0].price;
        });
        break;
      case 'price_desc':
        newProducts?.sort((a, b) => {
          return b.variants[0].price - a.variants[0].price;
        });
        break;

      default:
        break;
    }

    // const skip = (currentPage - 1) * totalItem;

    // return newProducts?.slice(skip, skip + totalItem);
    return newProducts?.slice(0, currentPage * totalItem);
  };

  const _products = handleRenderProducts();

  return _products?.map((product, index) => (
    <Product key={product.legacyResourceId} product={product} setCounter={setCounter} />
  ));
});

export default GridProducts;
