import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from "@loadable/component";
import Lazyload from 'react-lazyload';
import { graphql } from 'gatsby';

const CustomLink = loadable(() => import('../../../components/common/CustomLink'))

const BrandsBlock = React.memo(({ slice, ...props }) => {

    const { primary: { brands_group_title, brands_group_button_text, brands_group_button_link }, items } = slice

    return (
        <Lazyload throttle={500} height={300} scroll={true}>
            <div className="main__trending-brands container-xl">
                <div className="main__trending-brands__title -title">
                    <h2>{brands_group_title}</h2>
                </div>
                <div className="main__trending-brands__list row">
                    {
                        items.map(({ brand_logo, brand_link }, i) => (
                            <div key={i} className="col-lg-2 col-sm-4 col-6">
                                <CustomLink
                                    link={brand_link}
                                    text={<GatsbyImage image={brand_logo.gatsbyImageData} alt={brand_logo?.alt || "activeskin"} />}
                                />
                            </div>
                        ))
                    }
                </div>

                <div className="main__trending-brands__btn-view-all text-center">
                    <CustomLink link={brands_group_button_link} className="btn-activeskin" text={brands_group_button_text} />
                </div>
            </div>
        </Lazyload>
    )

})

export default BrandsBlock

export const query = graphql`
  fragment HomeSalonDataBodyBrandsBlock on PrismicHomeSalonDataBodyBrandsBlock {
      primary {
        brands_group_title
        brands_group_button_text
        brands_group_button_link {
          url
          uid
          type
          link_type
        }
      }
      items {
        brand_logo {
          gatsbyImageData
          alt
        }
        brand_link {
          url
          type
          uid
          link_type
        }
      }
  }
`