import React, { memo } from 'react';
import { Link } from 'gatsby';
import { getImgCropShopify, getModelUrl, loaded } from '../../utils/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ProductImage = memo(({ product, images }) => {
  // if (!loaded) return null;
  const isPC = loaded && window.innerWidth >= 992;

  const len = images.length;

  return (
    <figure className="grid__product__image image">
      <Link to={getModelUrl('buy', product.handle)} title={product.title}>
        {len >= 1 && (
          <GatsbyImage image={getImage(images[0])} alt={images[0]?.altText || product?.title} />
        )}
      </Link>

      {isPC && len > 1 && (
        <Link
          className="image--hover"
          to={getModelUrl('buy', product.handle)}
          title={product.title}
        >
          {images[1]?.gatsbyImageData ? (
            <GatsbyImage image={getImage(images[1])} alt={images[1]?.altText || product?.title} />
          ) : (
            <img
              src={getImgCropShopify(images[1].src, '280x280')}
              alt={images[1]?.altText || product?.title}
            />
          )}
        </Link>
      )}
    </figure>
  );
});

export default ProductImage;
