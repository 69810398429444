import loadable from '@loadable/component';
import React, { useRef, useState, useEffect } from 'react';
import { ALGOLIA_INDEX_PREFIX } from '../../constants/algolia';

const SortByDefault = loadable(() => import('./SortByDefault'));

const SortBy = React.memo((props) => {
  const box = useRef(null);

  const { state, setState, refWrapper, setIndexName, indexName } = props;
  const [collapsed, setCollapsed] = useState(false);

  const handleChange = (item) => {
    setCollapsed(false);
    setState([item.key, item.label]);
    setIndexName(item.value);
  };

  useEffect(() => {
    if (refWrapper && refWrapper.current) {
      if (collapsed) refWrapper.current.classList.add('active');
      else refWrapper.current.classList.remove('active');
    }
  }, [collapsed, refWrapper]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (box.current && !box.current.contains(event.target)) {
        setCollapsed(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [box]);

  return (
    <div className="sort-by" ref={box}>
      <a
        id="sort-by__selector"
        className="sort-by__selector"
        onClick={() => setCollapsed(!collapsed)}
      >
        {state[1]}
      </a>

      {collapsed && (
        <div id="sort-by__options" className="sort-by__options">
          <div className="content">
            <div className="mobile-title">
              <span>Sort By</span>
              <button onClick={() => setCollapsed(false)} className="close"></button>
            </div>
            <SortByDefault
              defaultRefinement={indexName}
              items={[
                {
                  value: `${ALGOLIA_INDEX_PREFIX}activeskin`,
                  key: 'relevance',
                  label: 'Relevance',
                },
                { value: `${ALGOLIA_INDEX_PREFIX}activeskin_name_asc`, key: 'name', label: 'Name' },
                {
                  value: `${ALGOLIA_INDEX_PREFIX}activeskin_price_asc`,
                  key: 'price_asc',
                  label: 'Price: Low to high',
                },
              ]}
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default SortBy;
