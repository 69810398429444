import React, { useState, useEffect, memo } from 'react'
import { Controller, Scene } from 'react-scrollmagic';

const Scrollmagic = memo(({ children }) => {
    const [duration, setDuration] = useState(0)

    useEffect(() => {

        const checkClick = setInterval(() => {
            if (typeof window !== "undefined" && window?.innerWidth >= 992) {
                let el = document.getElementsByClassName('tab-title');
                if (el.length > 0) {
                    clearInterval(checkClick);
                    for (let i = 0; i < el.length; i++) {
                        el[i].addEventListener('click', () => {
                            if (document?.getElementsByClassName("scrollmagic-pin-spacer")?.length > 0) {
                                document.getElementsByClassName("scrollmagic-pin-spacer")[0].style.paddingTop = 0;
                                document.getElementsByClassName("scrollmagic-pin-spacer")[0].style.paddingBottom = 0;
                            }

                            setTimeout(() => {
                                let sidebarContent = document.getElementById("product-review").clientHeight
                                let _duration = document.getElementById("product-detail").clientHeight - sidebarContent
                                setDuration(_duration)
                            }, 500);
                        })
                    }
                }
            } else {
                clearInterval(checkClick);
            }

        }, 500);

        function handleChangeDuration() {

            if (window?.innerWidth >= 992) {
                let sidebarContent = document.getElementById("product-review").clientHeight
                let _duration = document.getElementById("product-detail").clientHeight - sidebarContent
                setDuration(_duration)
                window?.removeEventListener("scroll", handleChangeDuration)
            }
        }

        window?.addEventListener("scroll", handleChangeDuration)

        return () => {
            window?.removeEventListener("scroll", handleChangeDuration)
        }
    }, [])

    return (
        <Controller>
            <Scene
                duration={duration}
                pin='#product-review'
                triggerElement="#product-detail"
                triggerHook={"onLeave"}
                enabled={duration > 0 && ((window?.innerWidth || 0) >= 992)}
            >
                {children}
            </Scene>
        </Controller>
    )
})

export default Scrollmagic
