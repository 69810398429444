import { RichText } from 'prismic-reactjs';
import React, { memo, useState } from 'react';
import { serializeHyperlink } from '../../utils/helpers';

const Desciption = memo(({ data }) => {
  const [state, setState] = useState(false);

  if (!data?.text || data?.text?.length < 1) return null;
  else {
    const { richText } = data;
    return (
      <div className="description">
        {richText.length > 1 ? (
          <div className={state ? '' : richText[0].type === 'paragraph' ? 'text-1' : 'text'}>
            <RichText render={richText} serializeHyperlink={serializeHyperlink} />
          </div>
        ) : (
          <div className={state ? '' : 'text-format'}>
            <RichText render={richText} serializeHyperlink={serializeHyperlink} />
          </div>
        )}

        <a
          href="#"
          onClick={() => {
            event.preventDefault();
            setState(!state);
          }}
        >
          Read {state ? 'Less' : 'More'}
        </a>
      </div>
    );
  }
});

export default Desciption;
