import { Link } from 'gatsby';
import React, { memo } from 'react';
import { useStore } from '../../hooks';
import { getImgCropShopify, getModelUrl } from '../../utils/helpers';

const LineItem = ({ line_item }) => {
  const { removeLineItemInCart } = useStore();

  return (
    <tr className="line-item --free-gift">
      <td className="line-item--image">
        {line_item?.variant?.image ? (
          <img
            src={getImgCropShopify(line_item.variant.image.src, '75x75')}
            alt={`${line_item.title} product shot`}
          />
        ) : null}
      </td>
      <td className="line-item--name">
        <Link to={getModelUrl('buy', line_item.variant?.product?.handle?.replace('buy-', ''))}>
          {line_item.title}
        </Link>
      </td>
      <td className="line-item--price">$0.00</td>
      <td className="line-item--quantity"></td>
      <td className="line-item--subtotal"></td>
      <td className="line-item--remove">
        <button onClick={() => removeLineItemInCart(line_item)}>
          X <u>Remove</u>
        </button>
      </td>
    </tr>
  );
};

export default memo(LineItem);
