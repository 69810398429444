import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { memo } from 'react';

const ListVariants = (props) => {
  const { product, setVariant, className, children, images } = props;

  return (
    <div
      className={`main__product-view__${className || 'list-variants'} ${
        className || 'list-variants'
      }`}
    >
      {children}

      <ul className={`${className || 'list-variants'}__list`}>
        {product?.variants?.map((v, i) => {
          const titleVariant =
            v?.selectedOptions?.filter((v) => v.name == 'Color')?.[0]?.value || v?.title;

          let image =
            images.find(
              (img) => img?.altText && img?.altText.trim() === `swatch_${titleVariant}`
            ) || undefined;

          if (!image) {
            image = v.image;
          }

          return (
            <li
              key={i}
              className={`${className || 'list-variants'}__list__item item`}
              onClick={() => setVariant(v)}
            >
              {image && (
                <div className="item__image">
                  <GatsbyImage image={getImage(image)} alt={image.altText || v.title} />
                </div>
              )}
              <span className="item__name">{titleVariant}</span>
            </li>
          );
        })}
      </ul>
      <div className="clearfix"></div>
    </div>
  );
};

export default memo(ListVariants);
