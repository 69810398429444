import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../../utils/helpers'
import CustomLink from './../../../components/common/CustomLink';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const TextBlock = memo(({ slice }) => {

  const { items, slice_type } = slice

  return items.map(({ tb_title, tb_meta_title, tb_content, tb_image, tb_image_location, button_text, button_link }, i) => (
    <section key={i} className={`container ${slice_type} ${tb_image_location}`}>
      <div className="row">
        {tb_image?.gatsbyImageData && <div className="col-md-6">
          <GatsbyImage image={getImage(tb_image)} alt="activeskin" />
        </div>}

        <div className="col">
          <h3 className="title">{tb_title}</h3>
          <p className="meta-title">{tb_meta_title}</p>
          <div className="content">
            <RichText render={tb_content.richText} serializeHyperlink={serializeHyperlink} />
          </div>
          {button_text && <CustomLink className='btn-activeskin' link={button_link} text={button_text} />}
        </div>
      </div>
    </section>
  ))
})

export default TextBlock

export const query = graphql`
  fragment SalonStaticPagesDataBodyTextBlock on PrismicSalonStaticPagesDataBodyTextBlock {
    items {
      tb_title
      tb_meta_title
      tb_image_location
      tb_image {
        gatsbyImageData
        alt
      }
      tb_content {
        richText
      }
    }
  }
`