import React, { createContext, useEffect, useState } from 'react';
import Client from 'shopify-buy';
import { setCookieSwym, getSwymRegId } from '../utils/wishlist';
import { getAllRules } from '../utils/query';
import useDataCommon from '../hooks/useDataCommon';
import { encode as base64_encode } from 'base-64';
import { isLogged, setToken } from '../services/customer';
import { checkExpried, getCookie, loaded, setCookieHasDomain } from '../utils/helpers';
import { titleCombination } from '../utils/titleCombination';
import { useProductMetafields } from '../hooks/useProductMetafields';
import { useFiltersMetafields } from '../hooks/useFiltersMetafields';
import { accessToken, domainCheckout } from '../constants/shopify';
import { dmptClear } from '../utils/dmpt';
import { useBrandsPassword } from '../hooks/useBrandsPassword';
import { getVariantById } from '../services/productVariant';

const debug = process.env.GATSBY_DEBUG || false;

let client;
const initValueContext = {
  isCartOpen: false,
  checkout: { lineItems: [] },
  products: [],
  brands: {},
  shop: {},
  rules: [],
  offers: [],
  giftsToChoose: [],
  dataFilter: [],
  siteConfig: {},
  metafields: {},
  filters: {},
  swymLoading: true,
};

if (domainCheckout && accessToken) {
  client = Client.buildClient({
    domain: domainCheckout,
    storefrontAccessToken: accessToken,
  });
}

export const StoreContext = createContext(initValueContext);

const StoreProvider = ({ children }) => {
  const { dataFilter, systemConfig, siteConfig } = useDataCommon();
  const { metafields } = useProductMetafields();
  const { filters } = useFiltersMetafields();
  const { brands } = useBrandsPassword();

  const [state, setState] = useState({
    isCartOpen: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    rules: [],
    offers: [],
    giftsToChoose: [],
    swymLoading: true,
  });

  const checkVersion = () => {
    const {
      data: { version },
    } = systemConfig;
    const versionCurrent = localStorage?.getItem('version');

    if (version !== versionCurrent) {
      localStorage?.setItem('version', version);
      localStorage.removeItem('checkoutId');
      setCookieHasDomain('gatsby-checkout-id', '', -10000);
      localStorage.removeItem('gatsby-swym-products');
      localStorage.removeItem('_products');
      localStorage.removeItem('auth');
      dmptClear();
      setCookieSwym('', '', '', -10000);
      setToken('');
    }
  };

  useEffect(() => {
    checkVersion();

    let clear = false;
    if (location?.hash == '#logout' && isLogged()) {
      setToken('');
      clear = true;
    }
    getSwymRegId(true, clear).then(() => setState((_state) => ({ ..._state, swymLoading: false })));

    if (!isLogged()) {
      loaded && localStorage?.removeItem('auth');
      setToken('');
    }

    getAllRules().then((data) => {
      setState((_state) => ({ ..._state, ...data }));
    });

    const updateAttributes = (checkout) => {
      const input = { customAttributes: [{ key: 'id', value: checkout.id }] };
      client.checkout.updateAttributes(checkout.id, input);
    };

    const createCheckoutId = () => {
      client?.checkout?.create()?.then((checkout) => {
        localStorage.setItem('checkoutId', checkout.id);
        setCookieHasDomain('gatsby-checkout-id', checkout.id);
        setState((_state) => ({ ..._state, checkout }));
        updateAttributes(checkout);
        addVariantsToCart(checkout.id);
      });
    };

    if (localStorage?.checkoutId) {
      let checkoutId = localStorage.checkoutId;
      setCookieHasDomain('gatsby-checkout-id', checkoutId);

      client?.checkout?.fetch(checkoutId)?.then((checkout) => {
        if (checkout && checkout.completedAt == null) {
          setState((_state) => ({ ..._state, checkout }));
          if (checkout?.customAttributes?.length < 1) updateAttributes(checkout);
          addVariantsToCart(checkout.id);
        } else createCheckoutId();
      });
    } else createCheckoutId();
  }, []);

  const getRules = async () => {
    const { rules, offers } = await getAllRules();
    await processAddGiftToCard(state.checkout, rules);
    setState((_state) => ({ ..._state, rules, offers }));
  };

  useEffect(() => {
    if (state?.rules?.length > 0) processAddGiftToCard(state?.checkout);
  }, [state.rules]);

  const addVariantsToCart = (checkoutId) => {
    if (localStorage?.checkout_products) {
      const checkout_products = JSON.parse(localStorage?.checkout_products, '[]');
      debug && console.log('checkout: is exists checkout products in loaclstorage');
      if (checkout_products?.length > 0) {
        localStorage.removeItem('checkout_products');
        const _checkout_products = checkout_products.map(
          ({ customAttributes, quantity, variant }) => ({
            customAttributes: customAttributes.map(({ key, value }) => ({ key, value })),
            quantity,
            variantId: variant?.id,
          })
        );

        debug && console.log('checkout: add checkout products to cart');
        client.checkout.addLineItems(checkoutId, _checkout_products).then((checkout) => {
          setState((_state) => ({ ..._state, checkout: checkout }));
          return processAddGiftToCard(checkout);
        });
      }
    }
  };

  const addAllWhistlistToCart = async (products) => {
    await client.checkout.addLineItems(state.checkout.id, products).then((checkout) => {
      setState((_state) => ({ ..._state, checkout: checkout, isCartOpen: true }));
      return processAddGiftToCard(checkout);
    });
  };

  const handleCustomAttributes = (product, type = false, status = 'Ready to dispatch') => {
    const customAttributes = [
      {
        key: '_collections',
        value: JSON.stringify(product?.collections?.map(({ title }) => title) || []),
      },
      {
        key: '_status',
        value: status,
      },
    ];

    const dispatches_status = () => {
      switch (status) {
        case 'Dispatches in 7 business days':
          return 'Expected to ship in 7 business days';
        case 'Dispatches in 1 - 2 business days':
          return 'Expected to ship in 1-2 business days';
        case 'Dispatches in 2 - 3 business days':
          return 'Expected to ship in 2-3 business days';
        case 'Dispatches in 3 - 5 business days':
          return 'Expected to ship in 3-5 business days';
        case 'Ready':
        case 'Ready to dispatch':
          return 'Ready to ship';
        default:
          return '';
      }
    };
    if (dispatches_status() != '') {
      customAttributes.push({
        key: '_dispatch_status',
        value: dispatches_status(),
      });
    }

    if (type == 'Free Gift') {
      customAttributes.push(
        ...[
          { key: '_isGiftChoose', value: '1' },
          { key: '_isGift', value: '1' },
          { key: '_rule_id', value: product?.rule_id || '' },
          { key: '_expired', value: product?.expired || '' },
        ]
      );
    } else if (type == 'Special Gift') {
      customAttributes.push(
        ...[
          { key: '_isGiftChoose', value: '1' },
          { key: '_isSpecialGift', value: '1' },
          { key: '_rule_id', value: product?.rule_id || '' },
          { key: '_expired', value: product?.expired || '' },
        ]
      );
    }

    if (product.validate) {
      customAttributes.push({
        key: '_validate',
        value: JSON.stringify(product.validate),
      });
    }

    if (product?.productType === 'Gift Cards' || product?.product_type === 'Gift Cards') {
      customAttributes.push({ key: '_isGiftCard', value: '1' });
    }

    return customAttributes;
  };

  const addVariantToCart = (
    product,
    variantId,
    quantity,
    type = false,
    status = 'Ready to dispatch'
  ) => {
    const _openCart = location?.pathname?.indexOf('/cart') === -1;
    _openCart !== state?.isCartOpen &&
      setState((_state) => ({
        ..._state,
        isCartOpen: _openCart,
      }));

    if (!type || !['Free Gift', 'Special Gift'].includes(type)) {
      if (typeof dataLayer !== 'undefined' && product && product?.title) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'AUD',
            add: {
              products: [
                {
                  name: `${product?.title}`,
                  id: `${product?.legacyResourceId || product?.prismicId}`,
                  price: `${
                    product?.variants?.filter(
                      (variant) =>
                        (variant?.shopifyId || variant?.admin_graphql_api_id) === variantId
                    )?.[0]?.price
                  }`,
                  brand: `${product?.vendor}`,
                  category: `${product?.collections
                    ?.filter((v) => v.title.indexOf('Brands') === -1)
                    ?.map((v) => v.title)
                    ?.join()}`,
                  variant: `${
                    product?.variants?.filter(
                      (variant) =>
                        (variant?.shopifyId || variant?.admin_graphql_api_id) === variantId
                    )?.[0]?.title
                  }`,
                  quantity,
                },
              ],
            },
          },
        });
      }

      if (typeof fbq !== 'undefined' && product && product?.title) {
        fbq('track', 'AddToCart', {
          content_type: 'product',
          content_ids: [`${product?.legacyResourceId || product?.prismicId || 'gatsby app'}`],
          content_name: `${product?.title || 'gatsby app'}`,
          value: `${
            product?.variants?.filter(
              (variant) => (variant?.shopifyId || variant?.admin_graphql_api_id) === variantId
            )?.[0]?.price ||
            product?.variants?.[0]?.price ||
            '0.0'
          }`,
          currency: 'AUD',
        });
      }
    }

    const customAttributes = handleCustomAttributes(product, type, status);

    const lineItem = state?.checkout?.lineItems?.find(
      ({ variant }) => base64_encode(variant?.id) === variantId || variant?.id === variantId
    );

    if (lineItem) {
      updateQuantityInCart(lineItem, lineItem?.quantity + quantity, customAttributes);
      return;
    }

    if (variantId.indexOf('Shopify__ProductVariant__') !== -1)
      variantId = variantId.split('Shopify__ProductVariant__')[1];

    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes,
      },
    ];
    const checkoutId = state.checkout.id;

    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
      setState((_state) => ({
        ..._state,
        checkout: checkout,
      }));
      return processAddGiftToCard(checkout);
    });
  };

  const updateQuantityInCart = (lineItem, quantity, customAttributes = false) => {
    const checkoutId = state.checkout.id;
    const lineItemsToUpdate = [{ id: lineItem.id, quantity: parseInt(quantity, 10) }];
    if (customAttributes) lineItemsToUpdate[0].customAttributes = customAttributes;

    const _openCart = location?.pathname?.indexOf('/cart') === -1;
    _openCart !== state?.isCartOpen &&
      setState((_state) => ({
        ..._state,
        isCartOpen: _openCart,
      }));

    return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then((checkout) => {
      setState((_state) => ({
        ..._state,
        checkout: checkout,
      }));
      return processAddGiftToCard(checkout);
    });
  };

  const removeLineItemInCart = (lineItem) => {
    const { checkout } = state;
    const checkoutId = checkout.id;
    const _openCart = location?.pathname?.indexOf('/cart') === -1;
    _openCart !== state?.isCartOpen &&
      setState((_state) => ({
        ..._state,
        isCartOpen: _openCart,
      }));

    return client.checkout.removeLineItems(checkoutId, [lineItem.id]).then((checkout) => {
      setState((_state) => ({
        ..._state,
        checkout: checkout,
      }));
      return processAddGiftToCard(checkout);
    });
  };

  const processAddGiftToCard = async (checkout, _rules = false) => {
    const checkoutId = state?.checkout?.id || checkout.id;

    if (!checkout) {
      checkout = await client.checkout.fetch(checkoutId);
    }
    console.log('************ Run processAddGiftToCard ************');
    if (checkout.lineItems?.length <= 0) return;
    let rules = _rules || state.rules;
    if (rules.length < 1) {
      let data = await getAllRules();
      rules = data.rules;
    }

    const allGiftInCart = [];
    const allGiftChooseInCart = [];
    const giftsToChooseAdded = [];
    const giftsToChoose = { free_gift: [], special_gift: [] };
    const giftsAddToCart = [];
    const products = [];
    const giftsValidateQuantity = [];

    checkout.lineItems.forEach((item) => {
      if (
        item?.customAttributes.findIndex(
          ({ key, value }) => key == '_isGiftChoose' && value == '1'
        ) !== -1
      ) {
        allGiftChooseInCart.push(item);
        item.quantity > 1 && giftsValidateQuantity.push({ id: item.id, quantity: 1 });
      } else if (
        item?.customAttributes.findIndex(
          ({ key, value }) =>
            (key == '_isGift' && value == '1') || (key == '_isSpecialGift' && value == '1')
        ) !== -1
      ) {
        allGiftInCart.push(item);
        item.quantity > 1 && giftsValidateQuantity.push({ id: item.id, quantity: 1 });
      } else if (
        item?.customAttributes.findIndex(
          ({ key, value }) => key == '_isGiftCard' && value == '1'
        ) === -1
      )
        products.push(item);
    });
    console.log('Start', { products, allGiftInCart, allGiftChooseInCart });

    if (giftsValidateQuantity.length > 0) {
      console.log('Update free gifts with quantity more than 1', giftsValidateQuantity);
      await client.checkout.updateLineItems(checkoutId, giftsValidateQuantity).then((checkout) => {
        setState((_state) => ({
          ..._state,
          checkout: checkout,
        }));
      });
    }

    // start check promotion free gift rules
    rules.forEach(async (rule) => {
      const isFreeGifts = rule?.type === 'free_gifts';
      if (checkExpried(rule?.start_date, rule?.end_date)) {
        debug && console.log(`Ignore rule ${rule.rule_name}: date is not validate`);
        return;
      }

      if (rule.free_gift_group?.length <= 0) {
        debug && console.log(`Ignore rule ${rule.rule_name}: Don't have any free gift`);
        return;
      }

      let validateBrand = false;
      let validateCategory = false;
      let validateProducts = false;
      let flag = false;

      if (rule?.brand) {
        validateBrand = titleCombination(
          rule?.brand?.title_collection?.replace('Brands/', ''),
          true
        );
        flag = true;
      } else if (rule?.category) {
        validateCategory = titleCombination(rule?.category?.title_collection);
        flag = true;
      } else if (rule?.products && rule?.products?.length > 0) {
        validateProducts = rule?.products;
        flag = true;
      }

      const minimum_cart_value = rule.minimum_cart_value || 0;
      const minimum_product_count = rule.minimum_product_count || 0;

      if (flag && !minimum_cart_value && !minimum_product_count) {
        return;
      }

      let _cart_value = 0;
      let _product_count = 0;

      products.forEach(async (item) => {
        const collections = JSON.parse(
          item.customAttributes.find(({ key }) => key == '_collections')?.value || '[]'
        );

        let isValidateBrandCategoryProducts = true;
        if (validateBrand) {
          isValidateBrandCategoryProducts =
            collections.findIndex((title) => validateBrand.includes(title)) !== -1;
        } else if (validateCategory) {
          isValidateBrandCategoryProducts =
            collections?.findIndex((title) => validateCategory.includes(title)) !== -1;
        } else if (validateProducts) {
          isValidateBrandCategoryProducts =
            validateProducts.findIndex((id) => id === item?.variant?.product?.id) !== -1;
        }

        debug && console.log(`isValidateBrandCategoryProducts: ${isValidateBrandCategoryProducts}`);
        if (!isValidateBrandCategoryProducts) {
          debug &&
            console.log(`Ignore rule ${rule.rule_name}: isValidateBrandCategoryProducts false`);
          return;
        }
        _cart_value += parseFloat(item?.variant?.price?.amount) * item.quantity;
        _product_count += item.quantity;
      });

      if (minimum_cart_value && _cart_value < minimum_cart_value) {
        debug &&
          console.log(`Ignore rule ${rule.rule_name}: minimum_cart_value not met`, {
            _cart_value,
            minimum_cart_value,
          });
        return;
      }
      if (minimum_product_count && _product_count < minimum_product_count) {
        debug &&
          console.log(`Ignore rule ${rule.rule_name}: minimum_product_count not met`, {
            _cart_value,
            minimum_product_count,
          });
        return;
      }

      console.log('Matched rule:', rule.rule_name);
      if (rule?.free_gift_group?.length == 1) {
        const giftVariantId =
          rule.free_gift_group[0].free_gift_product.variants[0].admin_graphql_api_id;

        const _customAttributes = [
          { key: isFreeGifts ? '_isGift' : '_isSpecialGift', value: '1' },
          {
            key: '_collections',
            value: JSON.stringify([`Brands/${rule.free_gift_group[0]?.free_gift_product?.vendor}`]),
          },
          { key: '_rule_id', value: rule?.id || '' },
          { key: '_Expired', value: rule?.end_date || '' },
        ];

        if (flag) {
          _customAttributes.push({
            key: '_validate',
            value: JSON.stringify(validateBrand || validateCategory || validateProducts),
          });
        }

        const isFreeSamples = rule.rule_name.indexOf('Free samples') !== -1;

        if (isFreeSamples) {
          _customAttributes.push({ key: '_isFreeSamples', value: '1' });
        } else {
          _customAttributes.push({
            key: '_status',
            value: 'Ready to dispatch',
          });
        }

        giftsAddToCart.push({
          variantId: giftVariantId,
          quantity: 1,
          customAttributes: _customAttributes,
        });
      } else {
        const checkInCart = allGiftChooseInCart.find(
          ({ variant }) =>
            rule.free_gift_group.findIndex(
              ({ free_gift_product }) =>
                free_gift_product.admin_graphql_api_id === variant?.product?.id
            ) !== -1
        );
        if (!checkInCart) {
          const free_gift_group = rule.free_gift_group.map(({ free_gift_product }) => ({
            ...free_gift_product,
            expired: rule?.end_date || '',
            rule_id: rule?.id,
            validate: validateBrand || validateCategory || validateProducts,
          }));
          if (isFreeGifts) giftsToChoose?.free_gift?.push(free_gift_group);
          else giftsToChoose?.special_gift?.push(free_gift_group);
        } else giftsToChooseAdded.push(checkInCart);
      }
    });
    // end check promotion free gift rules

    setState((_state) => ({ ..._state, giftsToChoose }));

    let giftsRemove = [];
    let _giftsAddToCart = [];
    if (giftsAddToCart?.length > 0) {
      _giftsAddToCart = giftsAddToCart.filter(
        ({ variantId }, i) =>
          allGiftInCart.findIndex(({ variant }) => variant.id === variantId) === -1 &&
          giftsAddToCart.findIndex((v) => v.variantId === variantId) === i
      );

      if (_giftsAddToCart?.length > 0) {
        debug && console.log('Add gifts to cart', _giftsAddToCart);
        _giftsAddToCart.forEach(async (_item) => {
          const dataVariantById = await getVariantById(_item?.variantId);

          if (
            dataVariantById?.data?.productVariant?.product?.status === 'ACTIVE' &&
            (dataVariantById?.data?.productVariant?.inventoryPolicy === 'CONTINUE' ||
              dataVariantById?.data?.productVariant?.inventoryQuantity > 0)
          )
            await client.checkout
              .addLineItems(checkoutId, [{ ..._item, variantId: base64_encode(_item?.variantId) }])
              .then((_checkout) => {
                setState((_state) => ({ ..._state, checkout: _checkout }));
              });
        });
      }

      giftsRemove = allGiftInCart.filter(
        ({ variant }) =>
          giftsAddToCart.findIndex(({ variantId }) => variantId === variant.id) === -1
      );
    } else {
      giftsRemove = allGiftInCart;
    }

    if (giftsToChooseAdded?.length <= 0) giftsRemove = [...giftsRemove, ...allGiftChooseInCart];
    else
      giftsRemove = [
        ...giftsRemove,
        ...allGiftChooseInCart.filter(
          ({ id }) => giftsToChooseAdded.findIndex(({ id: _id }) => id === _id) === -1
        ),
      ];

    debug &&
      console.log('Results', {
        allGiftInCart,
        allGiftChooseInCart,
        giftsToChooseAdded,
        giftsToChoose,
        giftsAddToCart,
        _giftsAddToCart,
        giftsRemove,
      });

    if (giftsRemove.length) {
      debug && console.log('Removing gift from cart', giftsRemove);

      await client.checkout
        .removeLineItems(
          checkoutId,
          giftsRemove.map(({ id }) => id)
        )
        .then((_checkout) => {
          debug && console.log('Remove gift from cart', checkout.lineItems);
          setState((_state) => ({ ..._state, checkout: _checkout }));
        });
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...initValueContext,
        addVariantToCart,
        removeLineItemInCart,
        updateQuantityInCart,
        fetchRecommendations: client?.product?.fetchRecommendations?.bind(client?.product),
        checkout: state.checkout,
        isCartOpen: state.isCartOpen,
        dataFilter: dataFilter,
        siteConfig: siteConfig,
        giftsToChoose: state.giftsToChoose,
        swymLoading: state.swymLoading,
        rules: state.rules,
        offers: state.offers,
        setState,
        metafields,
        filters,
        handleCustomAttributes,
        addAllWhistlistToCart,
        getRules,
        brands,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
