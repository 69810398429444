import React, { memo } from 'react';
import loadable from '@loadable/component';

const FilterCollapse = loadable(() => import('../FilterCollapse'));

const FillterPrice = ({
  price,
  price_filter_group,
  handleChangePrice,
  productsAfterFilter,
  ...props
}) => {
  return (
    <FilterCollapse {...props}>
      <ul>
        {price_filter_group?.map(({ price_range }, i) => {
          const _price_range = price_range.split('-');
          const num = productsAfterFilter?.filter((v) => {
            if (_price_range?.length === 1) {
              return (
                (Number(v?.priceRangeV2?.minVariantPrice?.amount) || 0) > Number(_price_range[0])
              );
            }
            return (
              (Number(v?.priceRangeV2?.minVariantPrice?.amount) || 0) >= Number(_price_range[0]) &&
              (Number(v?.priceRangeV2?.maxVariantPrice?.amount) || 0) <= Number(_price_range[1])
            );
          }).length;
          if (num > 0)
            return (
              <li key={i} onClick={() => handleChangePrice(price_range)}>
                <span className={price.includes(price_range) ? 'active' : ''}>
                  {_price_range.length > 1
                    ? `$${_price_range[0]} To $${_price_range[1]}`
                    : `$${_price_range[0]}+`}
                  <span className="num">({num})</span>
                </span>
              </li>
            );
        })}
      </ul>
    </FilterCollapse>
  );
};

export default memo(FillterPrice);
