import React, { memo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CustomLink from '../common/CustomLink';

const CategoryImage = memo(({ image, link }) => {
  return (
    <figure className="image">
      {image?.gatsbyImageData && (
        <CustomLink link={link} className="image__pc">
          <GatsbyImage
            image={image?.gatsbyImageData}
            loading="eager"
            alt={image.alt || 'activeskin'}
          />
        </CustomLink>
      )}

      {image?.thumbnails?.mobile?.gatsbyImageData && (
        <CustomLink className="image__mobile" link={link}>
          <GatsbyImage
            loading="eager"
            image={image?.thumbnails?.mobile?.gatsbyImageData}
            alt={image?.thumbnails?.mobile?.alt || 'activeskin'}
          />
        </CustomLink>
      )}
    </figure>
  );
});

export default CategoryImage;
