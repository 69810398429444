import React, { memo, useEffect, useState } from 'react';
import { useQueryParams } from 'use-query-params';
import loadable from '@loadable/component';
import { loaded } from '../../utils/helpers';

const FilterCollapse = loadable(() => import('../archive-product/FilterCollapse'));

/**
 * handle check exist tags
 * @param {*} filter
 * @param {*} metafields
 * @returns
 */
const checkExistMetafield = (filter = {}, metafields = [], brand = '') => {
  let countCheck = 0,
    countCheckTrue = 0;

  const keys = Object.keys(filter);
  for (let i = 0; i < keys.length; i++) {
    const values = filter[keys[i]];
    if (values.length <= 0) continue;

    if (keys[i] == 'brand') {
      countCheck++;
      if (filter?.brand?.includes(brand)) countCheckTrue++;
      else {
        //flase
      }
    } else {
      const _metafields = metafields
        .filter((metafield) => metafield.key === keys[i])
        .map((metafield) => {
          try {
            return JSON.parse(metafield.value || '[]').join('|');
          } catch (error) {
            // console.log(error);
            // console.log(metafield);
            return metafield;
          }
        })
        .join('|')
        .split('|')
        .map((metafield) => metafield.trim())
        .filter((metafield) => metafield != '');

      if (_metafields?.length) {
        // console.log({ key: keys[i], _metafields });
        let flag = false;
        countCheck++;
        for (let j = 0; j < values.length; j++) {
          if (_metafields.includes(values[j])) {
            flag = true;
            break;
          }
        }

        if (flag) countCheckTrue++;
        else {
          //false
        }
      } else {
        countCheck++;
      }
    }
  }
  // console.log('run', countCheck === countCheckTrue);
  return countCheck === countCheckTrue;
};

/**
 * handle filter products
 * @param {*} filter
 * @param {*} products
 * @returns
 */
const handleFilterProducts = (filter = {}, products = []) => {
  if (Object.values(filter).filter((v) => v.length > 0).length <= 0) {
    return products;
  } else {
    return products.filter((v) => checkExistMetafield(filter, v.metafields, v.vendor));
  }
};

const Sidebar = memo((props) => {
  const {
    showSidebarMobile,
    setShowSidebarMobile,
    products,
    setNewProducts,
    setCurrentPage,
    dataFilters,
    initQuery,
    dataFilter,
    children,
  } = props;

  const [filter, setFilter] = useState({});
  const [productsAfterFilter, setProductsAfterFilter] = useState([]);
  const [query, setQuery] = useQueryParams(initQuery);
  const [price, setPrice] = React.useState([]);

  const { price_filter_group, colour_filter_group } = dataFilter?.data || {};

  const handleFilterPrice = (arr) => {
    if (arr.length <= 0) setNewProducts(productsAfterFilter);
    else {
      const productsAfterFilterPrice = [];
      for (let i = 0; i < arr.length; i++) {
        const _price = arr[i].split('-');
        productsAfterFilterPrice.push(
          ...productsAfterFilter.filter(
            (v) =>
              (Number(v?.priceRangeV2?.minVariantPrice?.amount) || 0) >= Number(_price[0]) &&
              (Number(v?.priceRangeV2?.maxVariantPrice?.amount) || 0) <= Number(_price[1])
          )
        );
      }
      setNewProducts(productsAfterFilterPrice);
    }
  };

  /**
   * handle change price
   * @param {*} event
   * @param {*} newPrice
   */
  const handleChangePrice = (newPrice) => {
    let arr;
    if (price.indexOf(newPrice) === -1) {
      arr = [...price, newPrice];
    } else {
      arr = price.filter((p) => p !== newPrice);
    }
    setPrice(arr);
    handleFilterPrice(arr);
  };

  /**
   * handle toggle filter
   * @param {*} key
   * @param {*} metafield
   */
  const handleToggleFilter = (key, metafield) => {
    let newFilter;

    if (filter[key]) {
      if (filter[key].includes(metafield)) {
        newFilter = { ...filter, [key]: filter[key].filter((v) => v !== metafield) };
      } else {
        newFilter = { ...filter, [key]: [...filter[key], metafield] };
      }
    } else {
      newFilter = { ...filter, [key]: [metafield] };
    }

    setFilter(newFilter);
    setQuery(newFilter);

    const _handleFilterProducts = handleFilterProducts(newFilter, products);
    setNewProducts(_handleFilterProducts);
    setProductsAfterFilter(_handleFilterProducts);
  };

  /**
   * handle check filter active
   * @param {*} key
   * @param {*} metafield
   * @returns
   */
  const handleCheckFilterActive = (key, metafield = false) => {
    if (filter[key]) {
      if (!metafield) return filter[key].length > 0;
      return filter[key].includes(metafield);
    }
    return false;
  };

  useEffect(() => {
    setFilter(query);
    const _handleFilterProducts = handleFilterProducts(query, products);
    setNewProducts(_handleFilterProducts);
    setProductsAfterFilter(_handleFilterProducts);
    loaded &&
      document.getElementById('header__main__nav__item--active')?.classList?.remove('active');
  }, [query]);

  useEffect(() => {
    price?.length > 0 && setPrice([]);
    // setCurrentPage(1);
  }, [filter]);

  return (
    <div className={`main__archive-product__sidebar sidebar ${showSidebarMobile ? 'mobile' : ''}`}>
      <div className="sidebar__header mobile-title">
        {/* <span>Filter {newProducts.length} products found</span> */}
        <span>Filter Options</span>
        <button onClick={() => setShowSidebarMobile(false)} className="close-filter"></button>
      </div>

      <div className="sidebar__body">
        {children}

        {dataFilters.map((v, i) => {
          const { metafield_value, metafield_key, total } = v;
          if (metafield_value.length > 0) {
            if (metafield_key == 'brand') {
              return (
                <FilterCollapse
                  key={i}
                  show={handleCheckFilterActive(metafield_key)}
                  className="--checkbox"
                  title={v.filter_title}
                >
                  <ul>
                    {metafield_value?.map((metafield, metafieldIndex) => (
                      <li
                        onClick={() => handleToggleFilter(metafield_key, metafield)}
                        key={metafieldIndex}
                      >
                        <span
                          className={
                            handleCheckFilterActive(metafield_key, metafield) ? 'active' : ''
                          }
                        >
                          {metafield}
                          <span className="num">({total[metafieldIndex]})</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </FilterCollapse>
              );
            } else if (metafield_key == 'colour') {
              return (
                <FilterCollapse
                  key={i}
                  show={handleCheckFilterActive(metafield_key)}
                  className="--checkbox --colour"
                  title={v.filter_title}
                >
                  <ul>
                    {metafield_value.map((metafield, metafieldIndex) => {
                      const color = colour_filter_group?.filter(
                        ({ colour_name }) => colour_name == metafield
                      )?.[0]?.colour_code;

                      return (
                        <li
                          onClick={() => handleToggleFilter(metafield_key, metafield)}
                          key={metafieldIndex}
                        >
                          <span
                            className={
                              handleCheckFilterActive(metafield_key, metafield) ? 'active' : ''
                            }
                          >
                            <span
                              className="color"
                              style={{ background: color || metafield }}
                            ></span>

                            {metafield}
                            <span className="num">({total[metafieldIndex]})</span>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </FilterCollapse>
              );
            } else {
              return (
                <FilterCollapse
                  key={i}
                  show={handleCheckFilterActive(metafield_key)}
                  className="--checkbox"
                  title={v.filter_title}
                >
                  <ul>
                    {metafield_value?.map((metafield, metafieldIndex) => (
                      <li
                        onClick={() => handleToggleFilter(metafield_key, metafield)}
                        key={metafieldIndex}
                      >
                        <span
                          className={
                            handleCheckFilterActive(metafield_key, metafield) ? 'active' : ''
                          }
                        >
                          {metafield}
                          <span className="num">({total[metafieldIndex]})</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </FilterCollapse>
              );
            }
          }
        })}

        <FilterCollapse className="--checkbox" title="Price">
          <ul>
            {price_filter_group?.map(({ price_range }, i) => {
              const _price_range = price_range.split('-');
              const num = productsAfterFilter?.filter(
                (v) =>
                  (Number(v?.priceRangeV2?.minVariantPrice?.amount) || 0) >=
                    Number(_price_range[0]) &&
                  (Number(v?.priceRangeV2?.maxVariantPrice?.amount) || 0) <= Number(_price_range[1])
              ).length;
              if (num > 0)
                return (
                  <li key={i} onClick={() => handleChangePrice(price_range)}>
                    <span className={price.includes(price_range) ? 'active' : ''}>
                      {`$${_price_range[0]} To $${_price_range[1]}`}
                      <span className="num">({num})</span>
                    </span>
                  </li>
                );
            })}
          </ul>
        </FilterCollapse>
      </div>
    </div>
  );
});

export default Sidebar;
