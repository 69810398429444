import React, { memo, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import './style.scss';
import { getModelUrl } from '../../utils/helpers';
import { getVariantById } from '../../services/productVariant';
const GatsbyImage = loadable(() => import('../common/GatsbyImage'));
const CustomLink = loadable(() => import('../common/CustomLink'));

const PromotionItem = memo(({ slice_type, image, product_page_text, brand, product, title }) => {
  const [state, setState] = useState({
    loading: true,
    inventoryPolicy: false,
    inventoryQuantity: false,
  });

  useEffect(() => {
    if (product) handleGetVariantById(product?.variants?.[0]?.admin_graphql_api_id);
    else setState((_) => ({ ..._, loading: false, inventoryPolicy: true }));
  }, []);

  const handleGetVariantById = (variantStorefrontId) => {
    getVariantById(variantStorefrontId).then(({ data }) => {
      const { inventoryPolicy, inventoryQuantity, product: _product } = data?.productVariant || {};

      if (_product?.status && _product?.status !== 'ACTIVE') {
        setState((_) => ({ ..._, loading: false }));
        return;
      }

      setState({
        loading: false,
        inventoryQuantity,
        inventoryPolicy: inventoryPolicy === 'CONTINUE',
      });
    });
  };

  if (state.loading || (!state.inventoryPolicy && state.inventoryQuantity <= 0)) return null;

  return (
    <div className="col-lg-4 col-md-6">
      <article className={`${slice_type}__item promotion-item`}>
        <div className="promotion-item__image">
          <CustomLink link={brand}>
            <GatsbyImage image={image} alt={image?.alt} />
            {image?.thumbnails?.lifestyle?.gatsbyImageData && (
              <GatsbyImage
                className="promotion-item__image--hover d-none d-md-block"
                image={image?.thumbnails?.lifestyle}
                alt={image?.thumbnails?.lifestyle?.alt}
              />
            )}
          </CustomLink>
        </div>
        <div className="promotion-item__inner">
          <div className="promotion-item__title">
            <CustomLink className="-link-primary -no-underline" link={brand}>
              <h3 className="m-0">{title || brand?.document?.data?.name}</h3>
            </CustomLink>
          </div>
          <div className="promotion-item__sort-description">{product_page_text?.text}</div>
          <div className="promotion-item__read-more">
            <CustomLink className="-link-primary -underline" link={brand} text="Shop This" />
          </div>

          {/* {product?.handle && (
            <div className="promotion-item__learn-more">
              <Link to={getModelUrl('buy', product.handle)} title={product.title}>
                Learn More
              </Link>
            </div>
          )} */}
        </div>
      </article>
    </div>
  );
});

export default PromotionItem;
