import React, { memo, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import GatsbyImage from './../common/GatsbyImage';
import { getVariantById } from '../../services/productVariant';
import { getModelUrl } from '../../utils/helpers';

const PromoImage = memo((props) => {
  const { image, link: product, title, sub_title, lifestyle_image, tag, button_text } = props;

  const [state, setState] = useState({
    loading: true,
    inventoryPolicy: false,
    inventoryQuantity: false,
  });

  useEffect(() => {
    if (product) handleGetVariantById(product?.variants?.[0]?.admin_graphql_api_id);
    else setState((_) => ({ ..._, loading: false, inventoryPolicy: true }));
  }, []);

  const handleGetVariantById = (variantStorefrontId) => {
    getVariantById(variantStorefrontId).then(({ data }) => {
      const { inventoryPolicy, inventoryQuantity, product: _product } = data?.productVariant || {};

      if (_product?.status && _product?.status !== 'ACTIVE') {
        setState((_) => ({ ..._, loading: false }));
        return;
      }

      setState({
        loading: false,
        inventoryQuantity,
        inventoryPolicy: inventoryPolicy === 'CONTINUE',
      });
    });
  };

  if (state.loading || (!state.inventoryPolicy && state.inventoryQuantity <= 0)) return;

  return (
    <div className="col-xl-3 col-lg-4 col-6 p-lg-3 p-0">
      <div className="promo">
        <figure className="promo__image">
          {image?.gatsbyImageData && (
            <Link to={product?.handle ? getModelUrl('buy', product?.handle) : '#'}>
              <GatsbyImage image={image?.gatsbyImageData} alt={image.alt} />

              {lifestyle_image?.gatsbyImageData && (
                <GatsbyImage
                  className="promo__image--hover"
                  image={lifestyle_image?.gatsbyImageData}
                  alt={lifestyle_image.alt}
                />
              )}
            </Link>
          )}

          {tag && (
            <span className="promo__tag">
              <span>{tag}</span>
            </span>
          )}
        </figure>
        <div className="promo__inner text-center">
          {title && <h3 className="promo__title text-left">{title}</h3>}
          {sub_title && <p className="promo__sub-title text-left">{sub_title}</p>}
          {button_text && (
            <button className="promo__btn btn-activeskin">
              <Link to={product?.handle ? getModelUrl('buy', product?.handle) : '#'}>
                {button_text}
              </Link>
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default PromoImage;
