import React, { memo, useEffect, useState } from 'react';
import { getVariantById } from '../../services/productVariant';
import { navigate } from 'gatsby';

const FreeGift = ({ rule }) => {
  const { rule_name, free_gift_subtitle, terms_conditions, product_page_text, free_gift_group } =
    rule;

  const [state, setState] = useState({
    loading: true,
    inventoryPolicy: false,
    inventoryQuantity: false,
  });

  useEffect(() => {
    if (free_gift_group.length === 1)
      handleGetVariantById(
        free_gift_group?.[0]?.free_gift_product?.variants?.[0]?.admin_graphql_api_id
      );
    else setState((_) => ({ ..._, loading: false }));
  }, []);

  const handleGetVariantById = (variantStorefrontId) => {
    getVariantById(variantStorefrontId).then(({ data }) => {
      const { inventoryPolicy, inventoryQuantity, product: _product } = data?.productVariant || {};
      if (_product?.status && _product?.status !== 'ACTIVE') {
        setState((_) => ({ ..._, loading: false }));
        return;
      }

      setState({
        loading: false,
        inventoryQuantity,
        inventoryPolicy: inventoryPolicy === 'CONTINUE',
      });
    });
  };

  if (state.loading || (!state.inventoryPolicy && state.inventoryQuantity <= 0)) return;

  return (
    <div className="free-gift" data-rule={rule_name}>
      <div className="free-gift__sub-title">{free_gift_subtitle}</div>
      {(product_page_text?.html || product_page_text?.text) && (
        <div
          className="free-gift__text"
          dangerouslySetInnerHTML={{
            __html: product_page_text?.html || product_page_text?.text,
          }}
        ></div>
      )}
      <div className="free-gift__terms-conditions">{terms_conditions}</div>
    </div>
  );
};

export default memo(FreeGift);
