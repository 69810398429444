import { useStaticQuery, graphql } from 'gatsby';

export const useFiltersMetafields = () => {
  const { allShopifyProductMetafield } = useStaticQuery(query);
  const filters = {};
  allShopifyProductMetafield?.group?.forEach(({ id, nodes }) => {
    filters[id] = nodes;
  });

  return { filters };
};

export default useFiltersMetafields;

const query = graphql`
  query {
    allShopifyProductMetafield(
      filter: {
        namespace: { eq: "filters" }
        value: { ne: "" }
        product: { priceRangeV2: { maxVariantPrice: { amount: { ne: "0.0" } } } }
      }
    ) {
      totalCount
      group(field: product___id) {
        id: fieldValue
        nodes {
          key
          value
        }
      }
    }
  }
`;
