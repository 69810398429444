import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/blog/Layout'));
const Archive = loadable(() => import('../../containers/blog/archive'));

export const Head = ({ location, params, data, pageContext }) => {
  const seo = {
    title: 'Beauty and Skincare Blog | The Ultimate Style Guide | Afterpay | Activeskin',
    description:
      "Keep up to date with this season's hottest beauty trends. Tips, tricks, tutorials and product releases, curated by Activeskin's beauty professionals.",
    location,
  };

  return <Seo {...seo} />;
};

const paginateHomeBlog = memo((props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const { allPosts } = data;

  return (
    <Layout location={location}>
      <Archive posts={allPosts} link="/blog" />
    </Layout>
  );
});

export default paginateHomeBlog;

export const query = graphql`
  query paginateHomeBlogQuery($skip: Int = 0, $limit: Int = 21) {
    allPosts: allPrismicBlogPostNew(
      sort: { fields: data___published_date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          data {
            body {
              ... on PrismicBlogPostNewDataBodyPostContentFromWpBlog {
                primary {
                  post_content
                }
              }
            }
            featured_image {
              gatsbyImageData
              alt
            }
            category_group {
              category {
                document {
                  ... on PrismicBlogCategory {
                    data {
                      category_name
                    }
                  }
                }
              }
            }
            post_name
            secondary_title
          }
          uid
          url
          tags
          first_publication_date(formatString: "dddd M yy")
        }
      }

      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;
