import React, { memo, useState } from 'react';
import { Link } from 'gatsby';
import { getModelUrl, formatPrice, filterHasFreeGift } from './../../utils/helpers';
import { Highlight } from 'react-instantsearch-dom';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';
import { useStore } from '../../hooks';

const ButtonAddToCart = loadable(() => import('../archive-product/ButtonAddToCart'));
const OkendoStarSnippet = loadable(() => import('./../common/OkendoStarSnippet'));
const AddToWishlist = loadable(() => import('../archive-product/AddToWishlist'));
const ShipText = loadable(() => import('../archive-product/ShipText'));
const ProductPrice = loadable(() => import('../common/ProductPrice'));

function Hit({ hit }) {
  const [state, setState] = useState({
    price: -1,
    compareAtPrice: null,
    active: null,
    quantity: -1,
    currentQuantity: 0,
    purchase_method: '',
    hide_price: false,
    no_dispatch: '',
    images: [hit?.image],
  });

  if (state?.active === false) {
    return null;
  }

  const { rules, metafields } = useStore();

  let valuePrice = metafields[hit.objectID]?.filter(({ key }) => key === 'value_price_2')?.[0]
    ?.value;
  // let hide_price =
  //   metafields[hit.objectID]?.filter(({ key }) => key === 'hide_price')?.[0]?.value || false;

  // let no_dispatch =
  //   metafields[hit.objectID]?.filter(({ key }) => key === 'no_dispatch')?.[0]?.value || '';

  // let purchase_method =
  //   metafields[hit.objectID]?.filter(({ key }) => key === 'purchase_method')?.[0]?.value || '';
  // hide_price = overwriteHidePrice(hit.brand, hide_price, purchase_method);

  const { hide_price, no_dispatch, purchase_method } = state;

  const hasFreeGift = filterHasFreeGift(rules, hit).length > 0;

  const shades = hit?.variants.filter(
    (v) =>
      (v?.selectedOptions || [])?.findIndex((s) => s?.name === 'Color' || s?.name === 'Colour') !==
      -1
  );

  return (
    <div className="p-lg-3 h-100 p-0">
      <div className="grid__product">
        <LazyLoad throttle={500} height={50} scroll={true}>
          <AddToWishlist product={hit} variant={hit?.variants[0] || {}} />
        </LazyLoad>
        <figure className="grid__product__image">
          <Link to={getModelUrl('buy', hit.handle)} title={hit.title}>
            {hit.image && <img src={hit.image} alt={hit.title} />}
          </Link>
        </figure>
        {!hide_price && (
          <div className="grid__product__box-value-price">
            {valuePrice && <span>VALUE {formatPrice(Number(valuePrice || 0))}</span>}
          </div>
        )}
        <div className="grid__product__shades">
          {shades?.length > 0 && `${shades.length} Shades`}
        </div>
        <div className="grid__product__name">
          <Link to={getModelUrl('buy', hit.handle)} title={hit.title}>
            <Highlight hit={hit} attribute="title" />
          </Link>
        </div>
        <div className="grid__product__price">
          {!hide_price && state.price > 0 && (
            <ProductPrice price={state.price} compareAtPrice={state.compareAtPrice} />
          )}
        </div>
        <div className="grid__product__free-text">{hasFreeGift ? 'GIFT WITH PURCHASE' : ''}</div>
        {hit.__position < 4 ? (
          <Button
            hit={hit}
            hide_price={hide_price}
            setState={setState}
            no_dispatch={no_dispatch}
            purchase_method={purchase_method}
          />
        ) : (
          <LazyLoad throttle={500} height={50} scroll={true}>
            <Button
              hit={hit}
              hide_price={hide_price}
              setState={setState}
              no_dispatch={no_dispatch}
              purchase_method={purchase_method}
            />
          </LazyLoad>
        )}

        <div className="grid__product__ratings">
          <OkendoStarSnippet productId={hit.legacyResourceId} />
        </div>
        <ShipText product={hit} quantity={state.currentQuantity} />
      </div>
    </div>
  );
}

const Button = ({ hit, hide_price, setState, no_dispatch, purchase_method }) => {
  return (
    <div className="grid__product__button row">
      <div className="btn btn-quick-view text-center col-6 pl-2 pr-1">
        <Link to={getModelUrl('buy', hit.handle)} title={hit.title}>
          Info
        </Link>
      </div>

      <div className="btn btn-add-to-cart text-center col-6 pr-2 pl-1">
        <ButtonAddToCart
          hide_price={hide_price}
          product={hit}
          setState={setState}
          no_dispatch={no_dispatch}
          purchase_method={purchase_method}
        />
      </div>
    </div>
  );
};

export default memo(Hit);
