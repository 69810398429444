import * as React from 'react'
import TextBlock from './TextBlock'
import './style.scss'
import ContentFromWp from './ContentFromWp';

const SliceZone = React.memo(({ sliceZone, ...props }) => {

    const sliceComponents = {
        text_block: TextBlock,
        content_from_wp: ContentFromWp,
    }

    const sliceZoneContent = sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]

        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`} />
        }
        return null
    })

    return sliceZoneContent
})

export default SliceZone