import React, { memo, useState } from 'react';
import SlideDown from 'react-slidedown';
import loadable from '@loadable/component';
import { formatPrice } from '../../utils/helpers';

const KlarnaPlacement = loadable(() => import('./KlarnaPlacement'));
const AfterPayment = loadable(() => import('./AfterPayment'));
const MinusIcon = loadable(() => import('../../images/svg/MinusIcon'));
const PlusIcon = loadable(() => import('../../images/svg/PlusIcon'));

const PaymentOptionsInner = memo(({ variant }) => {
  const [paymentOptionsVisible, setPaymentOptionsVisible] = useState(false);

  const handleLoadScript = () => {
    if (!document.getElementById('afterpay-1.x')) {
      let script;

      script = document.createElement('script');

      script.id = 'afterpay-1.x';
      script.src = 'https://js.afterpay.com/afterpay-1.x.js';
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);
    }
  };

  return (
    <div className="payment-options-toggle">
      <div
        onMouseEnter={handleLoadScript}
        onClick={() => {
          event.preventDefault();
          setPaymentOptionsVisible(!paymentOptionsVisible);
        }}
      >
        Or 4 payments of {formatPrice(Math.round((variant.price / 4) * 100) / 100)}
        <b> -- </b>
        <b className="sign ml-0" style={{textDecorationLine: "underline"}}>shop now pay later options</b>
      </div>
      <SlideDown>
        {paymentOptionsVisible && (
          <div className="payments" style={{ maxHeight: '81px' }}>
            <AfterPayment price={variant.price} />
            <KlarnaPlacement price={variant.price} />
          </div>
        )}
      </SlideDown>
    </div>
  );
});

const PaymentOptions = memo((props) => <PaymentOptionsInner {...props} />);

export default PaymentOptions;
