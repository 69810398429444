import axios from 'axios';
import { getToken } from '../services/customer';
import { getCookie, setCookie, validateEmail } from './helpers';
import { v4 as uuid } from 'uuid';

/**
 * get data swym
 *
 * @returns
 */
export async function getDataSwym() {
  let email = getCookie('swym-email');
  let regid = getCookie('gatsby-swym-swymRegid');
  let sessionid = getCookie('gatsby-swym-session-id');
  let products = JSON.parse(localStorage.getItem('gatsby-swym-products') || '[]');

  return { email, regid, sessionid, products };
}

/**
 * check product/variant exist in list
 * @param {*} product
 * @param {*} variant
 * @returns
 */
export async function checkProductExistInList(product, variant) {
  const { products } = await getDataSwym();
  if (products.length <= 0) return false;
  const [, productId] = product.shopifyId.match(/\/(\d+)/);
  const [, variantId] = variant.shopifyId.match(/\/(\d+)/);

  const check = products.find(
    ({ empi, epi }) => empi == productId && (variantId ? epi == variantId : true)
  );

  if (check) return true;
  return false;
}

/**
 *
 * set cookie swym
 *
 * @param {string} regid
 * @param {string} sessionid
 * @param {string} email
 * @param {number} exdays
 */
export async function setCookieSwym(regid, sessionid, email = '', exdays = 365) {
  setCookie('swym-email', email, exdays);
  setCookie('gatsby-swym-swymRegid', regid, exdays);
  setCookie('gatsby-swym-session-id', sessionid, exdays);
}

/**
 * call api to wishlist
 * @param {*} action
 * @param {*} product
 * @param {*} variant
 * @returns
 */
export const callApiWishlist = async (action, product, variant) => {
  if (!action || !product || !variant) {
    return false;
  }

  const [, empi] = product?.shopifyId?.match(/\/(\d+)/);
  const [, epi] = variant?.shopifyId?.match(/\/(\d+)/);

  const { regid, sessionid } = await getSwymRegId();
  await runAction(action, { empi, epi, handle: product?.handle, regid, sessionid });
};

/**
 * run action
 * @param {*} action
 * @param {*} params
 */
export const runAction = async (action, params) => {
  switch (action) {
    case 'add':
      await addVariantToWishlist(params);
      break;

    case 'remove':
      await removeVariantFromWishlist(params);
      break;

    default:
      break;
  }
};

/**
 * add variant to wishlist
 * @param {*} param0
 * @returns
 */
export const addVariantToWishlist = async ({ empi, epi, handle, regid, sessionid }) => {
  console.log('swym: add product/variant in wishlist');
  const {
    data: { data },
  } = await axios.post('/api/swym', {
    type: 'update-list',
    regid,
    sessionid,
    // lid,
    a: [
      {
        empi,
        epi,
        du: `https://${process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT}/products/` + handle,
      },
    ],
  });

  if (data?.a?.length > 0) {
    const _data = data?.a?.filter((_a) => !_a?.hasError);
    if (_data?.length > 0) {
      console.log('swym: update localstorage');
      const { products } = await getDataSwym();

      localStorage.setItem(
        'gatsby-swym-products',
        JSON.stringify(
          [..._data, ...products].filter(
            (v, i, arr) => arr.findIndex((v1) => v1.empi === v.empi) === i
          )
        )
      );
      console.log('swym: add product/variant successfully!');
      return true;
    }
  }

  console.log('swym: add product/variant fail!');
  return false;
};

/**
 * add list variants to wishlist
 * @param {*} param0
 * @returns
 */
export const addListVariantsToWishlist = async ({ regid, sessionid, a }) => {
  console.log('swym: add list products/variants in wishlist');
  await axios.post('/api/swym', {
    type: 'update-list',
    regid,
    sessionid,
    a,
  });

  console.log('swym: run fetch lists');
  await fetchLists({ regid, sessionid });

  return true;
};

/**
 * remove variant from whishlist
 * @param {*} param0
 * @returns
 */
export const removeVariantFromWishlist = async ({ empi, epi, handle, regid, sessionid }) => {
  console.log('swym: remove product/variant in wishlist');
  const {
    data: { data },
  } = await axios.post('/api/swym', {
    type: 'update-list',
    regid,
    sessionid,
    // lid,
    d: [
      {
        empi,
        epi,
        du: `https://${process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT}/products/` + handle,
      },
    ],
  });

  if (data?.d?.length > 0) {
    console.log('swym: update localstorage');
    const { products } = await getDataSwym();
    localStorage.setItem(
      'gatsby-swym-products',
      JSON.stringify(products.filter((product) => product?.empi !== empi))
    );
    console.log('swym: remove successfully!');
    return true;
  }

  console.log('swym: remove fail!');
  return false;
};

/**
 * is variant in wishlist
 * @param {*} param0
 * @returns
 */
const fetchLists = async ({ regid, sessionid }) => {
  console.log({ action: 'fetchLists', regid, sessionid });

  const {
    data: { data },
  } = await axios.post('/api/swym', {
    type: 'fetch-lists',
    regid,
    sessionid,
  });

  let products = [];
  if (data?.length > 0) {
    products = data[0]?.listcontents || [];
  }
  console.log('swym: update localstorage');
  localStorage.setItem('gatsby-swym-products', JSON.stringify(products));

  return data;
};

/**
 * get swym reg id
 *
 * @param {boolean} _fetch
 * @returns
 */
export const getSwymRegId = async (_fetch = false, _clear = false) => {
  if (_clear) setCookieSwym('', '', '', -9999);
  console.log('swym: check variables swym');
  const auth = getToken();
  let { regid, sessionid, email } = await getDataSwym();

  email = email.trim().replaceAll('"', '');
  sessionid = sessionid.trim().replaceAll('"', '');
  regid = regid.trim().replaceAll('"', '');

  if (auth?.email && auth?.email !== email) {
    email = auth?.email;
    regid = '';
    sessionid = '';
  }

  console.log('swym: check email');
  if (email && validateEmail(email)) {
    if (!regid || !sessionid) {
      console.log('swym: generate sessionid, regId');

      const { data } = await axios.post('/api/swym', { type: 'generate-regid', email });
      ({ regid, sessionid } = data?.data || {});

      setCookieSwym(regid, sessionid, email);
    }
  } else {
    console.log('swym: email is null');
    if (!regid || !sessionid) {
      console.log('swym: generate sessionid, regId');
      const { data } = await axios.post('/api/swym', {
        type: 'guest-generate-regid',
        uuid: uuid(),
      });
      ({ regid, sessionid } = data?.data || {});

      setCookieSwym(regid, sessionid, email);
    }
  }

  if (_fetch) {
    await fetchLists({ regid, sessionid });
  }

  return { regid, sessionid };
};

/**
 *
 * @param {string} regid
 * @param {string} email
 */
export const guestValidateSync = async (regid, email) => {
  const {
    data: { data },
  } = await axios.post('/api/swym', { type: 'guest-validat-sync', regid, email });
  console.log(data?.msg);
};
