import gql from 'graphql-tag';
import apolloClient from '../utils/apolloClient';
import { checkExpried } from './helpers';
import { v4 as uuid } from 'uuid';

async function queryGetAllRules(after = '') {
  let params = '';
  if (after) {
    params = `after: "${after}"`;
  }
  const {
    data: {
      allRules: { edges, pageInfo, totalCount },
    },
  } = await apolloClient.query({
    query: gql`{
      allRules(where: { status: true }, first: 100, ${params}) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            _meta {
              id
            }
            rule_name
            start_date
            end_date
            display_on_product_page
            status
            free_gift_subtitle
            terms_conditions
            minimum_product_count
            minimum_cart_value
            product_page_text
            brand {
              ... on Brand {
                name
                title_collection
              }
            }
            category {
              ... on Category {
                name
                title_collection
              }
            }
            products {
              product
            }
            free_gift_group {
              free_gift_product
            }
          }
        }
      }
    }`,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  let _edges = [],
    _pageInfo = {};
  if (pageInfo.hasNextPage) {
    const _data = await queryGetAllRules(pageInfo.endCursor);
    _edges = _data.edges;
    _pageInfo = _data._pageInfo;
  }

  return {
    edges: [...edges, ..._edges],
    pageInfo: { ...pageInfo, ..._pageInfo },
    totalCount,
  };
}

export async function getAllRules() {
  const allRules = await queryGetAllRules();

  function filterFinancialOffers(allFinancial_offers) {
    const data = allFinancial_offers.edges
      .filter(({ node: { start_date, end_date } }) => !checkExpried(start_date, end_date))
      .map(({ node }) => {
        return {
          ...node,
          start_date: node?.start_date ? new Date(node.start_date) : null,
          end_date: node?.end_date ? new Date(node.end_date) : null,
          product_page_text:
            node?.product_page_text && node.product_page_text?.length > 0
              ? node.product_page_text[0]
              : node.product_page_text,
          brands: node?.brands?.filter(({ brand }) => brand) || [],
          categories: node?.categories?.filter(({ category }) => category) || [],
          products:
            node?.products
              ?.filter(({ product }) => product?.id)
              ?.map(({ product }) => ({ product: { id: product.id } })) || [],
          product: node?.product?.id ? { id: node?.product?.id } : null,
        };
      });

    let offers = {};

    data.map((v) => {
      if (v.product || v.brand || v.category) {
        const index = v.product?.id || (v.brand || v.category)?.name;
        if (!offers[index]) {
          offers[index] = v;
        }
      } else if (v.products?.length || v.brands?.length || v.categories?.length) {
        const collections = v.products?.length
          ? v?.products
          : v.brands?.length
          ? v.brands
          : v.categories;
        collections.map((_v) => {
          const index = _v.product?.id || (_v.brand || _v.category)?.name;
          if (!offers[index]) {
            const _offer = { ...v };
            if (_v.product) _offer.product = _v.product;
            else if (_v.brand) _offer.brand = _v.brand;
            else _offer.category = _v.category;
            offers[index] = _offer;
          }
        });
      }
    });

    return Object.values(offers);
  }

  return apolloClient
    .query({
      query: gql`
        {
          allPromotion_special_price_rules(where: { status: true }, first: 500) {
            edges {
              node {
                rule_name
                start_date
                end_date
                display_on_product_page
                status
                minimum_product_count
                minimum_cart_value
                free_gift_subtitle
                product_page_text
                terms_conditions
                brand {
                  ... on Brand {
                    name
                    title_collection
                  }
                }
                category {
                  ... on Category {
                    name
                    title_collection
                  }
                }
                products {
                  product
                }
                special_price_products {
                  special_price_product
                }
              }
            }
          }

          allFinancial_offers(
            where: { status: true }
            first: 500
            sortBy: meta_firstPublicationDate_DESC
          ) {
            edges {
              node {
                _meta {
                  lastPublicationDate
                }
                start_date
                end_date
                status
                display_on_product_page
                product_page_text
                free_gift_subtitle
                color_offer_title
                terms_conditions
                product
                products {
                  product
                }
                brand {
                  ... on Brand {
                    name
                    title_collection
                  }
                }
                category {
                  ... on Category {
                    name
                    title_collection
                  }
                }
                brands {
                  brand {
                    ... on Brand {
                      name
                      title_collection
                    }
                  }
                }
                categories {
                  category {
                    ... on Category {
                      name
                      title_collection
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    })
    .then(({ data: { allPromotion_special_price_rules, allFinancial_offers } }) => ({
      rules: [
        ...allRules.edges
          .filter(({ node: { start_date, end_date } }) => !checkExpried(start_date, end_date))
          .map(({ node }) => {
            return {
              id: node._meta.id,
              ...node,
              start_date: node?.start_date ? new Date(node.start_date) : null,
              end_date: node?.end_date ? new Date(node.end_date) : null,
              products:
                node?.products
                  ?.filter(({ product }) => product)
                  .map(({ product }) => product.admin_graphql_api_id) || [],
              product_page_text:
                node?.product_page_text && node.product_page_text?.length > 0
                  ? node.product_page_text[0]
                  : node.product_page_text,
              free_gift_group:
                node?.free_gift_group?.filter(({ free_gift_product }) => free_gift_product) || [],
            };
          }),
      ],
      offers: filterFinancialOffers(allFinancial_offers),
    }))
    .catch((error) => {
      console.log('getAllRules', error);
      return { rules: [], offers: [] };
    });
}

export async function getPromotionsExpired() {
  return apolloClient
    .query({
      query: gql`
        {
          allPromotions_pages {
            edges {
              node {
                body {
                  ... on Promotions_pageBodyPromotions_block {
                    fields {
                      text
                      start_time
                      end_time
                    }
                  }
                }
              }
            }
          }
        }
      `,
    })
    .then(({ data: { allPromotions_pages } }) => {
      return allPromotions_pages.edges[0].node.body
        .filter(({ fields }) => fields)
        .map(({ fields }) =>
          fields
            .filter(({ start_time, end_time }) => checkExpried(start_time, end_time))
            .map((v) => v.text)
            .join('||')
        )
        .filter((v) => v.trim() !== '')
        .join('||')
        .split('||')
        .filter((v) => v.trim() !== '');
    })
    .catch((error) => {
      console.log('getPromotionsExpired', error);
      return [];
    });
}
