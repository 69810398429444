import React, { memo } from 'react';
import loadable from '@loadable/component';
import Seo from '../components/common/Seo';

const WhishlistContaier = loadable(() => import('../components/wishlist-page'));
const Layout = loadable(() => import('../layout'));

export const Head = ({ location, params, data, pageContext }) => {
  const seo = {
    title: 'Whishlist Activeskin Page',
    description: '',
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const WhishlistPage = ({ location }) => {
  return (
    <Layout location={location}>
      <h1 hidden>{'Wishlish Activeskin Page'}</h1>

      <WhishlistContaier />
    </Layout>
  );
};

export default memo(WhishlistPage);
