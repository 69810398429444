import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import './style.scss';
import { useStore } from '../../hooks';

const LineItem = loadable(() => import('./LineItem'));
const LineItemFreeGift = loadable(() => import('./LineItemFreeGift'));

function LineItems(props) {
  const { setSavePrice } = props;

  const { checkout, updateQuantityInCart, removeLineItemInCart } = useStore();

  function checkIsFreeGift(item) {
    return (
      item?.customAttributes.findIndex(
        ({ key, value }) =>
          (key == '_isGift' && value == '1') || (key == '_isSpecialGift' && value == '1')
      ) !== -1
    );
  }

  function checkIsFreeSamples(item) {
    return (
      item?.customAttributes.findIndex(
        ({ key, value }) => key == '_isFreeSamples' && value == '1'
      ) !== -1
    );
  }

  const products = checkout.lineItems?.filter((product) => !checkIsFreeGift(product));
  const allProductsFreeGift = checkout.lineItems?.filter((product) => checkIsFreeGift(product));
  const productsFreeSamples = allProductsFreeGift.filter((product) => checkIsFreeSamples(product));
  const productsFreeGift = allProductsFreeGift.filter((product) => !checkIsFreeSamples(product));

  useEffect(() => {
    if (products?.length > 0) {
      const savePrice = products
        ?.filter((product) => parseFloat(product?.variant?.compareAtPrice) > 0)
        ?.map(
          (product) =>
            (parseFloat(product?.variant?.compareAtPrice) - parseFloat(product?.variant?.price)) *
            product?.quantity
        )
        ?.reduce((a, b) => a + b, 0);
      setSavePrice(savePrice);
    }
  }, [products]);

  return (
    <div>
      <div>
        {products.map((product) => (
          <LineItem
            updateQuantityInCart={updateQuantityInCart}
            removeLineItemInCart={removeLineItemInCart}
            key={product.id.toString()}
            line_item={product}
          />
        ))}
      </div>

      {allProductsFreeGift?.length > 0 && <hr />}

      <div>
        {productsFreeGift.map((product) => (
          <LineItemFreeGift
            removeLineItemInCart={removeLineItemInCart}
            key={product.id.toString()}
            line_item={product}
          />
        ))}
      </div>

      <div>
        {productsFreeSamples.map((product) => (
          <LineItemFreeGift
            removeLineItemInCart={removeLineItemInCart}
            key={product.id.toString()}
            line_item={product}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(LineItems);
