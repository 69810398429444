import React from 'react'
import { getSrc } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

const CustomLink = loadable(() => import('../../../components/common/CustomLink'))

const BannerFooter = React.memo(({ slice, ...props }) => {

    const { b_button_link, b_button_text, b_image } = slice.primary

    return (
        <div className="salon-static-page__banner-footer" style={{ backgroundImage: `url(${getSrc(b_image)})` }}>
            <div className='salon-static-page__banner-footer__button'>
                <CustomLink className="btn-activeskin" link={b_button_link} text={b_button_text} />
            </div>
        </div>
    )
})

export default BannerFooter

export const query = graphql`
  fragment SalonStaticPagesDataBodyBannerFooter on PrismicSalonStaticPagesDataBodyBannerFooter {
    primary {
      b_button_text
      b_button_link {
        url
        uid
        type
        link_type
      }
      b_image {
        gatsbyImageData
        alt
      }
    }
  }
`