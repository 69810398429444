import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Link } from 'gatsby'

const BrandProduct = (props) => {
    const { brand } = props

    return (
        brand && <div className="main__product-view__brand-name text-center d-none d-lg-block" style={{ marginLeft: "100px" }}>
            <Link to={brand?.url} title={brand?.data?.name || ""}>
                {brand?.data?.brand_logo?.gatsbyImageData
                    && <GatsbyImage image={brand.data?.brand_logo?.gatsbyImageData} alt={brand.data?.brand_logo?.alt || brand.data?.name} />}
            </Link>
        </div>
    )
}

export default BrandProduct