import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import loadable from '@loadable/component';
import { useMediaQuery } from 'react-responsive';

const CustomLink = loadable(() => import('../../components/common/CustomLink'));
const ReactSlick = loadable(() => import('./../common/ReactSlick'));

const PromotionSliderGroup = ({ data }) => {
  const box = useRef(null);
  const len = data.length;

  const settings = {
    dots: false,
    slidesToShow: 5,
    infinite: len > 5,
    swipeToSlide: true,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          infinite: len > 4,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          infinite: len > 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          centerMode: true,
          infinite: len > 1,
        },
      },
    ],
  };

  const isMobile = useMediaQuery({ maxWidth: 767.98 });
  const isTablet = useMediaQuery({ maxWidth: 991.98 });
  const isPCXL = useMediaQuery({ maxWidth: 1199.89 });

  function handleSlickGoTo(next = true) {
    if (box.current) {
      next ? box.current.slickNext() : box.current.slickPrev();
    }
  }

  const checkShowArrows = () => {
    const len = data?.length;
    if (isMobile && len >= 2) return true;
    else if (isTablet && len > 3) return true;
    else if (isPCXL && len > 4) return true;
    else if (len > 5) return true;
    else return false;
  };

  return (
    <div className="main__promotion">
      <div className="container-xl">
        {checkShowArrows() && (
          <div
            className="main__promotion__title -title --arrows"
            style={{ position: 'relative', top: '-15px' }}
          >
            <h2></h2>

            <div className="arrows">
              <span className="arrows__btn --left" onClick={() => handleSlickGoTo(false)}></span>
              <span className="arrows__btn --right" onClick={() => handleSlickGoTo()}></span>
            </div>
          </div>
        )}
        <ReactSlick ref={box} {...settings} className="main__promotion__owl-carousel">
          {data.map((v, i) => (
            <div key={i} className="item">
              <CustomLink
                link={v.url}
                text={
                  <div>
                    <GatsbyImage
                      image={v.slider_image.gatsbyImageData}
                      alt={v.slider_image.alt || 'activeskin'}
                    />
                    <span className="item__label">
                      <span>{v.promo_text.text}</span>
                    </span>
                  </div>
                }
              />
              <div className="item__content">
                <h3>
                  <CustomLink link={v.url} text={v.promo_heading} />
                </h3>
                {/* <RichText render={v.promo_text.richText} serializeHyperlink={serializeHyperlink} /> */}
              </div>
            </div>
          ))}
        </ReactSlick>
      </div>
    </div>
  );
};

export default PromotionSliderGroup;
