import React, { useState, useEffect } from 'react';
import { SlideDown } from 'react-slidedown'

const FaqQuestionGroup = (props) => {
    const [actives, setActives] = useState([]);
    const { question_groups } = props;

    useEffect(() => {
        let actives = [];
        question_groups.map((item, index) => {
            actives[index] = false;
        })
        setActives(actives);
    }, []);

    return (
        <ul className={`faq__question-group`}>
            {
                question_groups.map((item, index) => {
                    return <li key={index} className="faq__faq-item">
                        <p className={`faq__question ${actives[index] ? 'active' : ""}`} onClick={() => {
                            setActives(actives.map((item, index2) => {
                                return index == index2 ? !item : item;
                            }))
                        }}>{item.faq_question}</p>
                        <SlideDown className={'my-dropdown-slidedown'}>
                            {actives[index] && <div className={`faq__answer active`}>{item.faq_answer}</div>}
                        </SlideDown>
                    </li>
                })
            }
        </ul>
    )
}

export default FaqQuestionGroup
