import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"
import BoxNavbarCategories, { NavItem, NavList, NavTitle } from './style'
import loadable from '@loadable/component';

const CustomLink = loadable(() => import('./../../common/CustomLink'))

const NavbarCategories: React.FunctionComponent<any> = React.memo((props) => {

  const [state, setState] = React.useState(false);

  const data = useStaticQuery(query)?.prismicBlogCategoryMenu?.data?.menu_group || []

  return (
    <BoxNavbarCategories>
      <div className="container-xl text-center position-relative">
        <NavTitle className={state ? "show" : ""} onClick={() => setState(!state)}>Blog Menu</NavTitle>
        <NavList className={state ? "show" : ""}>
          {
            data?.map(({ menu_item }, index) => {

              if (menu_item?.document?.data?.category_name === "Trending") {
                return (
                  <NavItem style={{ width: `${100 / (data?.length)}%` }}>
                    <Link to="/blog/" activeClassName="active">Trending</Link>
                  </NavItem>
                )
              }

              return (
                <NavItem key={index} style={{ width: `${100 / (data?.length)}%` }}>
                  <CustomLink link={menu_item} text={menu_item?.document?.data?.category_name} />
                </NavItem>
              )
            })
          }
        </NavList>
      </div>
    </BoxNavbarCategories>
  )
})

export default NavbarCategories

export const query = graphql`
{
    prismicBlogCategoryMenu {
      data {
        menu_group {
          menu_item {
            uid
            url
            link_type
            type
            document {
                ... on PrismicBlogCategory {
                  data {
                    category_name
                  }
                }
            }
          }
        }
      }
    }
}  
`