import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/salon/Layout'));
const SliceZone = loadable(() => import('./../../containers/salon/home/SliceZone'));
const Banner = loadable(() => import('./../../containers/salon/home/Banner'));
const SliderTreamtentPages = loadable(() =>
  import('../../containers/salon/home/SliderTreamtentPages')
);

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicHomeSalon: {
      data: { seo },
    },
  } = data;
  const _seo = {
    title: seo[0]['meta_title'],
    description: seo[0]['meta_description'],
    location,
  };

  return <Seo {..._seo} />;
};

const HomeSalon = memo((props) => {
  const { data, errors } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const {
    prismicHomeSalon: {
      data: { sliders, seo, body },
    },
    allPrismicSalonTreatmentPages,
  } = data;

  return (
    <Layout className="home-salon">
      <h1 hidden>{seo[0]['h1_title']}</h1>
      <Banner data={sliders} />
      <SliderTreamtentPages pages={allPrismicSalonTreatmentPages} />

      <SliceZone sliceZone={body} />
    </Layout>
  );
});

export default HomeSalon;

export const query = graphql`
  {
    prismicHomeSalon {
      data {
        sliders {
          slider_link {
            url
            uid
            link_type
            type
          }
          image {
            alt
            gatsbyImageData
            thumbnails {
              mobile {
                alt
                gatsbyImageData
              }
            }
          }
        }
        seo {
          h1_title
          meta_description
          meta_title
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomeSalonDataBodyTextBlock
          ...HomeSalonDataBodyPromotionsSlider
          ...HomeSalonDataBodyBrandsBlock
          ...HomeSalonDataBodyMapBlock
        }
      }
    }

    allPrismicSalonTreatmentPages(filter: { data: { featured: { eq: true } } }) {
      nodes {
        uid
        url
        data {
          title
          short_description {
            richText
          }
          avatar {
            gatsbyImageData
            alt
          }
        }
      }
    }
  }
`;
