import React, { memo, useState, useEffect } from 'react';
import { useStore } from './../../hooks';
import { navigate } from 'gatsby';
import { getVariantById, getVariantByProductId } from '../../services/productVariant';

import ListFreeGift from './ListFreeGift';
import ListFinancialOffers from './ListFinancialOffers';
import NotifyMe from './NotifyMe';
import ContactToOrder from './ContactToOrder';
import StockStatus from './StockStatus';
import { overwriteHidePrice } from '../../utils/helpers';

const AddToCart = memo(({ product, variant, hide_price, setState, purchase_method }) => {
  const { addVariantToCart, checkout, setState: setStateStore } = useStore();

  const isGiftCards = product?.productType === 'Gift Cards';

  // const no_dispatch = metafield.filter((v) => v.key === 'no_dispatch')?.[0]?.value || '';

  const [availableQty, setAvailableQty] = useState(false);
  const [inventoryPolicy, setInventoryPolicy] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [notifyOutOfStock, setNotifyOutOfStock] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stockStatus, setStockStatus] = useState('');
  const [no_dispatch, setNoDispatch] = useState('');

  const comingSoon =
    availableQty === 0 &&
    inventoryPolicy &&
    (no_dispatch.indexOf('business days') !== -1 || ['Pre-Order', 'Ready'].includes(no_dispatch));

  useEffect(() => {
    if (variant?.storefrontId) {
      setLoading(true);
      handleGetVariantById();
    }
  }, []);

  const handleGetVariantById = () => {
    getVariantById(variant.storefrontId, true).then(({ data }) => {
      const {
        inventoryPolicy,
        inventoryQuantity,
        price,
        compareAtPrice,
        product: _product,
      } = data?.productVariant || {};
      if (_product?.status && _product?.status !== 'ACTIVE') {
        navigate('/404');
        return;
      }

      setState((_state) => ({
        ..._state,
        price,
        compareAtPrice,
        purchase_method: _product?.purchase_method?.value || null,
        hide_price: overwriteHidePrice(
          product.vendor,
          _product?.hide_price?.value == 'true',
          _product?.purchase_method?.value
        ),
      }));
      setAvailableQty(inventoryQuantity > 0 ? inventoryQuantity : 0);
      setInventoryPolicy(inventoryPolicy === 'CONTINUE');
      setNoDispatch(_product?.no_dispatch?.value || '');
      // setPurchaseMethod(product?.purchase_method?.value || null);
      setLoading(false);
    });
  };

  useEffect(() => {
    // change variant
    if (availableQty !== false && product?.storefrontId) {
      setLoading(true);
      getVariantByProductId(product.storefrontId, product.variants.length).then((data) => {
        if (!data) {
          navigate('/404');
          return;
        }
        const {
          price,
          compareAtPrice,
          quantityAvailable,
          product: _product,
        } = (data || []).find((v) => v.id == variant.shopifyId) || {};

        if (quantityAvailable < 1) {
          handleGetVariantById();
        } else {
          setState((_state) => ({
            ..._state,
            price: price?.amount || 0,
            compareAtPrice: compareAtPrice?.amount || 0,
            purchase_method: _product?.purchase_method?.value || null,
            hide_price: overwriteHidePrice(
              product.vendor,
              _product?.hide_price?.value == 'true',
              _product?.purchase_method?.value
            ),
          }));
          setAvailableQty(quantityAvailable > 0 ? quantityAvailable : 0);
          setNoDispatch(_product?.no_dispatch?.value || '');
          // setPurchaseMethod(_product?.purchase_method?.value || null);
          setLoading(false);
        }
      });
    }

    if (notifyOutOfStock) setNotifyOutOfStock(false);
    if (quantity !== 1) setQuantity(1);
  }, [variant]);

  /**
   * add to cart
   */
  const addToCart = () => {
    if (checkQuantity()) {
      let qty_add, item, realAvailableQty;
      item = checkout.lineItems.find((item) => item.variant.id == variant.shopifyId);

      if (comingSoon || isGiftCards || inventoryPolicy) {
        addVariantToCart(product, variant.storefrontId, quantity, false, stockStatus);
      } else if (availableQty > 0) {
        realAvailableQty = availableQty - Number(item?.quantity || 0);
        if (realAvailableQty < 1) {
          setStateStore((_state) => ({ ..._state, isCartOpen: true }));
        } else {
          qty_add = quantity <= realAvailableQty ? quantity : realAvailableQty;
          addVariantToCart(product, variant.storefrontId, qty_add, false, stockStatus);
        }
      } else {
        return;
      }
      setQuantity(1);
    } else if (!isGiftCards) {
      setNotifyOutOfStock(true);
    }
  };

  function btnAddToCart() {
    // const purchase_method =
    //   purchaseMethod || metafield.filter((v) => v?.key === 'purchase_method')?.[0]?.value;
    // let purchase_method = "Book a Consult"

    if (
      purchase_method === 'Contact to Order' ||
      purchase_method === 'Hide Price' ||
      purchase_method === 'Phone To Order' ||
      hide_price
    ) {
      return <ContactToOrder product={product} title="CONTACT TO ORDER" />;
    } else if (purchase_method === 'Book a Consult') {
      return <ContactToOrder product={product} title="BOOK A CONSULT" />;
    } else if (
      !isGiftCards &&
      availableQty === 0 &&
      !inventoryPolicy
      // && no_dispatch.indexOf('business days') === -1 &&
      // !['Pre-Order', 'Ready'].includes(no_dispatch)
    ) {
      return <NotifyMe product={product} variant={variant} />;
    }

    return (
      <button
        type="button"
        title="Add to Cart"
        className="button btn-cart"
        onClick={() => addToCart()}
      >
        Add to Cart
      </button>
    );
  }

  function changeQuantity(value) {
    if (checkQuantity() || (value > 0 && value <= availableQty)) {
      setQuantity(value);
      setNotifyOutOfStock(false);
    }
  }

  function checkQuantity() {
    if (availableQty !== false) {
      if (comingSoon || isGiftCards || inventoryPolicy) {
        return true;
      } else if (availableQty < 1 || quantity > availableQty) {
        return false;
      }
      return true;
    }
    return false;
  }

  return (
    <div className="add-to-cart">
      {availableQty !== false && (
        <div
          className={`inner-add-box-cart ${
            availableQty === 0 && !comingSoon && !isGiftCards && !inventoryPolicy
              ? '--out-of-stock'
              : ''
          }`}
        >
          {!loading && availableQty === 0 && !comingSoon && !isGiftCards && !inventoryPolicy && (
            <div className="qty_message mb-2 pt-0">
              <span>We're sorry but this product is out of stock.</span>
            </div>
          )}
          <div className="cart-qty">
            <div className="control-qty">
              <span
                className="quantity-controls quantity-minus"
                onClick={() =>
                  (availableQty !== 0 || comingSoon || isGiftCards || inventoryPolicy) &&
                  quantity > 1 &&
                  changeQuantity(quantity - 1)
                }
              />
              <input
                type="number"
                name="qty"
                id="qty_details"
                min={1}
                value={quantity}
                title="Qty"
                className="input-text qty"
                readOnly={availableQty === 0 && !comingSoon && !isGiftCards && !inventoryPolicy}
                onChange={(e) => changeQuantity(e.target.value)}
                onBlur={(e) => changeQuantity(Number(e.target.value))}
              />
              <span
                className="quantity-controls quantity-plus"
                onClick={() => changeQuantity(quantity + 1)}
              />
            </div>
          </div>

          {btnAddToCart()}

          <div className="clearfix" />

          {notifyOutOfStock && (
            <div className="qty_message">
              <span>We're sorry, we only have {availableQty} in stock.</span>
            </div>
          )}
        </div>
      )}
      <div className="clearfix" />

      {!loading && !isGiftCards && availableQty >= 0 && (
        <StockStatus
          inventoryPolicy={inventoryPolicy}
          availableQty={availableQty}
          no_dispatch={no_dispatch}
          setStockStatus={setStockStatus}
          quantity={quantity}
        />
      )}

      <ListFinancialOffers product={product} />
      <ListFreeGift product={product} />
    </div>
  );
});

export default AddToCart;
