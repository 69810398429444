import React from 'react';
import { connectSortBy } from 'react-instantsearch-dom';

const CustomSortBy = ({ items, refine, createURL, handleChange }) => (
    <ul>
        {items.map(item => (
            <li key={item.value} className={item.isRefined ? "active" : ""}>
                <a
                    href={createURL(item.value)}
                    onClick={event => {
                        event.preventDefault();
                        refine(item.value);
                        handleChange(item)
                    }}
                >
                    {item.label}
                </a>
            </li>
        ))}
    </ul>
);

const SortByDefault = connectSortBy(CustomSortBy);

export default SortByDefault