import React, { memo, useState, useRef, useEffect } from 'react';
import { InfiniteHits } from 'react-instantsearch-dom';
import loadable from '@loadable/component';
// import PaginationSearch from './Pagination';
import HitCount from './HitCount';
import './style.scss';
import { configOkeReview, loaded } from '../../utils/helpers';

const HierarchicalMenu = loadable(() => import('./HierarchicalMenu'));
const Sidebar = loadable(() => import('./Sidebar'));
const Hit = loadable(() => import('./Hit'));
const RefinementList = loadable(() => import('./RefinementList'));
const SortBy = loadable(() => import('./SortBy'));
import { useStore } from '../../hooks';
import { ALGOLIA_INDEX_PREFIX } from '../../constants/algolia';

const SearchResult = (props) => {
  const refBoxSearchResults = useRef(null);
  const {
    dataFilter: {
      data: { filter_group },
    },
  } = useStore();
  const { setIndexName, indexName } = props;

  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const [sortBy, setSortBy] = useState([]);

  useEffect(() => {
    const sortData = [
      {
        value: `${ALGOLIA_INDEX_PREFIX}activeskin`,
        key: 'relevance',
        label: 'Relevance',
      },
      { value: `${ALGOLIA_INDEX_PREFIX}activeskin_name_asc`, key: 'name', label: 'Name' },
      {
        value: `${ALGOLIA_INDEX_PREFIX}activeskin_price_asc`,
        key: 'price_asc',
        label: 'Price: Low to high',
      },
    ];
    configOkeReview();
    const item = sortData.find((v) => v.value === indexName) || {};
    setSortBy([item.key, item.label]);
  }, []);

  return (
    <div
      ref={refBoxSearchResults}
      className={`search-algolia-results main__archive-product ${
        showSidebarMobile ? 'active' : ''
      }`}
    >
      <div className="container-xl">
        <div className="row">
          <aside id="sidebar" className="col-xl-2 col-lg-3">
            <div id="sidebar-content">
              <Sidebar
                showSidebarMobile={showSidebarMobile}
                setShowSidebarMobile={setShowSidebarMobile}
              >
                <HierarchicalMenu
                  attributes={['categories.level_1', 'categories.level_2', 'categories.level_3']}
                />

                {filter_group?.map(({ metafield_key, filter_title }, index) => (
                  <RefinementList
                    attribute={metafield_key}
                    key={index}
                    show={index === 0}
                    title={filter_title}
                    facetOrdering={false}
                  />
                ))}
              </Sidebar>
            </div>
          </aside>

          <section id="main-content" className="main__archive-product__content col-xl-10 col-lg-9">
            <div className="row toolbar-pc mx-0">
              <div className="col-6 pl-0">
                <div className="total-product">
                  <HitCount />
                </div>
              </div>

              <div className="col-6 pr-0">
                <SortBy
                  state={sortBy}
                  setState={setSortBy}
                  refWrapper={refBoxSearchResults}
                  setIndexName={setIndexName}
                  indexName={indexName}
                />
              </div>
            </div>

            <div className="toolbar-mobile">
              <div className="btn-filter" onClick={() => setShowSidebarMobile(true)}>
                Filter
              </div>

              <SortBy
                state={sortBy}
                setState={setSortBy}
                refWrapper={refBoxSearchResults}
                setIndexName={setIndexName}
                indexName={indexName}
              />

              <div className="page">
                <div className="page__total">
                  <p>
                    {' '}
                    <HitCount />
                  </p>
                </div>
              </div>
            </div>

            <div className="grid">
              <InfiniteHits
                showPrevious={false}
                hitComponent={Hit}
                translations={{
                  loadMore: 'Show More Products',
                }}
              />
              {/* <PaginationSearch /> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default memo(SearchResult);
