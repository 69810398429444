import * as React from 'react'
import './style.scss'
import loadable from '@loadable/component'

const TextBlock = loadable(() => import('./TextBlock'))
const LocationHours = loadable(() => import('./LocationHours'))
const BannerFooter = loadable(() => import('./BannerFooter'))
const Embed = loadable(() => import('./Embed'))

const SliceZone = React.memo(({ sliceZone, ...props }) => {

    const sliceComponents = {
        text__block: TextBlock,
        location__hours: LocationHours,
        banner_footer: BannerFooter,
        embed_book_now: Embed
    }

    const sliceZoneContent = sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]

        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`} />
        }
        return null
    })

    return sliceZoneContent
})

export default SliceZone