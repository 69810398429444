import { Link } from 'gatsby';
import React, { memo } from 'react';

const BreadcrumbBrand = memo((props) => {
  const { categories, current, uid, className, seo } = props;

  const links = () => {
    const dataLinks = [];

    categories?.forEach(({ brand }) => {
      if (brand && brand?.document && brand?.document?.data) {
        const {
          document: {
            data: { name, url },
          },
        } = brand;
        dataLinks.push({
          slug: `/${url}`,
          label: name,
        });
      }
    });

    return dataLinks;
  };

  if (!seo) {
    return (
      <nav
        className={`main__archive-product__breadcrumb ${className || ''}`}
        aria-label="breadcrumb"
        itemScope
        itemtype="http://schema.org/Breadcrumb"
      >
        <ol className="breadcrumb bg-white pl-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/brands">Brands</Link>
          </li>
          {links().map(({ label, slug }, index) => (
            <li key={index} className="breadcrumb-item">
              <Link to={slug}>{label}</Link>
            </li>
          ))}
          <li className="breadcrumb-item active" aria-current="page">
            {current}
          </li>
        </ol>
      </nav>
    );
  }

  return (
    <nav className={`main__archive-product__breadcrumb ${className || ''}`} aria-label="breadcrumb">
      <ol
        itemScope
        itemType="http://schema.org/BreadcrumbList"
        className="breadcrumb bg-white pl-0"
      >
        <li
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link to="/" itemProp="item">
            <span itemProp="name">Home</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        <li
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link to="/brands" itemProp="item">
            <span itemProp="name">Brands</span>
          </Link>
          <meta itemProp="position" content="2" />
        </li>
        {links().map(({ label, slug }, index) => (
          <li
            key={index}
            itemScope
            className="breadcrumb-item"
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            <Link itemProp="item" to={slug}>
              <span itemProp="name">{label}</span>
            </Link>
            <meta itemProp="position" content={index + 3} />
          </li>
        ))}
        <li
          className="breadcrumb-item active"
          aria-current="page"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link className="d-none" to={`/${uid}`} itemProp="item">
            <span itemProp="name">{current}</span>
          </Link>
          <meta itemProp="position" content={links()?.length + 2} />
          {current}
        </li>
      </ol>
    </nav>
  );
});

export default BreadcrumbBrand;
