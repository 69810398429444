import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import {
  IntroWrapper,
  IntroTitle,
  Desciption,
  BgText,
  IntroContentWrapper,
} from './style';

type IntroProps = {
  data?: {
    category_name?: string,
    description?: any
    image?: any
  }
};

const Intro: React.FunctionComponent<IntroProps> = React.memo(({ data }) => {

  return (
    <IntroWrapper>
      <BgText>
        <GatsbyImage image={getImage(data.image)} alt={data?.category_name} />
      </BgText>
      <IntroContentWrapper>
        <IntroTitle>{data?.category_name}</IntroTitle>
        {data?.description && <Desciption>
          <RichText render={data?.description?.richText} />
        </Desciption>}
      </IntroContentWrapper>
    </IntroWrapper>
  );
});

export default Intro;
