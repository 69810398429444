import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';

const CustomLink = loadable(() => import('../../../components/common/CustomLink'));

const TextBlock = memo(({ slice, index, ...props }) => {
  const { banner_link, full_width_banner } = slice.primary;

  return (
    <>
      {props[`before__${slice.slice_type}__${index}`]}
      <section className={`${slice.slice_type}`}>
        <CustomLink link={banner_link} isLink={false}>
          <GatsbyImage image={full_width_banner.gatsbyImageData} alt={full_width_banner.alt} />
        </CustomLink>
      </section>
      {props[`after__${slice.slice_type}__${index}`]}
    </>
  );
});

export default TextBlock;

export const query = graphql`
  fragment BlogPostNewDataBodyPromotionalBanner on PrismicBlogPostNewDataBodyPromotionalBanner {
    primary {
      banner_link {
        uid
        type
        link_type
        url
      }
      full_width_banner {
        gatsbyImageData(layout: FULL_WIDTH)
        alt
      }
    }
  }
`;
