import React, { memo, useEffect, useState } from 'react';
import { formatPrice, getImgCropShopify, validateAddToCart } from '../../utils/helpers';
import { useStore } from '../../hooks';
import { getVariantById, getVariantByProductId } from '../../services/productVariant';
import GatsbyImage from '../common/GatsbyImage';

const ProductBestSeller = memo(({ product, image, setState: setIds }) => {
  const { addVariantToCart, checkout } = useStore();
  const init = {
    quantity: 0,
    compareAtPrice: 0,
    price: 0,
  };
  const [state, setState] = useState(init);

  const handleAddToCart = (_product) => {
    validateAddToCart(
      addVariantToCart,
      checkout,
      _product,
      _product?.variants?.[0]?.admin_graphql_api_id
    );
    // console.log({ _product });
    // addVariantToCart(_product, _product?.variants?.[0]?.admin_graphql_api_id, 1);
  };

  useEffect(() => {
    if (product?.admin_graphql_api_id) {
      getVariantByProductId(product.admin_graphql_api_id).then((data) => {
        if (!data) {
          setState(init);
          setIds((_ids) => [..._ids, product.id]);
          return;
        }

        const no_dispatch = data.product.no_dispatch;
        const { quantityAvailable, price, compareAtPrice } = data || {};
        if (quantityAvailable > 0) {
          setState({
            quantity: quantityAvailable,
            price: price.amount,
            compareAtPrice: compareAtPrice.amount,
          });
        } else {
          getVariantById(product.variants[0].admin_graphql_api_id).then(({ data }) => {
            const { inventoryPolicy } = data?.productVariant || {};
            const policy = inventoryPolicy === 'CONTINUE';
            const comingSoon =
              policy &&
              (no_dispatch?.value?.indexOf('business days') !== -1 ||
                ['Pre-Order', 'Ready'].includes(no_dispatch?.value));
            let _quantity = 0;
            if (policy || comingSoon) {
              _quantity = 1;
            } else {
              setIds((_ids) => [..._ids, product.id]);
            }

            setState({
              price: price.amount,
              compareAtPrice: compareAtPrice.amount,
              quantity: _quantity,
            });
          });
        }
      });
    }
  }, [product]);

  if (state.quantity < 1) return;

  return (
    <div className="product">
      <div className="product__img">
        {image?.gatsbyImageData ? (
          <GatsbyImage image={image} alt={image.altText} />
        ) : (
          <img
            src={getImgCropShopify(
              product?.images?.[1]?.src || product?.images?.[0]?.src,
              '130x130'
            )}
            alt={product.title}
          />
        )}
      </div>

      <div className="product__content">
        <div className="product__title title-format">{product?.title}</div>

        {/* <div className='product__body text-format' dangerouslySetInnerHTML={{ __html: product?.body_html }} /> */}

        {state.price > 0 && (
          <div className="product__add-to-cart">
            <span onClick={() => handleAddToCart(product)}>
              Add To Bag - {formatPrice(state.price)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
});

export default ProductBestSeller;
