import React from 'react'
import Slider from "react-slick";
import loadable from "@loadable/component";

const CustomLink = loadable(() => import('../../../components/common/CustomLink'))
const GatsbyImage = loadable(() => import('./../../../components/common/GatsbyImage'))

const Banner = React.memo(({ data }) => {

    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: 'ease'
    };

    return (
        <div className="main__banner container-xl">
            <Slider {...settings}>

                {data.map(({ image, slider_link }, i) => (
                    <div key={i} className='main__banner__box'>
                        <div className="image">
                            <CustomLink link={slider_link}>
                                <GatsbyImage className='d-none d-md-block' image={image.gatsbyImageData} alt={image.alt || "activeskin"} />
                                <GatsbyImage className='d-block d-md-none' image={image.thumbnails.mobile.gatsbyImageData} alt={image.thumbnails.mobile.alt || "activeskin"} />
                            </CustomLink>
                        </div>

                    </div>
                ))}

            </Slider>
        </div>
    )
})

export default Banner