import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../utils/helpers';
import GatsbyImage from '../common/GatsbyImage';
import CustomLink from '../common/CustomLink';
import FormContact from './contacts/FormContact';

const TextBlock = memo(({ slice, uid }) => {
  const {
    primary: { button_link, button_link_text, image_content, content },
    slice_type,
  } = slice;

  return (
    <section className={`page__${slice_type} page__content ${slice_type} container`}>
      {(content.richText.length > 0 || image_content?.gatsbyImageData) && (
        <div className="row">
          <div className="col-md text-md-left text-center pb-md-0 pb-3">
            <RichText render={content.richText} serializeHyperlink={serializeHyperlink} />
            {button_link_text && (
              <button type="button" className="page__content__button btn-page">
                <CustomLink link={button_link} text={button_link_text} />
              </button>
            )}
          </div>

          {uid === 'contacts' ? (
            <div className="col-md-6">
              <FormContact />
            </div>
          ) : (
            image_content?.gatsbyImageData && (
              <div className="col-md-6">
                {image_content?.gatsbyImageData && (
                  <GatsbyImage image={image_content?.gatsbyImageData} alt={image_content?.alt} />
                )}
              </div>
            )
          )}
        </div>
      )}
    </section>
  );
});

export default TextBlock;

export const query = graphql`
  fragment StaticPageDataBody1TextBlock on PrismicStaticPageDataBody1TextBlock {
    primary {
      button_link {
        url
        uid
        type
        link_type
      }
      button_link_text
      content {
        richText
      }
      image_content {
        gatsbyImageData
        alt
      }
    }
  }
`;
