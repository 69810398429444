import { CircularProgress } from '@mui/material';
import { Link } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { getDiscount } from '../../services/discount';
import { getVariantByProductId } from '../../services/productVariant';
import { formatPrice } from '../../utils/helpers';

const FooterPage = ({ savePrice }) => {
  const { checkout, addVariantToCart, getRules } = useStore();
  const [discount, setDiscount] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const openCheckout = async () => {
    setLoading(true);
    await getRules();
    setLoading(false);

    window.location.href = checkout.webUrl;
  };

  const applyDiscount = async () => {
    if (discount?.trim()?.length > 0) {
      const data = await getDiscount(discount);

      if (data && data?.buyX?.length > 0 && data?.getY?.length > 0) {
        const { buyX, getY, where } = data;
        const checkX =
          checkout.lineItems?.findIndex(
            (item) =>
              item.variant.product.id === `gid://shopify/Product/${buyX[0]}` &&
              item.quantity >= where.buyX
          ) !== -1;

        const checkY =
          checkout.lineItems?.findIndex(
            (item) =>
              item.variant.product.id === `gid://shopify/Product/${getY[0]}` &&
              item.quantity >= where.getY
          ) === -1;

        if (checkX && checkY) {
          const variant = await getVariantByProductId(`gid://shopify/Product/${getY[0]}`);
          if (variant) {
            addVariantToCart({}, variant.id, where.getY).then(() => {
              location.href = `${checkout.webUrl}&discount=${discount}`;
            });
            return;
          }
        }
      }
      location.href = `${checkout.webUrl}&discount=${discount}`;
    }
  };

  return (
    <div className="cart__footer">
      <div className="cart__subtotal text-lg-right text-center">
        Subtotal: <span>{formatPrice(Number(checkout?.totalPrice?.amount))}</span>
      </div>
      {savePrice > 0 && (
        <div className="cart__saving-price text-lg-right text-center">
          Your savings off RRP are <span>{formatPrice(savePrice)}</span>
        </div>
      )}
      <div className="cart__ctas d-lg-flex align-items-center justify-content-between">
        <div className={`cart__discount ${discount.length <= 0 ? 'disabled' : ''}`}>
          <label className="cart__discount--label" htmlFor="cart__discount--input">
            Gift card or discount code
          </label>
          <input
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            className="cart__discount--input"
            placeholder="Gift card or discount code"
            type="text"
          />
          <button
            onClick={applyDiscount}
            className={`cart__discount--btn-apply btn-activeskin button ${
              discount.length <= 0 ? 'disabled' : 'primary'
            }`}
            disabled={discount.length <= 0}
          >
            Apply
          </button>
        </div>

        <div className="cart__button">
          <button className="btn-activeskin active cart__button--shopping">
            <Link to="/">Continue Shopping</Link>
          </button>
          <button className="btn-activeskin primary cart__button--checkout" onClick={openCheckout}>
            Checkout {loading && <CircularProgress className="ml-1" size={13} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(FooterPage);
