import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Lazyload from 'react-lazyload';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../../utils/helpers';
import loadable from '@loadable/component';

const ReactSlick = loadable(() => import('./../../../components/common/ReactSlick'));

const SliderTreamtentPages = React.memo(({ pages: { nodes = [] } }) => {
  const settings = {
    arrows: false,
    dots: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };

  return (
    <Lazyload throttle={500} height={300} scroll={true}>
      <div className="main__treamtent-pages treamtent-pages -bg-color">
        <div className="container-xl">
          <ReactSlick {...settings} className="treamtent-pages__slick-slider">
            {nodes?.map(({ uid, url, data: { title, avatar, short_description } }, i) => (
              <article className="treamtent-pages__slick-item text-center" key={i}>
                <div className="treamtent-pages__slick-item--img">
                  <Link to={url} className="d-block" />
                  {avatar?.gatsbyImageData && (
                    <GatsbyImage image={avatar?.gatsbyImageData} alt={avatar?.alt || title} />
                  )}
                </div>
                <div className="treamtent-pages__inner">
                  <div className="treamtent-pages__slick-item--title">
                    <Link to={url}>
                      <h3>{title}</h3>
                    </Link>
                  </div>
                  <div className="treamtent-pages__slick-item--short-description">
                    <RichText
                      render={short_description.richText}
                      serializeHyperlink={serializeHyperlink}
                    />
                  </div>
                </div>
              </article>
            ))}
          </ReactSlick>
        </div>
      </div>
    </Lazyload>
  );
});

export default SliderTreamtentPages;
