import React, { memo, useEffect, useRef } from 'react';
import { configOkeReview } from '../../utils/helpers';

const OkendoReviewsWidget = ({ productId }) => {
  const widgetContainer = useRef(null);

  const initialiseReviewsWidget = () => {
    window.okeWidgetApi.initWidget(widgetContainer.current);
  };

  useEffect(() => {
    configOkeReview();
    const loadReviewsWidget = setInterval(() => {
      if (window.okeWidgetApi) {
        initialiseReviewsWidget();
        clearInterval(loadReviewsWidget);
      }
    }, 500);
  }, []);

  return (
    <div
      ref={widgetContainer}
      id="oke-reviews-widget"
      data-oke-widget
      data-oke-reviews-product-id={`shopify-${productId}`}
      // data-oke-reviews-product-id={`shopify-8254388502692`}
    ></div>
  );
};

export default memo(OkendoReviewsWidget);
