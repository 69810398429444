import React from 'react';

const NavDotActive = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 15 15"
    style={{ enableBackground: 'new 0 0 15 15' }}
    xmlSpace="preserve"
  >
    <style type="text/css" dangerouslySetInnerHTML={{ __html: '\n\t.st0{fill:#333333;}\n' }} />
    <circle className="st0" cx="7.5" cy="7.5" r="7.5" />
  </svg>
);

export default NavDotActive;
