import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { IoIosSearch, IoIosClose } from 'react-icons/io';
import { DrawerProvider } from '../../blog/drawer/drawer-context';
import Menu from './menu';
import MobileMenu from './mobile-menu';
import HeaderWrapper, {
  NavbarWrapper,
  Logo,
  MenuWrapper,
  NavSearch,
  NavSearchButton,
  NavSearchWrapper,
  SearchCloseButton,
  NavSearchFromWrapper,
  Col12,
} from './navbar.style';
import { StaticImage } from 'gatsby-plugin-image';

type NavbarProps = {
  className?: string;
};

const Navbar: React.FunctionComponent<NavbarProps> = React.memo(({ className, ...props }) => {

  const { prismicSalonMegaMenu: { data: { menus } } } = useStaticQuery(query)

  const [state, setState] = useState({ toggle: false, search: '' });

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };

  // Add al classs to an array
  const addAllClass = ['header'];

  // cla ssName p rop checking
  if (className) {
    addAllClass.push(className);
  }

  return (
    <HeaderWrapper className={addAllClass.join(' ')} {...props}>
      <NavbarWrapper className="navbar px-0">
        <div className="container-xl">
          <DrawerProvider>
            <MobileMenu items={menus} logo={''} />
          </DrawerProvider>

          <Col12>
            <Logo>
              <Link to="/salon">
                <StaticImage src='../../../images/activeskin-salon-logo-white.png' alt="logo" />
              </Link>
            </Logo>
          </Col12>

          <MenuWrapper>
            <Menu items={menus} />
          </MenuWrapper>
        </div>

      </NavbarWrapper>

      <NavSearchWrapper className={state.toggle === true ? 'expand' : ''}>
        <NavSearchFromWrapper>
          <SearchCloseButton
            type="submit"
            aria-label="close"
            onClick={toggleHandle}
          >
            <IoIosClose />
          </SearchCloseButton>
        </NavSearchFromWrapper>
      </NavSearchWrapper>
    </HeaderWrapper>
  );
})

export default Navbar;

export const query = graphql`
{
  prismicSalonMegaMenu {
    data {
      menus {
        item
        item_link {
          url
          uid
          link_type
          type
        }
      }
    }
  }
}
`