import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Seo from '../../components/common/Seo';

const GraphQLErrorList = loadable(() => import('../../components/common/GraphqlErrorList'));
const Layout = loadable(() => import('../../components/blog/Layout'));
const HomeBlog = loadable(() => import('../../containers/blog/home'));

export const Head = ({ location, params, data, pageContext }) => {
  const seo = {
    title: 'Beauty and Skincare Blog | The Ultimate Style Guide | Afterpay | Activeskin',
    description:
      "Keep up to date with this season's hottest beauty trends. Tips, tricks, tutorials and product releases, curated by Activeskin's beauty professionals.",
    location,
  };

  return <Seo {...seo} />;
};

const PersonalBlogPage = memo((props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const { allPosts } = data;

  return (
    <Layout title="The Active Edit" location={location}>
      <HomeBlog posts={allPosts} link="/blog" />
    </Layout>
  );
});

export default PersonalBlogPage;

export const query = graphql`
  {
    allPosts: allPrismicBlogPostNew(
      sort: { fields: data___published_date, order: DESC }
      skip: 0
      limit: 21
    ) {
      edges {
        node {
          data {
            body {
              ... on PrismicBlogPostNewDataBodyPostContentFromWpBlog {
                primary {
                  post_content
                }
              }
            }
            category_group {
              category {
                document {
                  ... on PrismicBlogCategory {
                    data {
                      category_name
                    }
                  }
                }
              }
            }
            featured_image {
              gatsbyImageData
              alt
            }
            post_name
            secondary_title
          }
          url
          uid
          tags
          first_publication_date(formatString: "dddd M yy")
        }
      }

      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;
