import React, { memo } from 'react'
import { graphql } from 'gatsby'

const ContentFromWp = memo(({ slice, ...props }) => {

  const { primary: { content_from_wp }, slice_type } = slice

  return (
    <section className={slice_type} dangerouslySetInnerHTML={{ __html: content_from_wp }} />
  )
})

export default ContentFromWp

export const query = graphql`
  fragment SalonTreatmentPagesDataBodyContentFromWp on PrismicSalonTreatmentPagesDataBodyContentFromWp {
    primary {
      content_from_wp
    }
  }
`