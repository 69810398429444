import React, { memo } from "react";

const GraphQLErrorList = memo(({ errors }) => (
  <div>
    <h1>GraphQL Error</h1>
    {errors.map(error => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
))

export default GraphQLErrorList;
