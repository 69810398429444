import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../utils/helpers';

const TextBlock = memo(({ slice }) => {

  const { primary: { title, content }, slice_type } = slice

  return (
    <section className={slice_type}>
      <div className={`${slice_type}__title -title`}>
        <h3>{title}</h3>
      </div>
      <div className={`${slice_type}__content`}>
        <RichText render={content.richText} serializeHyperlink={serializeHyperlink} />
      </div>
    </section>
  )
})

export default TextBlock

export const query = graphql`
  fragment PromotionsPageDataBodyTCBlock on PrismicPromotionsPageDataBodyTCBlock {
    primary {
     title
     content {
       richText
     }
   }
  }
`