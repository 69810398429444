import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../../utils/helpers';

const TextBlock = memo(({ slice, index, ...props }) => {
  return (
    <>
      {props[`before__${slice.slice_type}__${index}`]}
      <section className={`${slice.slice_type}`}>
        <RichText render={slice.primary.text.richText} serializeHyperlink={serializeHyperlink} />
      </section>
      {props[`after__${slice.slice_type}__${index}`]}
    </>
  );
});

export default TextBlock;

export const query = graphql`
  fragment BlogPostNewDataBodyTextBlock on PrismicBlogPostNewDataBodyTextBlock {
    primary {
      text {
        richText
      }
    }
  }
`;
