import { useStore } from '../../hooks';
import React, { memo } from 'react';
import { filterHasFinancialOffers } from '../../utils/helpers';

const ListFinancialOffers = ({ product }) => {
  const { offers } = useStore();

  return filterHasFinancialOffers(offers, product)?.map((offer, i) => {
    const { free_gift_subtitle, terms_conditions, product_page_text, color_offer_title } = offer;
    return (
      <div key={i} className="free-gift">
        <div
          className="free-gift__sub-title"
          style={{
            color: `${color_offer_title || '#dc3545'}`,
          }}
        >
          {free_gift_subtitle || 'FINANCIAL OFFER'}
        </div>
        {(product_page_text?.html || product_page_text?.text) && (
          <div
            className="free-gift__text"
            dangerouslySetInnerHTML={{
              __html: product_page_text?.html || product_page_text?.text,
            }}
          ></div>
        )}
        <div className="free-gift__terms-conditions">{terms_conditions}</div>
      </div>
    );
  });
};

export default memo(ListFinancialOffers);
