import React, { memo, useState } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';
import SlideDown from 'react-slidedown';

const CustomLink = loadable(() => import('../../components/common/CustomLink'));

const SiteMap = memo(({ footer_column_title, child, index }) => {
  const isBrowser = typeof window !== 'undefined';

  const isMobile = isBrowser && window.innerWidth < 992;

  const [state, setState] = useState(false);

  /**
   * handle collapse on mobile
   * @param {*} e
   */

  const hanldeCollapseOnMobile = () => {
    if (isMobile) {
      setState(!state);
    }
  };

  return (
    <div
      className={`footer__container__site-map ${
        [1, 2].includes(index) ? 'col-lg-3' : `col-lg-2 ${index == 3 ? 'pl-lg-0' : ''}`
      } ${state ? 'show' : ''}`}
    >
      <h3 onClick={hanldeCollapseOnMobile} className="title">
        {footer_column_title}
      </h3>
      <SlideDown className={'my-dropdown-slidedown'}>
        {(!isMobile || (isMobile && state)) && (
          <ul className="footer__container__site-map__links">
            {child.map((element, index2) => {
              return (
                <li key={index2}>
                  {element?.icon?.gatsbyImageData && (
                    <span className="icon">
                      <GatsbyImage image={getImage(element.icon)} alt={element?.icon?.alt || ''} />
                    </span>
                  )}
                  <CustomLink link={element.url} text={element.link_name} />
                </li>
              );
            })}
          </ul>
        )}
      </SlideDown>
    </div>
  );
});
export default SiteMap;
