import React, { memo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStore } from '../../hooks';
import { getProductById, getVariantById } from '../../services/productVariant';
import { overwriteHidePrice } from '../../utils/helpers';

const ITEM_HEIGHT = 48;

const Actions = ({ products }) => {
  const { checkout, metafields, handleCustomAttributes, addAllWhistlistToCart, setState } =
    useStore();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateAddAll = (index, _products) => {
    if (index === products.length - 1) {
      if (_products.length === 0) {
        setState((_state) => ({ ..._state, isCartOpen: true }));
        return;
      }
      addAllWhistlistToCart(_products);
    }
  };

  const handleAddAllToCart = async () => {
    const _products = [];
    await products.forEach(async ({ empi, epi }, index) => {
      const variantId = `gid://shopify/ProductVariant/${epi}`;

      const lineItem = checkout?.lineItems?.findIndex(({ variant }) => variant?.id === variantId);
      if (lineItem !== -1) {
        validateAddAll(index, _products);
        return;
      }

      const purchase_method =
        metafields[empi]?.filter(({ key }) => key === 'purchase_method')?.[0]?.value || '';

      const hide_price =
        metafields[empi]?.filter(({ key }) => key === 'hide_price')?.[0]?.value || false;

      if (
        purchase_method === 'Contact to Order' ||
        purchase_method === 'Hide Price' ||
        purchase_method === 'Phone To Order' ||
        hide_price ||
        purchase_method === 'Book a Consult'
      )
        return;

      const data = await getProductById(`gid://shopify/Product/${empi}`);
      if (!data) return;
      const { productType, variant, collections, vendor } = data || {};
      if (overwriteHidePrice(vendor, hide_price, purchase_method)) return;
      const product = {
        collections,
        productType,
      };
      const customAttributes = handleCustomAttributes(product, false);

      const _product = {
        variantId: `gid://shopify/ProductVariant/${epi}`,
        quantity: 1,
        customAttributes,
      };

      if (variant?.quantityAvailable && variant?.quantityAvailable > 0) {
        _products.push(_product);
      } else {
        const _data = await getVariantById(variantId);
        if (!_data) return;
        const { inventoryPolicy } = _data?.productVariant || {};
        const no_dispatch =
          metafields[empi]?.filter(({ key }) => key === 'no_dispatch')?.[0]?.value || '';

        const policy = inventoryPolicy === 'CONTINUE';
        const comingSoon =
          policy &&
          (no_dispatch?.indexOf('business days') !== -1 ||
            ['Pre-Order', 'Ready'].includes(no_dispatch));

        if (policy || comingSoon) {
          if (comingSoon)
            _product.customAttributes = handleCustomAttributes(product, false, no_dispatch);
          _products.push(_product);
        }
      }

      validateAddAll(index, _products);
    });
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleClose}>SHARE</MenuItem>
        <MenuItem onClick={handleAddAllToCart}>ADD ALL TO CART</MenuItem>
        <MenuItem onClick={handleClose}>CLEAR ALL</MenuItem>
      </Menu>
    </div>
  );
};

export default memo(Actions);
