import React, { memo, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../utils/helpers';

const BrandsDirectory = (props) => {
  const { brands, title } = props;
  const [state, setState] = useState({});
  const [key, setKey] = useState('A');

  useEffect(() => {
    let tabs = {};
    for (let i = 0; i < 26; i++) {
      let key = String.fromCharCode(65 + i);
      let value = brands.filter((brand) => brand.name[0].toUpperCase() === key);
      if (value.length > 0)
        tabs[key] = value.sort((a, b) => {
          const aName = a.name.toUpperCase();
          const bName = b.name.toUpperCase();
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });
    }
    setState(tabs);

    if (location?.hash != '') {
      setKey(location?.hash?.replace('#', ''));
    }
  }, []);

  const handleRenderTabContent = () => {
    const content = state[key] || [];
    const content1 = [];
    const content2 = [];
    const content3 = [];
    const content4 = [];

    let i = 0,
      j = 0;
    while (true) {
      if (i >= content.length) break;
      j++;
      const value = content[i];
      switch (j) {
        case 1:
          content1.push(value);
          break;

        case 2:
          content2.push(value);
          break;

        case 3:
          content3.push(value);
          break;

        default:
          content4.push(value);
          j = 0;
          break;
      }
      i++;
    }

    return (
      <div className="row">
        {content1.length > 0 && (
          <div className="col-md-3 col-6">
            <ul>
              {content1.map(({ name, url }, i) => (
                <li key={i}>
                  <Link to={`/${url}`}>{name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {content2.length > 0 && (
          <div className="col-md-3 col-6">
            <ul>
              {content2.map(({ name, url }, i) => (
                <li key={i}>
                  <Link to={`/${url}`}>{name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {content3.length > 0 && (
          <div className="col-md-3 col-6">
            <ul>
              {content3.map(({ name, url }, i) => (
                <li key={i}>
                  <Link to={`/${url}`}>{name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {content4.length > 0 && (
          <div className="col-md-3 col-6">
            <ul>
              {content4.map(({ name, url }, i) => (
                <li key={i}>
                  <Link to={`/${url}`}>{name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="brands-directory">
      <div className="brands-directory__header">
        <RichText render={title.richText} serializeHyperlink={serializeHyperlink} />
      </div>

      <div className="brands-directory__tabs">
        <ul>
          {Object.keys(state).map((v, i) => (
            <li
              key={i}
              onClick={() => {
                setKey(v);
                location.hash = `#${v}`;
              }}
              className={`${key === v ? 'active' : ''}`}
            >
              {v}
            </li>
          ))}
        </ul>
      </div>

      {/* <div className="brands-directory__tab-content">{handleRenderTabContent()}</div> */}
    </section>
  );
};

export default memo(BrandsDirectory);
