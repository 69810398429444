import React, { memo, useState } from 'react';
import loadable from '@loadable/component';
import EmailContactUs from './EmailContactUs';
import Recaptcha from 'react-recaptcha';

const FormGroup = loadable(() => import('./FormGroup'));

const FormContact = memo((props) => {
  const initDataForm = {
    name: '',
    telephone: '',
    email: '',
    order: '',
    comment: '',
    pref_contact_method: 'Email',
    recapcha: true,
  };

  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState({ name: '', telephone: '', email: '' });
  const [response, setResponse] = useState({ status: 500, message: null });

  const submitContactUs = () => {
    event.preventDefault();
    const { name, telephone, email, order, comment, recapcha } = dataForm;
    const _errors = {};
    let flag = false;
    if (name === '') {
      _errors['name'] = 'This is a required field';
      flag = true;
    }
    if (telephone === '') {
      _errors['telephone'] = 'This is a required field';
      flag = true;
    }
    if (email === '') {
      _errors['email'] = 'This is a required field';
      flag = true;
    }

    if (!recapcha) {
      _errors['recapcha'] = 'ReCAPTCHA is a required field';
      flag = true;
    }

    setErrors(_errors);
    if (flag) return;

    fetch(`/api/contact-us`, {
      method: `POST`,
      body: JSON.stringify({
        ...dataForm,
        revice_mail_body: EmailContactUs(name, telephone, email, order, comment),
      }),
      headers: {
        'content-type': `application/json`,
      },
    })
      .then((result) => result.json())
      .then((res) => {
        setResponse(res);
      })
      .catch((error) => {
        setResponse(error);
      });
  };

  const verifyCallback = function (response) {
    let recapcha = false;
    if (response) {
      recapcha = true;
    }
    setDataForm((_form) => ({ ..._form, recapcha }));
  };

  const expiredCallback = function () {
    setDataForm((_form) => ({ ..._form, recapcha: false }));
  };

  const callback = function () {
    console.log('Loaded recapcha');
    setDataForm((_form) => ({ ..._form, recapcha: false }));
  };

  /**
   * handle change data form
   * @param {*} e
   */
  const handleChangeDataForm = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    switch (e.target.name) {
      case 'name':
        if (errors.name) setErrors({ ...errors, name: '' });
        break;

      case 'email':
        if (errors.email) setErrors({ ...errors, email: '' });
        break;

      case 'telephone':
        if (errors.telephone) setErrors({ ...errors, telephone: '' });
        break;

      default:
        break;
    }
  };

  if (response.status !== 200) {
    return (
      <form
        onSubmit={submitContactUs}
        action="/api/contact-us"
        className="page__content__contact-form contact-form"
        method="POST"
      >
        {/* <div className='contact-form__header'>
                    <h2 className="contact-form__header__title">We would love to hear from you...</h2>
                </div> */}

        <div className="contact-form__body">
          <FormGroup className={`${errors?.name ? 'validation-error' : ''}`}>
            <label htmlFor="name">
              Name: <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="form-control"
              value={dataForm.name}
              onChange={handleChangeDataForm}
            />
            {errors?.name && <div className="invalid-feedback">{errors.name}</div>}
          </FormGroup>

          <FormGroup>
            <label htmlFor="order">
              Order <span>#:</span>
            </label>
            <input
              type="text"
              name="order"
              id="order"
              className="form-control"
              value={dataForm.order}
              onChange={handleChangeDataForm}
            />
          </FormGroup>

          <FormGroup className={`${errors?.email ? 'validation-error' : ''}`}>
            <label htmlFor="email">
              Email: <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="email"
              id="email"
              className="form-control"
              value={dataForm.email}
              onChange={handleChangeDataForm}
            />
            {errors?.email && <div className="invalid-feedback">{errors.email}</div>}
          </FormGroup>

          <FormGroup className={`${errors?.telephone ? 'validation-error' : ''}`}>
            <label htmlFor="telephone">
              Phone: <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="telephone"
              id="telephone"
              className="form-control"
              value={dataForm.telephone}
              onChange={handleChangeDataForm}
            />
            {errors?.telephone && <div className="invalid-feedback">{errors.telephone}</div>}
          </FormGroup>

          <FormGroup>
            <label htmlFor="comment">Comments:</label>
            <textarea
              className="form-control"
              name="comment"
              cols={5}
              rows={7}
              onChange={handleChangeDataForm}
              value={dataForm.comment}
            />
          </FormGroup>

          <div className="form-group-checkbox">
            <label htmlFor="pref_contact_method">Preferred Contact Method:</label>

            <div className="checkbox-group">
              <div className="checkbox-item">
                <span
                  className={`${dataForm.pref_contact_method === 'Phone' ? 'checked' : ''}`}
                  onClick={() => setDataForm({ ...dataForm, pref_contact_method: 'Phone' })}
                />
                via phone
              </div>

              <div className="checkbox-item">
                <span
                  className={`${dataForm.pref_contact_method === 'Email' ? 'checked' : ''}`}
                  onClick={() => setDataForm({ ...dataForm, pref_contact_method: 'Email' })}
                />
                via email
              </div>

              <Recaptcha
                className="recapcha"
                sitekey="6LeidyYpAAAAAK06IzQlqSYhdZYYRMp7kG---_pA"
                verifyCallback={verifyCallback}
                expiredCallback={expiredCallback}
                onloadCallback={callback}
              />
            </div>
          </div>
        </div>

        <div className="contact-form__footer">
          <button
            className="contact-form__footer__btn-submit btn-page"
            type="submit"
            title="Submit"
          >
            Submit
          </button>
          <div className="invalid-feedback d-block">* Required Fields</div>
        </div>
      </form>
    );
  }

  return (
    <div id="messages_product_view">
      <div className="top-line">
        <div className="top-line-inside"></div>
      </div>
      <br /> Your Enquiry <br /> has been successfully <br /> submitted
    </div>
  );
});

export default FormContact;
