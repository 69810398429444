import React, { useState } from 'react';
import FaqQuestionGroup from "./faq_question_group";
import { SlideDown } from 'react-slidedown'

const FaqCategoryItem = (props) => {
    const [active, setActive] = useState(false);
    const { category } = props;

    return (
        <>
            <li className={`faq__category-item ${active ? "active" : ""}`} onClick={() => setActive(!active)}>{category.faq_category_name}</li>
            <SlideDown className={'my-dropdown-slidedown'}>
                {active && <FaqQuestionGroup question_groups={category.faq_question_group} />}
            </SlideDown>
        </>
    )
}

export default FaqCategoryItem
