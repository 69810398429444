import React, { memo } from 'react';
import { Script, graphql } from 'gatsby';

const HtmlBlock = memo(({ slice }) => {
  const {
    primary: { install_okendo_js, html },
    slice_type,
  } = slice;

  return (
    <>
      {install_okendo_js && (
        <>
          {/* okendo review */}
          <Script
            type="text/javascript"
            defer
            src="https://d3hw6dc1ow8pp2.cloudfront.net/referrals/js/referrals-api.js"
          ></Script>
          <Script id="okeReferralSettings" type="application/json">
            {JSON.stringify({ subscriberId: 'a3596b75-2092-45cb-a29b-a5b733bddd6b' })}
          </Script>
          {/* ./okendo review */}
        </>
      )}
      <section
        className={`page__${slice_type} page__content ${slice_type} container`}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </>
  );
});

export default HtmlBlock;

export const query = graphql`
  fragment StaticPageDataBody1HtmlBlock on PrismicStaticPageDataBody1HtmlBlock {
    primary {
      install_okendo_js
      html
    }
  }
`;
