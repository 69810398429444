import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../utils/helpers';
import loadable from '@loadable/component';

const AccordianContent = loadable(() => import('./AccordianContent'));

const TextBlock = memo(({ slice }) => {
  const {
    primary: {
      single_column_content: { richText: content },
      accordian_content,
    },
    slice_type,
  } = slice;

  if (content.length > 0) {
    if (accordian_content) {
      return <AccordianContent content={content} />;
    }
    return (
      <div className={`page__${slice_type} page__content ${slice_type} single-content container`}>
        <div className="row">
          <div className="col-md-12">
            <RichText render={content} serializeHyperlink={serializeHyperlink} />
          </div>
        </div>
      </div>
    );
  }
  return null;
});

export default TextBlock;

export const query = graphql`
  fragment StaticPageDataBody1SingleColumnContent on PrismicStaticPageDataBody1SingleColumnContent {
    primary {
      accordian_content
      single_column_content {
        richText
      }
    }
  }
`;
