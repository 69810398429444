import * as React from 'react';
import SiteTitleWrapper, { Title } from './style';

const SiteTitle: React.FunctionComponent<any> = React.memo(({ title, ...props }) => {
  if (!title) return null;

  return (
    <SiteTitleWrapper>
      <Title>{title}</Title>
    </SiteTitleWrapper>
  );
});

export default SiteTitle;
