import React, { memo } from "react";
import { Link } from "gatsby";

const Breadcrumb = memo(({ className, links = [], current }) => {

    return (
        <div className={className || ""}>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-white pl-0">
                    <li className="breadcrumb-item">
                        <Link to='/'>Home</Link>
                    </li>
                    {
                        links?.map(({ label, url, onClick }, i) => (
                            <li key={i} className="breadcrumb-item" onClick={onClick} >
                                <Link to={url}>{label}</Link>
                            </li>
                        ))
                    }
                    <li className="breadcrumb-item active" aria-current="page">{current}</li>
                </ol>
            </nav>
        </div>

    )

})

export default Breadcrumb