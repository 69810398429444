import * as React from "react"
import MasonryCard from "../../../../components/blog/masonry-card/masonry-card"
import Pagination from "../../../../components/blog/pagination/pagination"
import BlogPostsWrapper, { PostRow, PostCol, SecTitle } from "./style"
import { mapEdgesToNodes } from "../../../../utils/helpers"
// import posts from './data.json'
import { strip } from './../../../../utils/helpers';

type PostsProps = {
  title?: string,
  posts?: {
    edges?: [],
    pageInfo?: {
      "currentPage"?: number,
      "hasNextPage"?: boolean,
      "hasPreviousPage"?: boolean,
      "itemCount"?: number,
      "pageCount"?: number,
      "perPage"?: number,
      "totalCount"?: number
    }
  },
  link?: string,
  cuid?: string,
  categoryName?: string
}

const Posts: React.FunctionComponent<PostsProps> = ({ title, posts, cuid, categoryName, link }) => {

  const { currentPage, hasNextPage, hasPreviousPage, pageCount } = posts?.pageInfo

  return (
    <BlogPostsWrapper className="container-xl">
      {/* {title && <SecTitle>{title}</SecTitle>} */}
      <PostRow>
        {/* <Masonry className="showcase"> */}
        {typeof window !== "undefined" && mapEdgesToNodes(posts).map(({ uid, tags, data, first_publication_date, url }) => {

          let _excerpt = strip(data?.body?.[0]?.primary?.post_content || "")
          let excerptArr = _excerpt.split('.')

          let excerpt = excerptArr?.[0] + '.' || "";
          if (excerptArr?.[1]?.trim()) {
            excerpt += ` ${excerptArr?.[1].trim()}.`

            if (excerptArr?.[2]?.trim()) {
              excerpt += ` ${excerptArr?.[2].trim()}.`
            }
          }

          let cats = data?.category_group?.filter(({ category }) => category?.document?.data?.category_name)
            ?.map(({ category }) => category?.document?.data?.category_name)

          return (
            <PostCol key={uid}>
              <MasonryCard
                title={data.post_name}
                categoryName={cats.length > 0 ? cats?.join(', ') : "Trending"}
                secondary_title={data.secondary_title}
                image={data.featured_image}
                url={cuid ? `${url}?cuid=${cuid}` : url}
                date={first_publication_date}
                tags={tags}
                excerpt={`${excerpt}...`}
                readTime="4 min read"
              />
            </PostCol>
          )
        })}
        {/* </Masonry> */}
      </PostRow>
      {pageCount > 1 && <div className="pagination justify-content-end">
        <Pagination
          link={link || "/blog"}
          currentPage={currentPage}
          totalPage={pageCount}
          className="pagination"
        />
      </div>}
    </BlogPostsWrapper>
  )
}

export default Posts