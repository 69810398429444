import React, { memo, useState } from 'react';
import { Link } from 'gatsby';
import {
  getModelUrl,
  formatPrice,
  filterHasFreeGift,
  filterHasFinancialOffers,
} from '../../utils/helpers';
import loadable from '@loadable/component';
import { useStore } from '../../hooks';
import { useEffect } from 'react';

const ProductPrice = loadable(() => import('../common/ProductPrice'));
const OkendoStarSnippet = loadable(() => import('../common/OkendoStarSnippet'));
const AddToWishlist = loadable(() => import('./AddToWishlist'));
const ButtonAddToCart = loadable(() => import('./ButtonAddToCart'));
const ProductImage = loadable(() => import('./ProductImage'));
const ShipText = loadable(() => import('./ShipText'));

const Product = memo(({ product, setCounter }) => {
  const init = {
    price: -1,
    compareAtPrice: null,
    active: true,
    quantity: -1,
    currentQuantity: 0,
    purchase_method: '',
    hide_price: false,
    no_dispatch: '',
    images: product?.images,
  };

  const [state, setState] = useState(init);

  if (!state?.active) {
    setCounter((_counter) => ({ ..._counter, [product.id]: 1 }));
    return null;
  }
  const { rules, offers, metafields } = useStore();
  const [data, setData] = useState({
    valuePrice: false,
    // hide_price: false,
    // no_dispatch: '',
    // purchase_method: '',
    hasFinancialOffer: false,
    hasFreeGift: false,
    shades: [],
    isGiftCards: false,
  });

  useEffect(() => {
    setState(init);
    const shades = product.variants.filter(
      (v) =>
        (v?.selectedOptions || [])?.findIndex(
          (s) => s?.name === 'Color' || s?.name === 'Colour'
        ) !== -1
    );

    setData((_state) => ({ ..._state, shades }));
  }, [product]);

  useEffect(() => {
    let valuePrice = metafields[product.id]?.filter(({ key }) => key === 'value_price_2')?.[0]
      ?.value;
    // let hide_price =
    //   metafields[product.id]?.filter(({ key }) => key === 'hide_price')?.[0]?.value || false;

    // let no_dispatch =
    //   metafields[product.id]?.filter(({ key }) => key === 'no_dispatch')?.[0]?.value || '';

    // let purchase_method =
    //   metafields[product.id]?.filter(({ key }) => key === 'purchase_method')?.[0]?.value || '';
    // hide_price = overwriteHidePrice(product.vendor, hide_price, purchase_method);
    let isGiftCards = product?.productType === 'Gift Cards';

    setData((_state) => ({
      ..._state,
      valuePrice,
      // hide_price,
      // no_dispatch,
      // purchase_method,
      isGiftCards,
    }));
  }, [metafields, product]);

  useEffect(() => {
    const hasFinancialOffer = filterHasFinancialOffers(offers, product);
    const hasFreeGift = !hasFinancialOffer.length
      ? filterHasFreeGift(rules, product).length > 0
      : false;
    setData((_state) => ({ ..._state, hasFreeGift, hasFinancialOffer }));
  }, [product, offers, rules]);

  const {
    valuePrice,
    // hide_price,
    // no_dispatch,
    // purchase_method,
    hasFreeGift,
    shades,
    hasFinancialOffer,
  } = data;

  const { hide_price, no_dispatch, purchase_method } = state;

  return (
    <div className="col-xl-3 col-lg-4 col-6 p-lg-3 p-0">
      <div className="grid__product">
        <AddToWishlist product={product} variant={product?.variants[0] || {}} />

        <ProductImage product={product} images={state.images} />

        {!hide_price && (
          <div className="grid__product__box-value-price">
            {valuePrice && <span>VALUE {formatPrice(Number(valuePrice))}</span>}
          </div>
        )}
        <div className="grid__product__shades">
          {shades?.length > 0 && `${shades.length} Shades`}
        </div>

        <div className="grid__product__name">
          <Link to={getModelUrl('buy', product.handle)} title={product.title}>
            {product.title}
          </Link>
        </div>

        <div className="grid__product__price">
          {!data.isGiftCards && !hide_price && state.price > 0 && (
            <ProductPrice price={state.price} compareAtPrice={state.compareAtPrice} />
          )}

          {data.isGiftCards && (
            <span className="price">
              {formatPrice(product.priceRangeV2.minVariantPrice.amount)}{' '}
              {product.variants.length > 1 &&
                `- ${formatPrice(product.priceRangeV2.maxVariantPrice.amount)}`}
            </span>
          )}
        </div>

        <div
          className={`grid__product__free-text`}
          style={{
            color: `${
              hasFinancialOffer.length ? hasFinancialOffer[0].color_offer_title : '#29b5af'
            }`,
          }}
        >
          {hasFinancialOffer.length
            ? hasFinancialOffer[0].free_gift_subtitle
            : hasFreeGift
            ? 'GIFT WITH PURCHASE'
            : ''}
        </div>

        <div className="grid__product__button row">
          <div className="btn btn-quick-view text-center col-6 pl-2 pr-1 pt-0">
            <Link to={getModelUrl('buy', product.handle)} title={product.title}>
              Info
            </Link>
          </div>

          <div className="btn btn-add-to-cart text-center col-6 pr-2 pl-1 pt-0">
            <ButtonAddToCart
              hide_price={hide_price}
              product={product}
              setState={setState}
              no_dispatch={no_dispatch}
              purchase_method={purchase_method}
            />
          </div>
        </div>

        <div className="grid__product__ratings">
          <OkendoStarSnippet productId={product.legacyResourceId} />
        </div>
        {state?.currentQuantity > 0 && <ShipText quantity={state.currentQuantity} />}
      </div>
    </div>
  );
});

export default Product;
