import React, { memo } from 'react';

const ShipText = memo(({ quantity }) => {
  var today = new Date();
  var hours = today.getHours();
  var weekDay = today.getDay();

  const shipping_text = () => {
    switch (weekDay) {
      case 1:
      case 2:
      case 3:
      case 4:
        if (hours <= 14) return 'ships today';
        else return 'ships tomorrow';

      case 5:
        if (hours <= 14) return 'ships today';
        else return 'ships Monday';
      case 6:
      case 7:
        return 'ships Monday';
      default:
        return 'ships today';
    }
  };

  if (quantity < 1) return null;

  return (
    <div className="grid__product__label">
      <label>
        <span className="in-stock">In Stock</span> -{' '}
        <span className="shipping">{shipping_text()}</span>
      </label>
    </div>
  );
});

export default ShipText;
