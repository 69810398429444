import React, { memo, useEffect, useState } from 'react';
import { useQueryParams } from 'use-query-params';
import loadable from '@loadable/component';
import { loaded, textCapitalize } from '../../utils/helpers';

const FilterPrice = loadable(() => import('./filter/FilterPrice'));
const FilterColour = loadable(() => import('./filter/FilterColour'));
const FilterBrand = loadable(() => import('./filter/FilterBrand'));
const FilterMore = loadable(() => import('./filter/FilterMore'));

/**
 * handle sort text
 * @param {*} a
 * @param {*} b
 * @returns
 */
const sortText = (a, b) => {
  const _a = textCapitalize(a, false);
  const _b = textCapitalize(b, false);
  if (_a > _b) return 1;
  else if (_a < _b) return -1;
  else return 0;
};

/**
 * handle check exist tags
 * @param {*} filters
 * @param {*} dataFilters
 * @param {*} id
 * @param {*} brand
 * @returns
 */
const checkExistMetafield = (filters = [], dataFilters = {}, id, brand) => {
  let countCheck = 0,
    countCheckTrue = 0;

  filters.forEach(({ key, values }) => {
    if (key == 'brand') {
      countCheck++;
      if (values.includes(brand)) countCheckTrue++;
    } else {
      const metafields = dataFilters[key];
      countCheck++;
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        const metafield = metafields[value];
        if (metafield?.includes(id)) {
          countCheckTrue++;
          break;
        }
      }
    }
  });

  return countCheck === countCheckTrue;
};

/**
 * handle filter products
 * @param {*} filter
 * @param {*} dataFilters
 * @param {*} products
 * @returns
 */
const handleFilterProducts = (filter = {}, dataFilters = {}, products = []) => {
  const filters = Object.keys(filter)
    .filter((key) => key !== 'page' && filter[key].length > 0)
    .map((key) => ({ key, values: filter[key] }));

  if (filters.length < 1) {
    return products;
  } else {
    return products.filter(({ id, vendor }) =>
      checkExistMetafield(filters, dataFilters, id, vendor)
    );
  }
};

const Sidebar = memo((props) => {
  const {
    showSidebarMobile,
    setShowSidebarMobile,
    products,
    setNewProducts,
    setCurrentPage,
    currentPage,
    dataFilters,
    initQuery,
    dataFilter,
    children,
  } = props;

  const [filter, setFilter] = useState({});
  const [productsAfterFilter, setProductsAfterFilter] = useState([]);
  const [query, setQuery] = useQueryParams(initQuery);
  const [price, setPrice] = React.useState([]);
  // const [changeCurrentPage, setChangeCurrentPage] = React.useState(false);

  const { price_filter_group, colour_filter_group, filter_group } = dataFilter?.data || {};

  const handleFilterPrice = (arr) => {
    if (arr.length <= 0) setNewProducts(productsAfterFilter);
    else {
      const productsAfterFilterPrice = [];
      for (let i = 0; i < arr.length; i++) {
        const _price = arr[i].split('-');
        productsAfterFilterPrice.push(
          ...productsAfterFilter.filter((v) => {
            if (_price?.length === 1) {
              return (Number(v?.priceRangeV2?.minVariantPrice?.amount) || 0) > Number(_price[0]);
            }
            return (
              (Number(v?.priceRangeV2?.minVariantPrice?.amount) || 0) >= Number(_price[0]) &&
              (Number(v?.priceRangeV2?.maxVariantPrice?.amount) || 0) <= Number(_price[1])
            );
          })
        );
      }
      setNewProducts(productsAfterFilterPrice);
    }
  };

  /**
   * handle change price
   * @param {*} event
   * @param {*} newPrice
   */
  const handleChangePrice = (newPrice) => {
    let arr;
    if (price.indexOf(newPrice) === -1) {
      arr = [...price, newPrice];
    } else {
      arr = price.filter((p) => p !== newPrice);
    }
    setPrice(arr);
    handleFilterPrice(arr);
  };

  /**
   * handle toggle filter
   * @param {*} key
   * @param {*} metafield
   */
  const handleToggleFilter = (key, metafield) => {
    let newFilter;

    if (filter[key]) {
      if (filter[key].includes(metafield)) {
        newFilter = { ...filter, [key]: filter[key].filter((v) => v !== metafield) };
      } else {
        newFilter = { ...filter, [key]: [...filter[key], metafield] };
      }
    } else {
      newFilter = { ...filter, [key]: [metafield] };
    }
    newFilter.page = 1;

    setFilter(newFilter);
    setQuery(newFilter);
    currentPage !== 1 && setCurrentPage(1);

    const _handleFilterProducts = handleFilterProducts(newFilter, dataFilters, products);
    setNewProducts(_handleFilterProducts);
    setProductsAfterFilter(_handleFilterProducts);
  };

  /**
   * handle check filter active
   * @param {*} key
   * @param {*} metafield
   * @returns
   */
  const handleCheckFilterActive = (key, metafield = false) => {
    if (filter[key]) {
      if (!metafield) return filter[key].length > 0;
      return filter[key].includes(metafield);
    }
    return false;
  };

  useEffect(() => {
    if (!filter?.page) {
      setFilter(query);
      // if (query.page && currentPage !== query.page) setCurrentPage(query.page);

      const _handleFilterProducts = handleFilterProducts(query, dataFilters, products);
      setNewProducts(_handleFilterProducts);
      setProductsAfterFilter(_handleFilterProducts);
      loaded &&
        document.getElementById('header__main__nav__item--active')?.classList?.remove('active');
    }
  }, [query]);

  useEffect(() => {
    price?.length > 0 && setPrice([]);
  }, [filter]);

  // useEffect(() => {
  //   if (currentPage && currentPage !== 1 && !changeCurrentPage) setChangeCurrentPage(true);
  //   if (filter?.page && changeCurrentPage) {
  //     setQuery({ ...filter, page: currentPage });
  //   }
  // }, [currentPage, filter, changeCurrentPage]);

  return (
    <div className={`main__archive-product__sidebar sidebar ${showSidebarMobile ? 'mobile' : ''}`}>
      <div className="sidebar__header mobile-title">
        {/* <span>Filter {newProducts.length} products found</span> */}
        <span>Filter Options</span>
        <button onClick={() => setShowSidebarMobile(false)} className="close-filter"></button>
      </div>

      <div className="sidebar__body">
        {children}

        {filter_group.map(({ metafield_key, filter_title }, i) => {
          const metafields = dataFilters[metafield_key] || {};
          const _metafields = Object.keys(metafields).sort(sortText);

          if (_metafields.length > 0) {
            if (metafield_key == 'brand') {
              return (
                <FilterBrand
                  key={i}
                  metafield_key={metafield_key}
                  show={handleCheckFilterActive(metafield_key)}
                  className="--checkbox"
                  title={filter_title}
                  _metafields={_metafields}
                  metafields={metafields}
                  handleToggleFilter={handleToggleFilter}
                  handleCheckFilterActive={handleCheckFilterActive}
                />
              );
            } else if (metafield_key == 'colour') {
              return (
                <FilterColour
                  key={i}
                  metafield_key={metafield_key}
                  show={handleCheckFilterActive(metafield_key)}
                  className="--checkbox --colour"
                  title={filter_title}
                  _metafields={_metafields}
                  metafields={metafields}
                  colour_filter_group={colour_filter_group}
                  handleToggleFilter={handleToggleFilter}
                  handleCheckFilterActive={handleCheckFilterActive}
                />
              );
            } else {
              return (
                <FilterMore
                  key={i}
                  show={handleCheckFilterActive(metafield_key)}
                  className="--checkbox"
                  title={filter_title}
                  _metafields={_metafields}
                  metafields={metafields}
                  metafield_key={metafield_key}
                  handleToggleFilter={handleToggleFilter}
                  handleCheckFilterActive={handleCheckFilterActive}
                />
              );
            }
          }
        })}

        <FilterPrice
          className="--checkbox"
          title="Price"
          price={price}
          price_filter_group={price_filter_group}
          productsAfterFilter={productsAfterFilter}
          handleChangePrice={handleChangePrice}
        />
      </div>
    </div>
  );
});

export default Sidebar;
