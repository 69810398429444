import React, { memo } from 'react'
import { Link } from 'gatsby';
import { getModelUrl } from './../../../utils/helpers';

const BlogTags = memo(({ tags }) => {

    return (
        <section className={`post__tags`}>

            {
                tags.map((tag, i) => (
                    <Link key={i} to={getModelUrl("blog", tag, true)}>{tag}</Link>
                ))
            }

        </section>
    )
})

export default BlogTags