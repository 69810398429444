import { useStore } from '../../hooks';
import React, { memo } from 'react';
import { filterHasFreeGift } from '../../utils/helpers';
import FreeGift from './FreeGift';

const ListFreeGift = ({ product }) => {
  const { rules } = useStore();

  return filterHasFreeGift(rules, product)?.map((rule, i) => {
    return <FreeGift key={i} rule={rule} />;
  });
};

export default memo(ListFreeGift);
