import React from 'react'

const Banner = React.memo(({ title }) => {

    return (
        <div className="single-treatments__banner" >
            <div className='container-xl'>
                <h1 className='single-treatments__banner__title'>{title}</h1>
            </div>
        </div>
    )
})

export default Banner