import React, { memo, useState } from 'react';
import { useStore } from '../../hooks';

function GiftProducts() {
  const { addVariantToCart, giftsToChoose } = useStore();
  const [adding, setAdding] = useState(false);

  const handleAddToCart = (product, type = 'Free Gift') => {
    if (!adding) {
      setAdding(true);
      product.collections = [{ title: `Brands/${product.vendor}` }];
      addVariantToCart(product, product.variants[0].admin_graphql_api_id, 1, type).then(() => {
        setAdding(false);
      });
    }
  };

  if (
    (giftsToChoose?.free_gift || giftsToChoose?.special_gift) &&
    (giftsToChoose?.free_gift?.length > 0 || giftsToChoose?.special_gift?.length > 0)
  )
    return (
      <div className="Cart__gift">
        <div className="Cart__gift__title -title">
          <h3>Choose Your Free Gift</h3>
        </div>

        {/* show list of products is free gift */}
        {giftsToChoose?.free_gift?.map((products, i) => (
          <div key={i} className="Cart__gift__products">
            {products?.map((product, j) => (
              <div key={j} className="product">
                <div className="product__img">
                  <img src={product?.image?.src} alt={product.title} />
                </div>

                <div className="product__title title-format">
                  {product?.title?.replace('FREE', '')}
                </div>

                <div className="product__add-to-cart">
                  <span onClick={() => handleAddToCart(product)}>Add</span>
                </div>
              </div>
            ))}
          </div>
        ))}

        {/* show list of products is special gift */}
        {giftsToChoose?.special_gift?.map((products, i) => (
          <div key={i} className="Cart__gift__products">
            {products?.map((product, j) => (
              <div key={j} className="product">
                <div className="product__img">
                  <img src={product?.image?.src} alt={product.title} />
                </div>

                <div className="product__title title-format">
                  {product?.title?.replace('FREE', '')}
                </div>

                <div className="product__add-to-cart">
                  <span onClick={() => handleAddToCart(product, 'Special Gift')}>Add</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );

  return null;
}

export default memo(GiftProducts);
