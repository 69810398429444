import * as React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import GatsbyImage from '../gatsby-image';
import {
  MasonryCardWrapper,
  PostPreview,
  PostDetails,
  PostDate,
  PostTitle,
  PostTags,
  PostMeta,
  ReadingTime,
  PostCat,
  ReadMore,
  PostCats,
  PostExcerpt,
  PostOverlay
} from './masonry-card.style';
import { IoIosArrowForward } from 'react-icons/io';

interface MasonryCardProps {
  image?: any;
  title: string;
  url: string;
  date?: string;
  tags?: [];
  // cats?: [];
  className?: string;
  imageType?: 'fixed' | 'fluid';
  readTime?: string;
  excerpt?: string;
  secondary_title?: string;
  categoryName?: string;
}

const MasonryCard: React.FunctionComponent<MasonryCardProps> = ({
  image,
  title,
  url,
  date,
  tags,
  // cats,
  excerpt,
  className,
  imageType,
  readTime,
  secondary_title,
  categoryName,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['mesonry_card'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <MasonryCardWrapper className={addAllClasses.join(' ')} {...props}>
      {image == null ? null : (
        <PostPreview className="post_preview">
          <Link to={url}>
            <div className="image">
              <GatsbyImage src={image.gatsbyImageData} alt={image.alt || title} />
            </div>
          </Link>
          <PostOverlay className="overlay d-lg-block d-none">
            <ReadMore className="d-lg-flex d-none">
              <Link to={url}>
                {secondary_title}
                {secondary_title && <br />}
                Read More <IoIosArrowForward />
              </Link>
            </ReadMore>
          </PostOverlay>
          {categoryName && <PostCat>{categoryName}</PostCat>}
        </PostPreview>
      )}

      <PostDetails className="post_details">
        {/* <PostMeta>
          {date && (
            <PostDate
              dangerouslySetInnerHTML={{
                __html: date,
              }}
              className="post_date"
            />
          )}
          {readTime && <ReadingTime>{readTime}</ReadingTime>}
          {cats.length > 0 && (
            <PostCats className="post_tags">
              {cats.map(({ uid, category }, index: number) => {
                if (category?.document?.data?.category_name) {
                  return (
                    <Link key={index} to={`/blog/${uid}`}>
                      {category?.document?.data?.category_name}
                    </Link>
                  )
                }
                return null
              })}
            </PostCats>
          )}
        </PostMeta> */}

        <PostTitle className="post_title">
          <Link to={url}>{title}</Link>
        </PostTitle>

        <PostExcerpt className="d-lg-none">{excerpt}</PostExcerpt>

        <ReadMore className="d-lg-none d-block">
          <Link to={url}>Read More</Link>
        </ReadMore>
      </PostDetails>
    </MasonryCardWrapper>
  );
};

MasonryCard.defaultProps = {
  imageType: 'fluid'
};

export default MasonryCard;
