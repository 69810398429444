import React from "react";

/**
 * template mail contact
 * @param {*} name 
 * @param {*} telephone 
 * @param {*} email 
 * @param {*} order 
 * @param {*} enq_nature
 * @param {*} comment 
 * @returns 
 */
const EmailContactUs = (name, telephone, email, order, comment) => (
    `<table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        width="100%"
        style="max-width: 640px; display: block;"
    >
        <tbody className="revice-mail-body" style="text-align: center; padding: 30px; color: rgb(153, 153, 153); display: block;">
            The below enquiry was submitted at www.activeskin.com.au

            <tr style="padding-bottom: 15px; display: block;">
                <td>
                    Comment: 
                </td>
                 <td>
                    ${comment}
                </td>
            </tr>
            
            <tr style="padding-bottom: 15px; display: block;">
                <td>
                     Name: 
                </td>
                 <td>
                    ${name}
                </td>
            </tr>
            <tr style="padding-bottom: 15px; display: block;">
                <td>
                     E-mail: 
                </td>
                 <td>
                    ${email}
                </td>
            </tr>
            <tr style="padding-bottom: 15px; display: block;">
                <td>
                     Order: 
                </td>
                 <td>
                    ${order}
                </td>
            </tr>
            <tr style="padding-bottom: 15px; display: block;">
                <td>
                     Telephone: 
                </td>
                 <td>
                    ${telephone}
                </td>
            </tr>
        </tbody>
    </table>`
);

export default EmailContactUs;
