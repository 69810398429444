import React, { memo, useState } from 'react';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getModelUrl, validateEmail } from '../../utils/helpers';
import mailContact from './maillContact';

const ContactToOrder = memo(({ product: { handle }, title }) => {
  const [state, setState] = useState(false);
  const [dataForm, setDataForm] = useState({
    name: '',
    phone: '',
    email: '',
    url: getModelUrl('buy', handle),
  });
  const [errors, setErrors] = useState({ name: '', phone: '', email: '' });
  const [response, setResponse] = useState({ status: 500, message: null });

  const handleSendEmail = () => {
    event.preventDefault();
    const { name, phone, url, email } = dataForm;
    const _errors = {};
    let flag = false;

    if (name === '') {
      _errors['name'] = 'Please enter your name';
      flag = true;
    }
    if (phone === '') {
      _errors['phone'] = 'Please enter your phone';
      flag = true;
    }
    if (email === '') {
      _errors['email'] = 'Please enter your email';
      flag = true;
    } else if (!validateEmail(email)) {
      _errors['email'] = 'Invalid email';
      flag = true;
    }
    setErrors(_errors);
    if (flag) return;

    fetch(`/api/contact-to-order`, {
      method: `POST`,
      body: JSON.stringify({
        ...dataForm,
        url: `${location.origin}${url}`,
        revice_mail_body: mailContact(location.origin, name),
      }),
      headers: {
        'content-type': `application/json`,
      },
    })
      .then((result) => result.json())
      .then((res) => {
        setResponse(res);
      })
      .catch((error) => {
        setResponse(error);
      });
  };

  /**
   * handle change data form
   * @param {*} e
   */
  const handleChangeDataForm = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value.trim() });
  };

  return (
    <>
      <button type="button" className="button btn-cart" onClick={() => setState(true)}>
        {title}
      </button>

      <Dialog
        open={state}
        onClose={() => setState(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <div className="body-contact-to-order">
          <h3>{title}</h3>
          <div onClick={() => setState(false)} className="btn-close">
            <CloseIcon />
          </div>
          <p>
            <i>Simply call 1300 784 661 or email clientcare@activeskin.com.au</i>
          </p>
          <p>
            One of our Professional Skin Experts will happily assist you with your product
            selection.
          </p>
          {response.status !== 200 ? (
            <>
              <a href="tel:1300784661">
                <button type="button" className="btn btn-call">
                  Call 1300 784 661
                </button>
              </a>
              <span className="or">Or</span>
              <form onSubmit={handleSendEmail} method="POST" action="/api/contact-to-order">
                <div className="form-group">
                  <input
                    type="text"
                    onChange={(e) => handleChangeDataForm(e)}
                    name="name"
                    placeholder="Name"
                  />
                  {errors.name && (
                    <span className="pt-2 text-left text-danger d-block">{errors.name}</span>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    onChange={(e) => handleChangeDataForm(e)}
                    name="phone"
                    placeholder="Phone"
                  />
                  {errors.phone && (
                    <span className="pt-2 text-left text-danger d-block">{errors.phone}</span>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    onChange={(e) => handleChangeDataForm(e)}
                    name="email"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <span className="pt-2 text-left text-danger d-block">{errors.email}</span>
                  )}
                </div>

                <button className="btn btn-submit" type="submit">
                  Submit
                </button>
                {response.message && (
                  <span className="pt-2 text-center d-block text-danger">{response.message}</span>
                )}
              </form>
            </>
          ) : (
            <button className="btn btn-submit" style={{ textTransform: 'none' }}>
              We've got your contact, will contact you soon!
            </button>
          )}
        </div>
      </Dialog>
    </>
  );
});

export default ContactToOrder;
