import React, { useState, memo, useEffect, createRef } from 'react';
import { Pagination as PaginationWrapper, PaginationItem } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const Pagination = memo(({ currentPage, setCurrentPage, paginate }) => {
  const ref = createRef(null);
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    function myFunction() {
      let offsetTop = ref?.current?.offsetTop - window?.innerHeight || 0;
      if (
        offsetTop > 0 &&
        (document?.body?.scrollTop >= offsetTop ||
          document?.documentElement?.scrollTop >= offsetTop)
      ) {
        if (currentPage < paginate.total) {
          setCurrentPage((_state) => {
            console.log({ _state, currentPage });
            return currentPage + 1;
          });
        }
      }
    }

    window.addEventListener('scroll', myFunction);

    return () => {
      // cleanup
      window.removeEventListener('scroll', myFunction);
    };
  }, [ref]);

  return <div ref={ref} />;

  return (
    <PaginationWrapper
      className="d-none"
      ref={ref}
      hidePrevButton={currentPage === 1}
      hideNextButton={currentPage >= paginate.total}
      page={currentPage}
      count={paginate.total}
      variant="outlined"
      shape="rounded"
      siblingCount={1}
      boundaryCount={0}
      onChange={handleChangePage}
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: KeyboardDoubleArrowLeftIcon, next: KeyboardDoubleArrowRightIcon }}
          {...item}
        />
      )}
    />
  );
});

export default Pagination;
