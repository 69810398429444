import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import arrows from '../../../images/black-arrows.png';

const NavCategoriesWrapper = styled.div`
  margin: 0 auto;
  position: relative;

  .container-xl {
      @media (min-width: 1200px) {
        max-width: 1140px !important;
      }
  }
`
export const NavTitle = styled.div`
    @media (min-width: 992px) {
        display: none;
    }

    @media (max-width: 991.98px) {
        background: url(${arrows}) no-repeat calc(100% - 10px) -30px;
        border: 1px solid ${themeGet('colors.black', '#333')};
        border-bottom: 0;
        padding: 15px;
        text-align: left;
        font-size: 15px;
    }

    &.show {
        @media (max-width: 991.98px) {
            background-position: calc(100% - 10px) 20px;
        }
    }
`;

export const NavList = styled.ul`
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${themeGet('colors.black', '#333')};

    @media (min-width: 992px) {
        
    }

    @media (max-width: 991.98px) {
        border-bottom: 0;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 15px;
        right: 15px;
        background: #fff;
        z-index: 1;
    }

    &.show {
        border-bottom: 1px solid ${themeGet('colors.black', '#333')};
        li {
            display: flex;
            &.active {
                @media (max-width: 991.98px) {
                    background-position: calc(100% - 10px) 20px;
                }
            }
        }
    }
`

export const NavItem = styled.li`
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 992px) {
        justify-content: center;
        border-right: 1px solid ${themeGet('colors.black', '#333')};
        height: 33px;
        display: flex;
    }

    @media (max-width: 991.98px) {
        border-bottom: 1px solid ${themeGet('colors.black', '#333')};
        width: 100% !important;
        text-align: left;
        display: none;
    }

    &:last-child {
        @media (min-width: 992px) {
            border-right: 0;
        }

        @media (max-width: 991.98px) {
            border-bottom: 0;
        }
    }

    a {
        font-size: 15px;
        width: 100%;
        display: block;
        line-height: ${themeGet('lineHeights.normal', '1')};
        /* font-weight: ${themeGet('fontWeights.5', 'bold')}; */
        color: ${themeGet('colors.black', '#333')};
        transition: 0.15s ease-in-out;
        text-decoration: none;

         @media (min-width: 992px) {
            
        }

        @media (max-width: 991.98px) {
            padding: 15px 15px 15px 45px;
        }

        &.active, &.active-link, &:hover {
            text-decoration: none;
        }

        &:hover {
            color: ${themeGet('colors.black', '#333')};
        }
    }
`

export default NavCategoriesWrapper