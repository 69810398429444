import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Seo from '../components/common/Seo';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const StaticPage = loadable(() => import('../components/static_page'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    page: {
      data: { meta_title, meta_description },
    },
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const Page = (props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const { page } = data;

  return (
    <Layout location={location}>
      <StaticPage page={page} />
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: prismicStaticPage(id: { eq: $id }) {
      id
      uid
      url
      type
      data {
        meta_title
        meta_description
        h1_title
        banner_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
          thumbnails {
            mobile {
              alt
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        embed_newsletter_signup
        thead {
          colspan
          rowspan
          th_text {
            richText
          }
        }
        text_before_table {
          richText
        }
        text_after_table {
          richText
        }
        body {
          ... on PrismicStaticPageDataBodyRow {
            items {
              td_text {
                richText
              }
              colspan
              rowspan
            }
          }
        }
        body1 {
          ... on PrismicSliceType {
            slice_type
          }
          ...StaticPageDataBody1TextBlock
          ...StaticPageDataBody1SingleColumnContent
          ...StaticPageDataBody1HtmlBlock
        }
      }
    }
  }
`;
