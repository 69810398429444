import React, { memo, useCallback, useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { formatPrice, getImgCropShopify, getModelUrl } from '../../utils/helpers';
import { encode as base64_encode } from 'base-64';
import { Link } from 'gatsby';
import debounce from 'lodash/debounce';
import { getVariantById } from '../../services/productVariant';

const LineItem = ({ line_item }) => {
  const [state, setState] = useState({
    show: false,
    availableQty: 0,
    inventoryPolicy: -1,
  });
  const [quantity, setQuantity] = useState(0);
  const { updateQuantityInCart, removeLineItemInCart } = useStore();

  const _collections = JSON.parse(
    line_item?.customAttributes?.filter((attr) => attr.key === '_collections')[0]?.value || '[]'
  );

  const isGiftVouchers = _collections?.findIndex((value) => value === 'Gift Vouchers') !== -1;

  useEffect(() => {
    setQuantity(line_item.quantity);
  }, []);

  const debounceDecrementQuantity = useCallback(
    debounce((currentQty) => {
      updateQuantityInCart(line_item, currentQty);
    }, 300),
    []
  );

  function decrementQuantity() {
    const currentQty = quantity - 1;
    if (currentQty > 0) {
      setQuantity(currentQty);
      setState((_state) => ({ ..._state, show: false }));
      debounceDecrementQuantity(currentQty);
    }
  }

  const debounceIncrementQuantity = useCallback(
    debounce((currentQty, isGiftVouchers) => {
      if (isGiftVouchers) {
        updateQuantityInCart(line_item, currentQty);
        return;
      }

      if (state.inventoryPolicy !== -1) {
        if (state.inventoryPolicy === true || currentQty <= state.availableQty) {
          updateQuantityInCart(line_item, currentQty);
        } else {
          !state.show && setState((_state) => ({ ..._state, show: true }));
          setQuantity(currentQty - 1);
        }
        return;
      }

      getVariantById(base64_encode(line_item.variant.id)).then(({ data }) => {
        const { inventoryPolicy, inventoryQuantity } = data?.productVariant;
        const checkInventoryPolicy = inventoryPolicy === 'CONTINUE';

        if (checkInventoryPolicy || currentQty <= (inventoryQuantity || 0)) {
          updateQuantityInCart(line_item, currentQty);
          setState((_state) => ({
            ..._state,
            availableQty: inventoryQuantity || 0,
            inventoryPolicy: checkInventoryPolicy,
          }));
        } else {
          setState({
            availableQty: inventoryQuantity || 0,
            show: true,
            inventoryPolicy: checkInventoryPolicy,
          });
          setQuantity(currentQty - 1);
        }
      });
    }, 300),
    [state]
  );

  function incrementQuantity(isGiftVouchers = false) {
    const currentQty = quantity + 1;
    setQuantity(currentQty);
    debounceIncrementQuantity(currentQty, isGiftVouchers);
  }

  return (
    <tr className="line-item --normal">
      <td className="line-item--image">
        {line_item?.variant?.image ? (
          <img
            src={getImgCropShopify(line_item.variant.image.src, '75x75')}
            alt={`${line_item.title} product shot`}
          />
        ) : null}
      </td>
      <td className="line-item--name">
        <Link to={getModelUrl('buy', line_item.variant?.product?.handle?.replace('buy-', ''))}>
          {line_item.title}
        </Link>
        {line_item?.variant?.title != 'Default Title' && (
          <div className="line-item--sub-title">
            <small>{line_item?.variant?.title}</small>
          </div>
        )}
      </td>
      <td className="line-item--price">
        {Number(line_item?.variant?.compareAtPrice?.amount) > 0 &&
          line_item?.variant?.compareAtPrice?.amount !== line_item?.variant?.price?.amount && (
            <>
              <del>{formatPrice(line_item?.variant?.compareAtPrice?.amount)}</del>
              <br />
            </>
          )}
        {formatPrice(line_item?.variant?.price?.amount)}
      </td>
      <td className="line-item--quantity">
        <div>
          <span className="btn-update" onClick={decrementQuantity}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="icon icon-minus"
              fill="none"
              viewBox="0 0 10 2"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <span className="value">{quantity}</span>
          <span className="btn-update" onClick={() => incrementQuantity(isGiftVouchers)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="icon icon-plus"
              fill="none"
              viewBox="0 0 10 10"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </div>
        {state.show && (
          <span className="text-danger d-block">
            <small>Can only be purchased up to {state.availableQty} products</small>
          </span>
        )}
      </td>
      <td className="line-item--subtotal">
        {formatPrice(quantity * line_item?.variant?.price?.amount)}
      </td>
      <td className="line-item--remove">
        <button onClick={() => removeLineItemInCart(line_item)}>
          X <u>Remove</u>
        </button>
      </td>
    </tr>
  );
};

export default memo(LineItem);
