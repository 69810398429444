import React, { useRef, useState, useEffect } from 'react';

const SortBy = React.memo((props) => {
  const box = useRef(null);
  const { state, setState, customOrderBy = [], hideSortby = [] } = props;

  const orderBy = {
    best_sellers: 'Best Sellers',
    newest: 'Newest',
    // top_rated: 'Top Rated',
    // in_stock: "In Stock",
    name: 'Name',
    price_asc: 'Price: Low to high',
    price_desc: 'Price: High to low',
    position: 'Position',
  };

  hideSortby?.forEach((value) => {
    orderBy[value] && delete orderBy[value];
  });

  const valuesOrderBy =
    customOrderBy.length > 0
      ? [customOrderBy[1], ...Object.values(orderBy)]
      : Object.values(orderBy);
  const keysOrderBy =
    customOrderBy.length > 0 ? [customOrderBy[0], ...Object.keys(orderBy)] : Object.keys(orderBy);

  const [collapsed, setCollapsed] = useState(false);

  const handleChange = (value) => {
    setCollapsed(false);
    setState(value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (box.current && !box.current.contains(event.target)) {
        setCollapsed(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [box]);

  return (
    <div className="sort-by" ref={box}>
      <a
        id="sort-by__selector"
        className="sort-by__selector"
        onClick={() => setCollapsed(!collapsed)}
      >
        {state[1]}
      </a>

      {collapsed && (
        <div id="sort-by__options" className="sort-by__options">
          <div className="content">
            <div className="mobile-title">
              <span>Sort By</span>
              <button onClick={() => setCollapsed(false)} className="close"></button>
            </div>
            <ul>
              {valuesOrderBy.map((v, i) => (
                <li key={i} className={state[0] === keysOrderBy[i] ? 'active' : ''}>
                  <a onClick={() => handleChange([keysOrderBy[i], v])}>{v}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
});

export default SortBy;
