import React, { memo, useRef } from 'react';
import { graphql } from 'gatsby';
import { mapNodesToData } from '../utils/helpers';
import loadable from '@loadable/component';
import '../components/faq/style.scss';
import Seo from '../components/common/Seo';
import { useEffect } from 'react';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const FaqCategoryItem = loadable(() => import('../components/faq/faq_category_item'));

export const Head = ({ location, params, data, pageContext }) => {
  const { prismicFaq, allPrismicFaqCategory } = data;
  const site = prismicFaq.data;
  const faq_categories = mapNodesToData(allPrismicFaqCategory) || [];

  const seo = {
    title: site.meta_title,
    description: site.meta_description,
    keywords: [],
    location,
  };

  return (
    <Seo {...seo}>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: faq_categories.map(({ faq_question_group }) =>
            faq_question_group.map((item) => ({
              '@type': 'Question',
              name: item.faq_question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.faq_answer,
              },
            }))
          ),
        })}
      </script>
    </Seo>
  );
};

const FaqPage = memo((props) => {
  const box = useRef(null);
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  // const { allPrismicFaqCategory, prismicFaq } = data;

  // const faq_categories = mapNodesToData(allPrismicFaqCategory) || [];
  // const site = prismicFaq.data;

  useEffect(() => {
    let checkBox = setInterval(() => {
      if (box?.current) {
        clearInterval(checkBox);

        let script = document.createElement('script');

        script.id = 'lj1fsbxe-load';
        script.src = 'https://help-center.gorgias.help/api/help-centers/loader.js?v=2';
        script.defer = true;
        script.setAttribute('data-gorgias-loader-help-center', true);
        script.setAttribute('data-gorgias-help-center-uid', 'lj1fsbxe');
        console.log(script);

        box?.current?.appendChild(script);
      }
    }, 300);

    return () => {
      let script = document.getElementById('lj1fsbxe-load');
      if (script) script.remove();

      if (window?.gorgiasHelpCenter) window.gorgiasHelpCenter = null;
    };
  }, [box]);

  return (
    <Layout location={location}>
      {/* <div className="faq__container container-xl">
        <h1 className="faq__title">{site.h1_title}</h1>

        <ul className="faq__categories pl-0">
          {faq_categories.map((category, index) => {
            return <FaqCategoryItem category={category} key={index} />;
          })}
        </ul>
      </div> */}
      <div ref={box}>
        <div data-gorgias-help-center-uid="lj1fsbxe" />
      </div>
    </Layout>
  );
});

export default FaqPage;

export const query = graphql`
  query FaqPage {
    prismicFaq {
      url
      data {
        h1_title
        meta_description
        meta_title
      }
    }
    allPrismicFaqCategory {
      nodes {
        data {
          faq_category_name
          faq_question_group {
            faq_answer
            faq_question
          }
        }
      }
    }
  }
`;
