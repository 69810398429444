import React, { useState, useEffect } from 'react';

const FilterCollapse = ({ className = '--link', title, show, children }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!state && show) setState(show);
  }, [show]);

  return (
    <div className={`filter ${className} ${state ? 'show' : ''}`}>
      <dl>
        <dt onClick={() => setState(!state)}>{title}</dt>
        {state && <dd>{children}</dd>}
      </dl>
    </div>
  );
};

export default FilterCollapse;
