import React, { memo } from 'react';
import { Dialog } from '@mui/material';
import loadable from '@loadable/component';

const Video = loadable(() => import('./Video'));

const ImageDialog = (props) => {
  const {
    dialog: { open, image, video },
    handleClose,
    alt,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      {image && <img src={image} alt={alt || 'activeskin'} />}
      {video && <Video video={video} />}
    </Dialog>
  );
};

export default memo(ImageDialog);
