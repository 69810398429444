import React, { memo, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import { getPromotionsExpired } from '../../utils/query';

const Breadcrumb = loadable(() => import('../common/Breadcrumb'));
const SliceZone = loadable(() => import('./ScliceZone'));
const PromotionsFilter = loadable(() => import('./PromotionsFilter'));

const PromotionsContaier = memo((props) => {
  const [query, setQuery] = useQueryParam('filter', withDefault(StringParam, ''));
  const { title_page, body, promotions } = props;

  const promotions_block = body
    .filter((v) => v.slice_type === 'promotions_block' && promotions?.[v?.primary?.category?.uid])
    .map((v) => v.primary);

  const [filter, setFilter] = useState(query);
  const [expired, setExpired] = useState([]);

  const changeFilter = (val) => {
    setQuery((v) => {
      if (v !== val) {
        setFilter(val);
        return val;
      } else {
        setFilter('');
        return '';
      }
    });
  };

  // useEffect(() => {
  //   getPromotionsExpired()
  //     .then((data) => {
  //       console.log('Promotion has Expried: ', data);
  //       setExpired(data);
  //     })
  //     .catch((error) => {
  //       setExpired([]);
  //     });
  // }, []);

  return (
    <div className="container promotions-page">
      <PromotionsFilter filter={filter} setFilter={changeFilter} promotions={promotions_block} />

      {filter === '' ? (
        <Breadcrumb className="border-bottom" current="Promotions" />
      ) : (
        <Breadcrumb
          className="border-bottom"
          links={[{ label: 'Promotions', url: '/promotions', onClick: () => setFilter('') }]}
          current={`${
            promotions_block?.find((v) => v?.category?.uid === filter)?.category?.document?.data
              ?.name
          } Promotions`}
        />
      )}

      <div className="title__page">
        <h2>{title_page}</h2>
      </div>

      <div className="row">
        <div className="col-xl-10 offset-xl-1">
          <SliceZone promotions={promotions} expired={expired} filter={filter} sliceZone={body} />
        </div>
      </div>
    </div>
  );
});

export default PromotionsContaier;
