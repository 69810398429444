import React, { memo, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';

const ContentFromWpBlog = memo(({ slice, index, category_group, ...props }) => {
  const box = useRef(null);

  let cats = category_group
    ?.filter(({ category }) => category?.document?.data?.category_name)
    ?.map(({ category }) => category?.document?.data?.category_name);
  // cats?.unshift('Trending');

  useEffect(() => {
    if (box && box?.current) {
      const div = document.createElement('div');
      div.innerHTML = slice.primary.post_content.replaceAll('loading="lazy"', '');
      box.current.appendChild(div);
    }
  }, [box]);

  useEffect(() => {
    const checkExistQodefBtn = setInterval(() => {
      if (typeof document !== 'undefined') {
        let btns = document.getElementsByClassName('qodef-btn-text');
        if (btns.length > 0) {
          for (let i = 0; i < btns.length; i++) {
            btns[i].innerText = 'Shop This';
          }
          clearInterval(checkExistQodefBtn);
        }
      }
    }, 500);

    const checkExistAuthor = setInterval(() => {
      if (typeof document !== 'undefined') {
        if (document.getElementsByClassName('saboxplugin-wrap').length > 0) {
          document.getElementsByClassName('saboxplugin-wrap')[0].style.display = 'none';
          clearInterval(checkExistAuthor);
        }
      }
    }, 100);
  }, []);

  return (
    <>
      {props[`before__${slice.slice_type}__${index}`]}
      <section className={`${slice.slice_type}`} ref={box}>
        <span className="meta-category">{cats.length > 0 ? cats?.join(', ') : 'Trending'}</span>
      </section>
      {props[`after__${slice.slice_type}__${index}`]}
    </>
  );
});

export default ContentFromWpBlog;

export const query = graphql`
  fragment BlogPostNewDataBodyPostContentFromWpBlog on PrismicBlogPostNewDataBodyPostContentFromWpBlog {
    primary {
      post_content
    }
  }
`;
