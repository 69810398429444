exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alist-js": () => import("./../../../src/pages/alist.js" /* webpackChunkName: "component---src-pages-alist-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-salon-index-js": () => import("./../../../src/pages/salon/index.js" /* webpackChunkName: "component---src-pages-salon-index-js" */),
  "component---src-pages-salon-treatments-js": () => import("./../../../src/pages/salon/treatments.js" /* webpackChunkName: "component---src-pages-salon-treatments-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-best-sellers-js": () => import("./../../../src/templates/best-sellers.js" /* webpackChunkName: "component---src-templates-best-sellers-js" */),
  "component---src-templates-blog-authors-js": () => import("./../../../src/templates/blog/authors.js" /* webpackChunkName: "component---src-templates-blog-authors-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog/categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blog/single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-blog-tags-js": () => import("./../../../src/templates/blog/tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */),
  "component---src-templates-brand-product-js": () => import("./../../../src/templates/brand-product.js" /* webpackChunkName: "component---src-templates-brand-product-js" */),
  "component---src-templates-brand-product-lv-1-js": () => import("./../../../src/templates/brand-product-lv1.js" /* webpackChunkName: "component---src-templates-brand-product-lv-1-js" */),
  "component---src-templates-collection-product-js": () => import("./../../../src/templates/collection-product.js" /* webpackChunkName: "component---src-templates-collection-product-js" */),
  "component---src-templates-collection-product-lv-1-js": () => import("./../../../src/templates/collection-product-lv1.js" /* webpackChunkName: "component---src-templates-collection-product-lv-1-js" */),
  "component---src-templates-new-arrivals-js": () => import("./../../../src/templates/new-arrivals.js" /* webpackChunkName: "component---src-templates-new-arrivals-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-salon-single-treatments-js": () => import("./../../../src/templates/salon/single-treatments.js" /* webpackChunkName: "component---src-templates-salon-single-treatments-js" */),
  "component---src-templates-salon-static-pages-js": () => import("./../../../src/templates/salon/static-pages.js" /* webpackChunkName: "component---src-templates-salon-static-pages-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

