import React, { memo, useEffect, useState } from 'react';
import { Link } from 'gatsby';

const BrandsSiteMap = (props) => {
  const { brands, title } = props;
  const [state, setState] = useState({});
  const [key, setKey] = useState('A');

  useEffect(() => {
    let tabs = {};
    for (let i = 0; i < 26; i++) {
      let key = String.fromCharCode(65 + i);
      let value = brands.filter((brand) => brand.name[0].toUpperCase() === key);
      if (value.length > 0)
        tabs[key] = value.sort((a, b) => {
          const aName = a.name.toUpperCase();
          const bName = b.name.toUpperCase();
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });
    }
    setState(tabs);

    if (location?.hash != '') {
      setKey(location?.hash?.replace('#', ''));
    }
  }, []);

  function renderContent() {
    let index = 0;
    return Object.keys(state).map((v, i) => {
      if (index < 3) index++;
      else index = 1;

      return (
        <div key={i} className={`col-md-4 col-6 column column-${index}`}>
          <ul>
            <li id={v} className="column-title">
              {v}
            </li>
            {state[v].map(({ name, url }, index) => (
              <li key={index}>
                <Link to={`/${url}`}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
      );
    });
  }

  return (
    <section className="brands-directory">
      {/* <div className="brands-directory__header">
        <p>{title}</p>
      </div> */}

      <div className="brands-directory__tab-content">
        <div className="row">
          <div className="col-xl-11">
            <div className="row">{renderContent()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(BrandsSiteMap);
