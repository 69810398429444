import { fetchCheckout } from '../services/shopify-buy';
import { getCookie, loaded, setCookieHasDomain, validateEmail } from './helpers';

export const dmptSetId = () => {
  const id = Math.floor(Math.random() * 10000);
  loaded && localStorage.setItem('dmpt_id', id);
  return id;
};

export const dmptSetEmail = (_email) => {
  if (loaded && validateEmail(_email)) {
    const { email } = dmptGet();
    if (email !== _email) {
      dmptSetId();
      localStorage.setItem('dmpt_email', _email);
    }
  }
};

export const dmptCheck = () => {
  const email = getCookie('dmpt_email') || null;
  if (email) {
    dmptSetEmail(email);
    setCookieHasDomain('dmpt_email', '', -999);
  }
};

export const dmptClear = () => {
  if (loaded) {
    localStorage.removeItem('dmpt_id');
    localStorage.removeItem('dmpt_email');
  }
};

export const dmptGet = () => {
  const dmp_id = (loaded && localStorage.getItem('dmpt_id')) || null;
  const email = (loaded && localStorage.getItem('dmpt_email')) || null;
  const checkoutId = (loaded && localStorage.getItem('dmpt_checkoutId')) || null;
  const cart = (loaded && localStorage.getItem('dmpt_cart')) || null;
  return { dmp_id, email, checkoutId, cart };
};

export const dmptCreate = async (checkout) => {
  let { dmp_id, email, checkoutId, cart } = dmptGet();

  if (checkoutId && checkout.id && checkout.id !== checkoutId) {
    const data = JSON.parse(cart || '{}');
    const _checkout = await fetchCheckout(checkoutId, true);
    if (
      !_checkout.data.checkoutAttributesUpdateV2 &&
      errors.length > 0 &&
      errors[0].message === 'Checkout is already completed.'
    ) {
      data.cartPhase = 'ORDER_COMPLETE';
      await window.dmPt('cartInsight', data);
    }
    localStorage.removeItem('dmpt_checkoutId');
    localStorage.removeItem('dmpt_cart');
  }

  if (!dmp_id) {
    dmp_id = dmptSetId();
  }

  if (email) {
    await window.dmPt('create', process.env.GATSBY_DMPT_CREATE_DM);
    await window.dmPt('identify', email);
    // window.dmPt('track');

    const { id, currencyCode, totalPrice, subtotalPrice, totalTax, lineItems } = checkout;

    const data = {
      programID: process.env.GATSBY_DMPT_PROGRAM_ID,
      cartDelay: 30,
      cartID: Number(dmp_id),
      cartPhase: 'CUSTOMER_LOGIN',
      currency: currencyCode,
      subtotal: Number.parseFloat(subtotalPrice),
      discountAmount: 0,
      taxAmount: Number.parseFloat(totalTax),
      grandTotal: Number.parseFloat(totalPrice),
      cartUrl: `${process.env.GATSBY_URL}#cart`,
      lineItems: lineItems.map(
        ({
          title,
          variant: { sku, product, price, compareAtPrice, image },
          quantity,
          customAttributes,
        }) => ({
          sku,
          name: title,
          description: '',
          category: JSON.parse(
            customAttributes.find((v) => v.key === '_collections')?.value || '[]'
          ).join('> '),
          other: {},
          quantity,
          unitPrice: Number.parseFloat(price || 0),
          salePrice: Number.parseFloat(compareAtPrice || 0),
          totalPrice: Number.parseFloat(price * quantity),
          imageUrl: image?.src,
          productUrl: `${process.env.GATSBY_URL}buy/${product.handle}`,
        })
      ),
    };

    localStorage.setItem('dmpt_checkoutId', id);
    localStorage.setItem('dmpt_cart', JSON.stringify(data));
    loaded && window.dmPt && window.dmPt('cartInsight', data);
  } else {
    console.log('dmpt: not found email, please login.');
  }
};
