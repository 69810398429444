import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { getModelUrl } from '../../../utils/helpers';
import { Link } from 'gatsby';
import loadable from "@loadable/component";

const ReactSlick = loadable(() => import('./../../../components/common/ReactSlick'))

const LatestPosts = React.memo(({ posts }) => {

    const settings = {
        arrows: false,
        dots: false,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    return (
        <div className="post__latest-posts">
            <div className="post__latest-posts__title -title">
                <a href="https://www.activeskin.com.au/blog/latest/">
                    <h2>Latest Blog Posts</h2>
                </a>
            </div>
            <ReactSlick
                className="owl-theme post__latest-posts__owl-carousel"
                {...settings}
            >

                {
                    posts.map(({ data, uid }, i) => (
                        <article className="post" key={i}>
                            <div className="post__image">
                                <Link to={getModelUrl('blog', uid)} >
                                    <GatsbyImage image={data?.featured_image?.gatsbyImageData} alt={data?.featured_image?.alt || data.post_name} />
                                </Link>

                                <div className='overlay d-block d-lg-none' />
                                {
                                    (data.category_group.length > 0 && data.category_group[0].category?.document?.data?.category_name) ?
                                        <div className="post__category">
                                            <span>{data.category_group[0].category?.document?.data?.category_name}</span>
                                        </div> : <div className="post__category">
                                            <span>Trending</span>
                                        </div>
                                }
                            </div>
                            <div className="post__title">
                                <Link to={getModelUrl('blog', uid)} >
                                    <h3>{data.post_name}</h3>
                                </Link>
                            </div>
                        </article>
                    ))
                }

            </ReactSlick>
        </div>
    )
})

export default LatestPosts