import loadable from '@loadable/component';
import React, { memo, useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { getAllRules } from '../../utils/query';

const LineItem = loadable(() => import('./LineItem'));
const LineItemFeeGift = loadable(() => import('./LineItemFeeGift'));
const FooterPage = loadable(() => import('./FooterPage'));

const MainPage = () => {
  const { checkout, setState } = useStore();
  const [savePrice, setSavePrice] = useState(0);

  useEffect(() => {
    setState((_state) => ({ ..._state, isCartOpen: false }));
    getAllRules().then((data) => {
      setState((_state) => ({ ..._state, ...data }));
    });
  }, []);

  function checkIsFreeGift(item) {
    return (
      item?.customAttributes.findIndex(
        ({ key, value }) =>
          (key == '_isGift' && value == '1') || (key == '_isSpecialGift' && value == '1')
      ) !== -1
    );
  }

  function checkIsFreeSamples(item) {
    return (
      item?.customAttributes.findIndex(
        ({ key, value }) => key == '_isFreeSamples' && value == '1'
      ) !== -1
    );
  }

  const products = checkout.lineItems?.filter((product) => !checkIsFreeGift(product));
  const allProductsFreeGift = checkout.lineItems?.filter((product) => checkIsFreeGift(product));
  const productsFreeSamples = allProductsFreeGift.filter((product) => checkIsFreeSamples(product));
  const productsFreeGift = allProductsFreeGift.filter((product) => !checkIsFreeSamples(product));

  useEffect(() => {
    if (products?.length > 0) {
      const savePrice = products
        ?.filter((product) => parseFloat(product?.variant?.compareAtPrice) > 0)
        ?.map(
          (product) =>
            (parseFloat(product?.variant?.compareAtPrice) - parseFloat(product?.variant?.price)) *
            product?.quantity
        )
        ?.reduce((a, b) => a + b, 0);
      setSavePrice(savePrice);
    }
  }, [products]);

  return (
    <>
      <table width="100%">
        {allProductsFreeGift.length > 0 && (
          <style>
            {`
            tr.line-item.--normal:nth-child(${products.length}) td {
                padding-bottom: 40px;
            }
            tr.line-item.--free-gift:nth-child(${products.length + 1}) {
                border-top: 1px solid rgba(0,0,0,.1);
            }
            @media (max-width: 767.98px) {
                tr.line-item.--free-gift:nth-child(${products.length + 1}) {
                    padding-top: 30px;
                }
            }
        `}
          </style>
        )}
        <thead>
          <tr>
            <th colSpan={2}>Product</th>
            <th>Unit Price</th>
            <th className="d-none d-lg-table-cell">Quantity</th>
            <th className="d-none d-lg-table-cell">Subtotal</th>
          </tr>
        </thead>

        <tbody>
          {products?.map((line_item) => {
            return <LineItem line_item={line_item} />;
          })}

          {productsFreeGift?.map((line_item) => {
            return <LineItemFeeGift line_item={line_item} />;
          })}

          {productsFreeSamples?.map((line_item) => {
            return <LineItemFeeGift line_item={line_item} />;
          })}
        </tbody>
      </table>
      <FooterPage savePrice={savePrice} />
    </>
  );
};

export default memo(MainPage);
