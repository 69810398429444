import React from 'react';

const CartIcon = ({ white = false }) => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2148 2148">
    <defs>
      <style>{white && `.cls-1{fill:#fff;}`}</style>
    </defs>
    <path
      class={white ? 'cls-1' : ''}
      d="M750.64,762.47c1.57-35.53,1.23-69,4.81-102,17.45-160.57,156-283.57,318.25-283.71,163.48-.15,301.48,122.48,319.28,284.88,3.56,32.42,3.16,65.27,4.72,100.85h22q109.93,0,219.86,0c41.23,0,56.3,14.43,56.32,54.77.12,235.25.55,470.51-.14,705.76-.42,143.52-105.67,247.78-249.44,248q-371.65.56-743.31,0c-142.64-.18-248.41-100.44-249.81-242.92-2.34-238.72-.81-477.48-.57-716.22,0-33.77,16.61-49.25,51.1-49.34C585.35,762.32,667,762.47,750.64,762.47ZM538.12,849.88V872.2q0,323.49,0,647c0,99.41,65.66,165.21,165.1,165.27q370.53.19,741.08,0c100.85,0,165.85-65.4,165.88-166.6q.12-322.45,0-644.91v-22.6H1396.13c0,9.75,0,18,0,26.26-.06,26.52.78,53.09-.46,79.55-1.18,25.36-19.7,42.31-43.21,42.08-22.65-.23-39.76-16.5-42.32-40.94-.86-8.29-.42-16.73-.43-25.11,0-27,0-54,0-81.71H838.59c0,35,.72,68.47-.2,101.86-.87,31.61-23.81,50.56-52.13,44.76-20.74-4.24-33.59-21.2-33.91-46.43-.42-33.27-.1-66.56-.1-100.81Zm302.39-88.74h468.83c0-25.59,1.33-50-.24-74.14-5.9-91.13-49.62-159.6-131.14-199.57-82.24-40.32-164.44-33.84-239.78,19.12C849.93,568.59,829.88,658.69,840.51,761.14Z"
    />
  </svg>
);

export default CartIcon;
