import React, { useRef } from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import { getModelUrl } from '../../utils/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ReactSlick = loadable(() => import('../common/ReactSlick'));
const ProductPriceLive = loadable(() => import('../common/ProductPriceLive'));

const RelatedProductsSeller = ({ products }) => {
  const box = useRef(null);

  const settings = {
    dots: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          centerMode: true,
        },
      },
    ],
  };

  function handleSlickGoTo(next = true) {
    if (box.current) {
      next ? box.current.slickNext() : box.current.slickPrev();
    }
  }

  // if (box?.current) box.current.slickGoTo(0);

  return (
    <div className="container-xl">
      <div className="row">
        <div className="col-12">
          <div className="main__product-view__related">
            <div className="main__product-view__related__title -title --arrows">
              <h2 className="mb-0">you may also like</h2>
              <div className="arrows">
                <span className="arrows__btn --left" onClick={() => handleSlickGoTo(false)}></span>
                <span className="arrows__btn --right" onClick={() => handleSlickGoTo()}></span>
              </div>
            </div>
            {products?.length > 0 && (
              <div className="main__product-view__related__products">
                <ReactSlick ref={box} {...settings}>
                  {products
                    ?.filter((v) => {
                      return v?.tags?.findIndex((v) => v === 'FreeGift') === -1;
                    })
                    ?.map((v, i) => {
                      return (
                        <div key={i} className="product text-center w-100">
                          <Link
                            className="product--image --overlay"
                            to={getModelUrl('buy', v?.handle)}
                            title={v.title}
                          >
                            <GatsbyImage
                              image={getImage(v.featuredImage)}
                              alt={v.featuredImage?.altText || v?.title}
                            />
                          </Link>
                          <p className="product--brand font-weight-bold">
                            <Link to={getModelUrl('brands', v?.vendor, true)}>{v.vendor}</Link>
                          </p>
                          <p className="product--name m-auto">
                            <Link to={getModelUrl('buy', v?.handle)}>
                              {v.title.replace(v.vendor || '', '')}
                            </Link>
                          </p>
                          <ProductPriceLive product={v} />
                        </div>
                      );
                    })}
                </ReactSlick>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedProductsSeller;
