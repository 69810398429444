import React from 'react';

const AccountIcon = ({ white = false, ...props }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2148 2148"
    {...props}
  >
    <defs>
      <style>{white && `.cls-1{fill:#fff;}`}</style>
    </defs>
    <path
      class={white ? 'cls-1' : ''}
      d="M893.59,1048.27c-178.47-128.93-173.3-365.4-41.42-492.4,129-124.21,334.53-118.74,455.51,12,121.72,131.53,118.21,354.07-54.18,480.37,6.91,2.65,12.75,4.91,18.61,7.14,240.84,91.43,382.45,263.79,422.33,518.77,3,18.94,3.91,38.32,4.26,57.52.56,30.23-17,48.23-47.23,50-5.9.34-11.84.15-17.76.15q-559.29,0-1118.57,0c-54.77,0-70.39-16.67-64.78-71.42,28.36-277,174.4-462.27,435.38-557.73C888.43,1051.65,890.82,1049.83,893.59,1048.27Zm709.32,541.67c-15.79-224.52-220.79-476.66-530-475.91-309.6.76-512.94,254.72-527.44,475.91Zm-528-579.12A226.44,226.44,0,0,0,1301,783.12c-.42-125.31-102.48-226.86-227.63-226.51-125,.36-226.39,102.63-226.09,228S949.45,1011.36,1074.88,1010.82Z"
    />
  </svg>
);

export default AccountIcon;
