import React from 'react'
import { graphql } from 'gatsby';

const Embed = React.memo(({ slice, ...props }) => {

    const { embed } = slice.primary

    return (
        <div className="salon-static-page__embed text-center mb-5" dangerouslySetInnerHTML={{ __html: embed }} />
    )
})

export default Embed

export const query = graphql`
  fragment SalonStaticPagesDataBodyEmbedBookNow on PrismicSalonStaticPagesDataBodyEmbedBookNow {
    primary {
      embed
    }
  }
`