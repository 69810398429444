import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/salon/Layout'));
const SliceZone = loadable(() =>
  import('./../../containers/salon/single-treatments-pages/SliceZone')
);
const Banner = loadable(() => import('../../containers/salon/single-treatments-pages/Banner'));
const Breadcrumb = loadable(() =>
  import('../../containers/salon/single-treatments-pages/Breadcrumb')
);
// const Author = loadable(() => import('./../../containers/salon/single-treatments-pages/Author'))

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicSalonTreatmentPages: {
      data: { meta_title, meta_description, avatar },
    },
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    image: getSrc(avatar),
    location,
  };

  return <Seo {...seo} />;
};

const SingleTreatments = memo((props) => {
  const { data, errors } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const {
    prismicSalonTreatmentPages: {
      uid,
      data: { body, title },
    },
  } = data;

  return (
    <Layout>
      <div className={`single-treatments single single-treatments__${uid}`}>
        <div className="container-xl">
          <Breadcrumb label={title} />
        </div>

        <Banner title={title} />

        <div className="container-xl">
          <div className="row">
            <div className="col-lg-12">
              <SliceZone sliceZone={body} />
            </div>
            {/* <div className="col-lg-3 col-md-4">
              <Author />
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
});

export default SingleTreatments;

export const query = graphql`
  query singleTreatmentsPages($uid: String!) {
    prismicSalonTreatmentPages(uid: { eq: $uid }) {
      uid
      url
      data {
        title
        meta_title
        meta_description
        avatar {
          gatsbyImageData
          alt
        }
        banner {
          gatsbyImageData
          alt
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }

          ...SalonTreatmentPagesDataBodyTextBlock
          ...SalonTreatmentPagesDataBodyContentFromWp
        }
      }
    }
  }
`;
