import React, { memo } from 'react';

const ClickCollect = memo(({ metafield }) => {
  let purchase_method = metafield.filter((v) => v?.key === 'purchase_method')?.[0]?.value;

  if (purchase_method === 'Click & Collect Only')
    return (
      <div className="click-and-collect-only">
        Click & Collect ONLY{' '}
        <span>
          Why? What is <a href="">Click & Collect?</a>
        </span>
      </div>
    );

  return null;
});

export default ClickCollect;
