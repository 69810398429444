import { CircularProgress } from '@mui/material';
import React, { memo, useState } from 'react';
import { customerCreate } from '../../services/customer';

const Register = (props) => {
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({ email: '', password: '', firstName: '', lastName: '' });
  const [message, setMessage] = useState({ success: '', errors: [], error: '' });

  const handleRegister = async () => {
    event.preventDefault();
    if (!submit) {
      setSubmit(true);
      const { data, errors } = await customerCreate(form);
      console.log({ customerUserErrors: data?.customerCreate?.customerUserErrors, errors });
      if (errors && errors?.length > 0) {
        setMessage({ error: errors[0]?.message || '' });
      } else if (data?.customerCreate?.customerUserErrors?.length > 0) {
        setMessage({ errors: data?.customerCreate?.customerUserErrors });
      } else if (data?.customerCreate?.customer) {
        setMessage({ success: 'Your account has been successfully created, please login here.' });
      }
      setSubmit(false);
    }
  };

  const setValueField = (e) => {
    setForm((_form) => ({ ..._form, [e.target.name]: e.target.value }));
  };

  const messageError = (field) => {
    if (message?.errors?.length > 0) {
      const error = message.errors.find(
        (error) => error?.field?.length >= 2 && error.field[1] === field
      );
      if (error) return <span className="d-block text-danger">{error?.message}</span>;
    }
    return;
  };

  return (
    <div className="customer --register">
      <h4 className="customer--title">CREATE AN ACCOUNT</h4>
      <div className="customer__inner">
        <form onSubmit={handleRegister} className="customer__form">
          <div className="form-group">
            <label>
              First name <span className="text-danger">*</span>
            </label>
            <input
              onChange={setValueField}
              name="firstName"
              className="form-control"
              type="text"
              required
              readOnly={submit}
            />
            {messageError('firstName')}
          </div>
          <div className="form-group">
            <label>
              Last name <span className="text-danger">*</span>
            </label>
            <input
              onChange={setValueField}
              name="lastName"
              className="form-control"
              type="text"
              required
              readOnly={submit}
            />
            {messageError('lastName')}
          </div>
          <div className="form-group">
            <label>
              Email Address <span className="text-danger">*</span>
            </label>
            <input
              onChange={setValueField}
              name="email"
              className="form-control"
              type="email"
              required
              readOnly={submit}
            />
            {messageError('email')}
          </div>
          <div className="form-group">
            <label>
              Password <span className="text-danger">*</span>
            </label>
            <input
              onChange={setValueField}
              name="password"
              className="form-control"
              type="password"
              required
              readOnly={submit}
            />
            {messageError('password')}
          </div>
          <div className="customer__btn-submit d-flex align-items-center mb-0">
            <button disabled={submit} className="btn-activeskin primary" type="submit">
              Continue
            </button>
            {submit && <CircularProgress className="ml-2" size={20} />}
          </div>
          {message?.error && <span className="d-block text-danger">{message?.error}</span>}
          {message?.success && (
            <span className="d-block text-success">
              <a className="text-success" href="#login" style={{ textDecoration: 'underline' }}>
                {message?.success}.
              </a>
            </span>
          )}
          <div className="customer__text-more" style={{ marginTop: '15px' }}>
            <p>
              Already have an account? <a href="#login">Login</a>
            </p>
            <p>
              By signing up, I agree to the <a href="#">terms & conditions</a> and to receive
              promotional emails from Activeskin
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(Register);
