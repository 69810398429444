import { RichText } from 'prismic-reactjs';
import React from 'react';
import { memo } from 'react';
import { serializeHyperlink } from '../../utils/helpers';

const Table = memo(({ thead, body, text_before_table, text_after_table }) => {
  if (thead?.length > 0 || body?.length > 0) {
    return (
      <div className="page__content page__table container">
        <RichText render={text_before_table.richText} serializeHyperlink={serializeHyperlink} />

        <table border={1} cellPadding={5} cellSpacing={0}>
          <thead>
            <tr>
              {thead.map((v, i) => (
                <th key={i} colSpan={v?.colspan || 1} rowSpan={v?.rowspan || 1}>
                  <RichText render={v.th_text.richText} serializeHyperlink={serializeHyperlink} />
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {body.map(({ items }, index) => (
              <tr key={index}>
                {items.map((item, i) => (
                  <td key={i} colSpan={item?.colspan || 1} rowSpan={item?.rowspan || 1}>
                    <RichText
                      render={item.td_text.richText}
                      serializeHyperlink={serializeHyperlink}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <RichText render={text_after_table.richText} serializeHyperlink={serializeHyperlink} />
      </div>
    );
  }

  return null;
});

export default Table;
