import React from 'react';

const SearchIcon = ({ white = false, ...props }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2148 2148"
    {...props}
  >
    <defs>
      <style>{white && `.cls-1{fill:#fff;}`}</style>
    </defs>
    <path
      class={white ? 'cls-1' : ''}
      d="M1389.37,1459c-256.28,205.7-605.35,159.14-801.63-70.68C395.9,1163.74,408,827.81,616.36,616.07c208.14-211.44,543-228.46,771.58-39.84,238.5,196.82,286.87,562.14,66.16,820.66,22.23,21.45,45,42.89,67.17,64.91q75,74.39,149.42,149.27c6.34,6.35,13,12.66,18,20,12.23,17.62,9.52,43-5.32,57.87-16.36,16.41-40.5,18.45-59.31,4.21-7.71-5.83-14.41-13.06-21.28-19.94C1532,1602.18,1461.2,1531.12,1389.37,1459Zm-366.84,39.3c263,2,480.42-213.92,482.15-478.75,1.71-263.94-213.07-480.46-478.23-482.1-263.87-1.63-480.69,211.66-482.66,474.82C541.79,1280,754.84,1496.26,1022.53,1498.33Z"
    />
  </svg>
);

export default SearchIcon;
