import * as React from 'react'
import loadable from "@loadable/component";

const GroupOfLinks = loadable(() => import('./GroupOfLinks'))
const HighlightedGroupOfLinks = loadable(() => import('./HighlightedGroupOfLinks'))

export const SliceZone = React.memo(({ sliceZone }) => {
  const sliceComponents = {
    group_of_links: GroupOfLinks,
    group_of_links_1: HighlightedGroupOfLinks,
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })

  return <>{sliceZoneContent}</>
})