import React from 'react';
import BrandProduct from './BrandProduct';
import { useState } from 'react';
import { requestProductPassword } from '../../services/productVariant';
import { getModelUrl } from '../../utils/helpers';
import Recaptcha from 'react-recaptcha';

function SendMail(props) {
  const { brand, product } = props;

  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
    history: '',
    url: '',
    success: false,
    recapcha: true,
  });
  const [error, setErrors] = useState('');
  const [history, setHistory] = useState({ key: '', value: '' });

  const handleSubmit = async () => {
    event.preventDefault();
    if (!form.success) {
      let flag = false;
      if (!form.recapcha) {
        setErrors('ReCAPTCHA is a required field');
        flag = true;
      }
      if (flag) return;

      setForm((_form) => ({ ..._form, success: true }));
      const { message, status } = await requestProductPassword({
        ...form,
        url: `${location.origin}${getModelUrl('buy', product.handle)}`,
        history: history.value,
      });
    }
  };

  const setValueField = (e) => {
    setForm((_form) => ({ ..._form, [e.target.name]: e.target.value }));
  };

  const verifyCallback = function (response) {
    let recapcha = false;
    if (response) {
      recapcha = true;
      setErrors('');
    }
    setForm((_form) => ({ ..._form, recapcha }));
  };

  const expiredCallback = function () {
    setForm((_form) => ({ ..._form, recapcha: false }));
  };

  const callback = function () {
    console.log('Loaded recapcha');
    setForm((_form) => ({ ..._form, recapcha: false }));
  };

  return (
    <div className="product-send-mail">
      <div className="row">
        <div className="offset-xl-4 col-xl-4">
          <h4 className="text-center mb-5">{brand?.data?.name}</h4>
          <p className="mb-5">
            For help with a password and / or skin advice, please submit your details below and one
            of our Skin Experts will promptly contact you.
          </p>

          <form onSubmit={handleSubmit} className="product-send-mail__form">
            <div className="form-group">
              <input
                onChange={setValueField}
                name="name"
                className="form-control"
                type="text"
                placeholder="Name"
                required
                readOnly={form.success}
              />
            </div>
            <div className="form-group">
              <input
                onChange={setValueField}
                name="phone"
                className="form-control"
                type="text"
                placeholder="Phone"
                required
                readOnly={form.success}
              />
            </div>
            <div className="form-group">
              <input
                onChange={setValueField}
                name="email"
                className="form-control"
                type="email"
                placeholder="Email"
                required
                readOnly={form.success}
              />
            </div>

            <div>
              <label className="mb-3">
                Have you purchased {brand?.data?.name} previously from Activeskin?
              </label>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="defaultCheck1"
                  name="history"
                  onChange={() => setHistory({ key: 1, value: 'Yes, I am an existing customer' })}
                  checked={history.key === 1}
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Yes, I am an existing customer
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="history"
                  onChange={() =>
                    setHistory({ key: 2, value: "Yes, however I'd like some advice" })
                  }
                  id="defaultCheck2"
                  checked={history.key === 2}
                />
                <label className="form-check-label" htmlFor="defaultCheck2">
                  Yes, however I'd like some advice
                </label>
              </div>
              <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="history"
                  onChange={() => setHistory({ key: 3, value: 'No, I need advice' })}
                  id="defaultCheck3"
                  checked={history.key === 3}
                />
                <label className="form-check-label" htmlFor="defaultCheck3">
                  No, I need advice
                </label>
              </div>
            </div>

            {form?.success ? (
              <span className="d-block text-center text-success">
                Thank you! <br /> Your form has been submitted and one of our Skin Experts will get
                back to you soon.
              </span>
            ) : (
              <>
                <div className='form-group mb-4'>
                  <Recaptcha
                    className="recapcha"
                    sitekey="6LeidyYpAAAAAK06IzQlqSYhdZYYRMp7kG---_pA"
                    verifyCallback={verifyCallback}
                    expiredCallback={expiredCallback}
                    onloadCallback={callback}
                  />
                  {error && <small className="text-danger">{error}</small>}
                </div>
                <div className="product-send-mail__btn-submit">
                  <button
                    className="btn-activeskin primary d-flex align-items-center w-100 justify-content-center"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </form>

          <div className="product-password__brand">
            <BrandProduct brand={brand} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendMail;
