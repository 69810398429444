import React from 'react';

const PlusIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="plusIconTitle"
    stroke="#000000"
    stroke-width="2"
    stroke-linecap="square"
    stroke-linejoin="miter"
    fill="none"
    color="#000000"
    {...props}
  >
    <title id="plusIconTitle">Plus</title> <path d="M20 12L4 12M12 4L12 20" />
  </svg>
);

export default PlusIcon;
