import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from "@loadable/component";

const CustomLink = loadable(() => import('../../components/common/CustomLink'))

const TrandingBrands = React.memo((props) => {

    return (
        <div className="main__trending-brands container-xl">
            <div className="main__trending-brands__title -title">
                <h2>{props.title}</h2>
            </div>
            <div className="main__trending-brands__list row">
                {
                    props.data.map((v, i) => (
                        <div key={i} className="col-lg-2 col-sm-4 col-6">
                            <CustomLink
                                link={v.brand_link}
                                text={<GatsbyImage image={v.brand_logo.gatsbyImageData} alt={v.brand_logo.alt || "activeskin"} />}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )

})

export default TrandingBrands