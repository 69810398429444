import React, { memo } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const FeaturedBrands = (props) => {

    const { brands, title } = props

    return (
        <section className="brands-featured">
            <div className="brands-featured__header">
                <h3>{title}</h3>
            </div>

            <div className="brands-featured__body">
                <div className="row" style={{ margin: "0 -7.5px" }}>
                    {brands.map(({ featured_brand: { document: { url } }, featured_brand_image: { gatsbyImageData, alt } }, i) => (
                        <div className="col-md-2 col-6" key={i} style={{ padding: "0 7.5px 15px" }}>
                            <Link to={url}>
                                <GatsbyImage image={gatsbyImageData} alt={alt} />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )

}

export default memo(FeaturedBrands)