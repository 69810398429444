import { useStaticQuery, graphql } from 'gatsby';

export const useDataCommon = () => {
  const { dataFilter, systemConfig, siteConfig } = useStaticQuery(query);
  return { dataFilter, systemConfig, siteConfig };
};

export default useDataCommon;

const query = graphql`
  query {
    siteConfig: prismicSiteConfiguration {
      data {
        products_per_page
      }
    }

    systemConfig: prismicSystemConfiguration {
      data {
        version
      }
    }

    dataFilter: prismicFilter {
      data {
        filter_group {
          metafield_key
          filter_title
        }
        price_filter_group {
          price_range
        }
        colour_filter_group {
          colour_name
          colour_code
        }
      }
    }
  }
`;
