import React, { memo, useState, useEffect } from 'react';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { StaticImage } from 'gatsby-plugin-image';
import { callApiWishlist, checkProductExistInList } from '../../utils/wishlist';
import { useStore } from '../../hooks';

const AddToWishlist = memo(({ product, variant }) => {
  const { swymLoading } = useStore();
  const [addedToWishlist, setAddedToWishlist] = useState(false);

  useEffect(() => {
    if (!swymLoading) {
      checkProductExistInList(product, variant)
        .then(check => setAddedToWishlist(check))
        .catch(() => setAddedToWishlist(false));
    }
  }, [swymLoading]);

  const addToWishlist = async () => {
    setAddedToWishlist(true);
    await callApiWishlist('add', product, variant);
  };

  const removeFromWishlist = async () => {
    setAddedToWishlist(false);
    await callApiWishlist('remove', product, variant);
  };

  return (
    <div className={`wishlist-icon ${addedToWishlist ? 'added' : ''}`}>
      <span
        className={addedToWishlist ? 'text-info' : ''}
        onClick={e => {
          if (!addedToWishlist) {
            addToWishlist();
          } else {
            removeFromWishlist();
          }
        }}
      >
        {!addedToWishlist ? (
          <StaticImage src="../../images/heart-icon.png" alt="wishlist" />
        ) : (
          <FavoriteOutlinedIcon />
        )}
      </span>
    </div>
  );
});

export default AddToWishlist;
