exports.titleCombination = (title, brand = false) => {
  const titleArr = title?.split('/') || [];
  const results = [];

  for (let i = 0; i < titleArr.length; i++) {
    const element = titleArr[i];
    const result = [];

    for (let j = 0; j < 3; j++) {
      switch (j) {
        case 1:
          result.push(
            element.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          );
          break;
        case 2:
          result.push(element.toUpperCase());
          break;

        default:
          result.push(element);
          break;
      }
    }

    results.push(result.filter((v, i) => result.findIndex((_v) => _v === v) === i));
  }

  if (titleArr?.length <= 1) {
    if (brand) return results[0].map((v) => `Brands/${v}`);
    return results[0];
  }

  function Combination(a, b, brands = '') {
    const result = [];
    for (let i = 0; i < a.length; i++) {
      const _a = a[i];

      for (let j = 0; j < b.length; j++) {
        const _b = b[j];
        result.push(`${brands}${_a}/${_b}`);
      }
    }
    return result;
  }
  let _results = results[0];

  for (let i = 1; i < results.length; i++) {
    if (brand && i === 1) _results = Combination(_results, results[i], 'Brands/');
    else _results = Combination(_results, results[i]);
  }

  return _results;
};
