import React, { useRef, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import CartEmpty from './CartEmpty';
import { useStore } from './../../hooks';
import { formatPrice, setCookieHasDomain } from '../../utils/helpers';
import { Link } from 'gatsby';
import { CircularProgress } from '@mui/material';
import { getAllRules } from '../../utils/query';
import { dmptCreate } from '../../utils/dmpt';
import { useLocation } from '@reach/router';

const GiftProducts = loadable(() => import('./GiftProducts'));
// const RelatedProducts = loadable(() => import('./RelatedProducts'));
const LineItems = loadable(() => import('./LineItems'));
const BestSellers = loadable(() => import('./BestSellers'));

function Cart() {
  const location = useLocation();
  const { isCartOpen, checkout, setState, getRules } = useStore();

  const cartOverlay = useRef(null);
  const [savePrice, setSavePrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const cartCount = (checkout?.lineItems?.map((v) => v.quantity) || []).reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (location.hash === '#cart') setState((state) => ({ ...state, isCartOpen: true }));
  }, []);

  useEffect(() => {
    const products = checkout?.lineItems?.map((v) => ({
      quantity: v.quantity,
      id: v?.variant?.id?.replace('gid://shopify/ProductVariant/', ''),
    }));
    setCookieHasDomain('number_on_cart', `${cartCount}`);
    setCookieHasDomain('gatsby_cart_products', JSON.stringify(products));

    if (cartCount > 0) dmptCreate(checkout);
  }, [cartCount]);

  useEffect(() => {
    setCookieHasDomain('gatsby_cart_saving_price', `${savePrice}`);
  }, [savePrice]);

  const openCheckout = async () => {
    setLoading(true);
    await getRules();
    setLoading(false);

    window.location.href = checkout.webUrl;
  };

  const handleCartClose = () => {
    setState((s) => ({ ...s, isCartOpen: false }));
    getAllRules().then((data) => {
      setState((_state) => ({ ..._state, ...data }));
    });
  };

  useEffect(() => {
    if (isCartOpen) {
      document.getElementsByTagName('body')[0].classList.add('modal-open');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }
  }, [isCartOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (cartOverlay.current && cartOverlay.current == event.target) {
        handleCartClose();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cartOverlay]);

  return (
    <div className={`Cart ${isCartOpen ? 'Cart--open' : ''}`} ref={cartOverlay}>
      {isCartOpen && (
        <div className="Cart-wrapper">
          {checkout?.lineItems?.length <= 0 ? (
            <CartEmpty handleCartClose={handleCartClose} />
          ) : (
            <>
              <header className="Cart__header">
                <div className="Cart__header__left">
                  <span onClick={handleCartClose} className="Cart__close">
                    ×
                  </span>
                  <h3 className="mb-0">Shopping Bag</h3>
                </div>

                <div className="Cart__header__right">
                  <span className="Cart__total">({cartCount || 0}) Items</span>
                </div>
              </header>

              <div className="Cart__content hide-scrollbar">
                <GiftProducts />

                <ul className="Cart__line-items">
                  <LineItems setSavePrice={setSavePrice} />
                </ul>

                <BestSellers />
              </div>

              <footer className="Cart__footer">
                <div className="Cart__footer__total total">
                  <span className="total__text">
                    Subtotal:{' '}
                    <span className="total__price">
                      {formatPrice(checkout?.totalPrice?.amount)}
                    </span>
                  </span>
                  {savePrice > 0 && (
                    <span className="rrp__text">
                      Your savings off RRP are{' '}
                      <span className="rrp__price">{formatPrice(savePrice)}</span>
                    </span>
                  )}
                </div>

                <div className="Cart__footer__checkout checkout">
                  <Link to="/cart" className="btn btn-view-cart">
                    View Cart
                  </Link>

                  <button className="btn btn-checkout" onClick={openCheckout}>
                    Checkout {loading && <CircularProgress className="ml-1" size={13} />}
                  </button>
                </div>
              </footer>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Cart;
