import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BlogTreatmentsWrapper = styled.div`
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  
  .pagination {
    margin-top: 80px;
    @media (max-width: 1024px) {
      margin-top: 60px;
    }
    @media (max-width: 575px) {
      margin-top: 30px;
    }
  }
`

export const SecTitle = styled.div`
  color: ${themeGet("colors.textColor", "#333")};
  font-size: 20px;
  font-weight: bold;
  /* font-family: ${themeGet("fontFamily.0", "'Fira Sans',sans-serif")}; */
  letter-spacing: 0.17em;
  position: relative;
  margin-bottom: 30px;
  text-transform: uppercase;

  /* &:after {
    content: "";
    width: 68px;
    height: 1px;
    background: #333;
    display: block;
    margin-top: 8px;
  } */
`

export const TreatmentsRow = styled.div`
  margin: 0 -15px;
  @media (max-width: 990px) {
    margin: 0 -10px;
  }
`

export const TreatmentsCol = styled.div`
  margin-bottom: 50px;
  width: 25%;
  float: left;
  padding: 0 15px;
  @media (max-width: 990px) {
    padding: 0 10px;
    width: 33.333%;
  }
  @media (max-width: 575px) {
    width: 50%;
    margin-bottom: 40px;
  }
`

export const Treatment = styled.div`
  position: relative;
`

export const TreatmentAvatar = styled.div``

export const TreatmentTitle = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background: #00000070;
  h3{
    padding: 0 30px;
  }
`



export default BlogTreatmentsWrapper
