import React, { memo, useState } from 'react';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../utils/helpers';
import { SlideDown } from 'react-slidedown';

const ItemCollapse = ({ title, show = false, children }) => {
  const [state, setState] = useState(show);

  return (
    <dl>
      <dt className={` ${state ? 'active' : ''}`} onClick={() => setState(!state)}>
        <RichText render={[title]} serializeHyperlink={serializeHyperlink} />
        <span className="arrows" />
      </dt>
      <SlideDown className={'my-dropdown-slidedown'}>
        {state ? <dd>{children}</dd> : null}
      </SlideDown>
    </dl>
  );
};

const AccordianContent = memo(({ content }) => {
  function handleAccordianContent() {
    const data = [];
    let item = null;

    content.map((v, i) => {
      if (v.type.indexOf('heading') !== -1) {
        if (item && item?.title) data.push(item);
        item = { title: v };
      } else {
        item = { ...item, content: item?.content ? [...item.content, v] : [v] };
      }

      if (i + 1 === content.length) {
        if (item && item?.title) data.push(item);
      }
    });

    return data;
  }

  return (
    <div className="container page__content single-content accordian-content">
      <div className="row">
        <div className="col-md-12">
          {handleAccordianContent().map(({ title, content }, i) => (
            <ItemCollapse title={title} key={i}>
              <RichText render={content} serializeHyperlink={serializeHyperlink} />
            </ItemCollapse>
          ))}
        </div>
      </div>
    </div>
  );
});

export default AccordianContent;
