import { useStaticQuery, graphql } from 'gatsby';

export const useProductMetafields = () => {
  const { allShopifyProductMetafield } = useStaticQuery(query);
  const metafields = {};
  allShopifyProductMetafield?.group?.forEach(({ id, nodes }) => {
    metafields[id] = nodes;
  });

  return { metafields };
};

export default useProductMetafields;

const query = graphql`
  query {
    allShopifyProductMetafield(
      filter: {
        key: { in: ["hide_price", "value_price_2", "no_dispatch", "purchase_method", "summaryData"] }
        product: { priceRangeV2: { maxVariantPrice: { amount: { ne: "0.0" } } } }
        value: { ne: "" }
      }
    ) {
      totalCount
      group(field: product___id, limit: 100) {
        id: fieldValue
        nodes {
          key
          namespace
          value
        }
      }
    }
  }
`;
