import React, { memo } from 'react'

const Sidebar = memo((props) => {

    const {
        showSidebarMobile,
        setShowSidebarMobile,
        children
    } = props;

    return (
        <div className={`main__archive-product__sidebar sidebar ${showSidebarMobile ? 'mobile' : ''}`}>
            <div className="sidebar__header mobile-title">
                {/* <span>Filter {newProducts.length} products found</span> */}
                <span>Filter Options</span>
                <button onClick={() => setShowSidebarMobile(false)} className="close-filter"></button>
            </div>

            <div className='sidebar__body'>
                {children}
            </div>
        </div>
    )
})

export default Sidebar