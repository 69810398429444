import React from 'react';

const ArrowLeft = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 68 34"
    style={{ enableBackground: 'new 0 0 68 34' }}
    xmlSpace="preserve"
  >
    <style type="text/css" dangerouslySetInnerHTML={{ __html: '\n   .st0{fill:#333333;}\n' }} />
    <g>
      <g>
        <path
          className="st0"
          d="M0.6,18.1L16,33.5c0.1,0.1,0.1,0.1,0.2,0.2c0.6,0.6,1.6,0.5,2.2-0.2c0.6-0.6,0.5-1.6-0.2-2.2L5.4,18.6h60.9
		   c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H5.4L18.2,2.8c0.1-0.1,0.1-0.1,0.2-0.2c0.6-0.6,0.5-1.6-0.2-2.2c-0.6-0.6-1.6-0.5-2.2,0.2
		   L0.6,16l0,0C0,16.6,0,17.5,0.6,18.1z"
        />
      </g>
    </g>
  </svg>
);

export default ArrowLeft;
