import loadable from '@loadable/component';
import React, { memo } from 'react';
import './styles.scss';

const HeaderPage = loadable(() => import('./HeaderPage'));
const GridProducts = loadable(() => import('./GridProducts'));

const WhishlistContaier = () => {
  return (
    <div className="page wishlist-page wishlish">
      <div className="container-xl">
        <HeaderPage />
        <GridProducts />
      </div>
    </div>
  );
};

export default memo(WhishlistContaier);
