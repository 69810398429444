import React, { memo, useEffect, useRef } from 'react';
import { graphql, Script } from 'gatsby';
// import { RichText } from 'prismic-reactjs';
// import { serializeHyperlink } from '../../../utils/helpers'

const Embed = memo(({ slice, index, ...props }) => {
  // slice.primary.text.text
  const box = useRef(null);

  useEffect(() => {
    if (box && box?.current) {
      const div = document.createElement('div');
      div.innerHTML = slice.primary.embed.text;

      box.current.appendChild(div);
    }
  }, []);

  return (
    <>
      {props[`before__${slice.slice_type}__${index}`]}
      <section className={`${slice.slice_type}`} ref={box}>
        {/* <RichText render={slice.primary.text.richText} serializeHyperlink={serializeHyperlink} /> */}
        <Script async src="//www.instagram.com/embed.js" />
      </section>
      {props[`after__${slice.slice_type}__${index}`]}
    </>
  );
});

export default Embed;

export const query = graphql`
  fragment BlogPostNewDataBodyEmbed on PrismicBlogPostNewDataBodyEmbed {
    primary {
      embed {
        text
      }
    }
  }
`;
