import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import loadable from "@loadable/component";

const CustomLink = loadable(() => import('../common/CustomLink'))

export const query = graphql`
{
  prismicHeaderSubNavigation(uid: {eq: "home-page"}) {
    data {
      hover_background_colour
      background_colour
      nav_group {
        hover_text
        nav_text
        nav_link {
          url
          uid
          link_type
          type
        }
      }
    }
  }
}
`

const HeaderSubNavigation = (props) => {

  const { prismicHeaderSubNavigation: { data } } = props.data || useStaticQuery(query)

  if (props.type === "main__services") {
    return (
      <div className="main__services -bg-color d-block d-lg-none">
        <div className="main__services__container container-xl">
          <ul className="main__services__container__nav">
            {
              data.nav_group.map((v, i) => i < 3 && (
                <li key={i} className="main__services__container__nav__item">
                  <CustomLink link={v.nav_link} text={v.nav_text} title={v.hover_text} />
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className="header__bottom -bg-color d-none d-lg-block" style={{ backgroundColor: data.background_colour }}>
      <div className="header__bottom__container container-xl">
        <ul className="header__bottom__container__nav">
          {
            data.nav_group.map((v, i) => (
              <li key={i} className="header__bottom__container__nav__item">
                <CustomLink link={v.nav_link} text={v.nav_text} title={v.hover_text} />
              </li>
            ))
          }
          <style>
            {`.wrapper .header__bottom__container__nav__item a:hover::after{
                  background-color: ${data.hover_background_colour};
                }

                .wrapper .header__bottom__container__nav__item a:hover:before{
                  border-color: transparent transparent ${data.hover_background_colour} transparent;
                }`
            }
          </style>
        </ul>
      </div>
    </div>
  )
}

export default HeaderSubNavigation