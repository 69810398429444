import { storeFontApi, accessToken } from '../constants/shopify';
import { loaded, setCookie, getCookie } from '../utils/helpers';

const options = {
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
    'X-Shopify-Storefront-Access-Token': accessToken,
  },
};

/**
 *
 * @returns {boolean}
 */
export const isLogged = () => {
  const { accessToken } = getToken();
  return accessToken && accessToken.trim() !== '' ? true : false;
};

/**
 *
 * @param {string} token
 */
export const setToken = (token) => {
  if (loaded) {
    localStorage.setItem('auth', token ? JSON.stringify(token) : '');
    if (token?.accessToken) setCookie('gatsby_secure_session_id', token?.accessToken || '');
    else setCookie('gatsby_secure_session_id', '');
  }
};

/**
 *
 * @returns { {accessToken: string, email: string, expiresAt: string, firstName: string} }
 */
export const getToken = () => {
  const token = loaded ? localStorage.getItem('auth') || '{}' : '{}';
  const accessToken = loaded ? getCookie('gatsby_secure_session_id') : '';
  const { email, expiresAt, firstName } = JSON.parse(token);
  return { accessToken, email, expiresAt, firstName };
};

/**
 *
 * @param {{email: string, password: string}} input
 * @returns
 */
export const customerAccessTokenCreate = async (input) => {
  const query = `
          mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
              customerAccessTokenCreate(input: $input) {
                  customerAccessToken {
                      accessToken
                      expiresAt
                  }
                  customerUserErrors {
                      code
                      field
                      message
                  }
              }
          }
        `;

  const {
    data: { customerAccessTokenCreate },
  } = await fetch(storeFontApi, {
    ...options,
    body: JSON.stringify({
      query,
      variables: { input },
    }),
  }).then((res) => res.json());

  return customerAccessTokenCreate;
};

/**
 *
 * @param {string} token
 * @returns
 */
export const getInfoCustomer = async (token) => {
  const query = `{
    customer(customerAccessToken: "${token}") {
      firstName
      lastName
      email
      phone
    }
  }`;

  const {
    data: { customer },
  } = await fetch(storeFontApi, {
    ...options,
    body: JSON.stringify({
      query,
    }),
  }).then((res) => res.json());

  return customer;
};

/**
 *
 * @param {string} email
 * @param {string} return_url
 * @returns
 */
export const getUrlMultypass = async (email, return_url = '/') => {
  const { data } = await fetch(`/api/customer`, {
    method: 'POST',
    body: JSON.stringify({ email, return_url, action: 'generate-token-multypass' }),
    headers: {
      'content-type': `application/json`,
    },
  }).then((res) => res.json());

  return data;
};

/**
 *
 * @param {{email: string, password: string}} input
 * @returns
 */
export const customerCreate = async (input) => {
  const query = `
    mutation customerCreate($input: CustomerCreateInput!) {
      customerCreate(input: $input) {
        customer {
            firstName
            lastName
            email
            phone
        }
        customerUserErrors {
            field
            code
            message
        }
      }
    }
  `;

  const data = await fetch(storeFontApi, {
    ...options,
    body: JSON.stringify({
      query,
      variables: { input },
    }),
  }).then((res) => res.json());

  return data;
};

/**
 *
 * @param {string} email
 * @returns
 */
export const customerResetPassowd = async (email) => {
  const data = await fetch(`/api/customer`, {
    method: 'POST',
    body: JSON.stringify({ email, action: 'reset-password' }),
    headers: {
      'content-type': `application/json`,
    },
  }).then((res) => res.json());

  return data;
};

/**
 *
 * @param {string} email
 * @returns
 */
export const getInfoCustomerCurrent = async (email) => {
  const {
    data: { customers },
  } = await fetch(`/api/customer`, {
    method: 'POST',
    body: JSON.stringify({ email, action: 'get-me' }),
    headers: {
      'content-type': `application/json`,
    },
  }).then((res) => res.json());

  return customers && customers?.length > 0 ? customers[0] : {};
};
