import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
export const MasonryCardWrapper = styled.div`
  position: relative;
  &:hover {
    .post_preview {
      .image {
        transform: scale(1.05);
      }
      .overlay {
        opacity: 1;
      }
    }
  }
`;

export const PostPreview = styled.div`
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
  .image {
    display: block;
    overflow: hidden;
    transition: 0.25s ease-in-out;
  }
`;

export const PostOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    color: #fff;
    background-color: #00000060;
    transition: 0.25s ease-in-out;
    opacity: 0;

    @media (max-width: 991.98px) {
      opacity: 1;
    }

    h4 {
      font-size: 50px;
      @media (max-width: 99.98px) {
        font-size: 40px;
      }

      @media (max-width: 767.98px) {
        font-size: 35px;
      }
    }
`;

export const PostDetails = styled.div`
  padding: 15px;
`;

export const PostMeta = styled.div`
  display: flex;
  /* align-items: center; */
  margin-bottom: 15px;
`;

export const PostDate = styled.div`
  color: ${themeGet('colors.lightTextColor', '#757575')};
  min-width: 80px;
`;

export const ReadingTime = styled.div`
  color: ${themeGet('colors.lightTextColor', '#757575')};
  position: relative;
  padding-left: 7px;
  margin-left: 5px;
  min-width: 80px;

  &::before {
    content: '';
    position: absolute;
    top: 13px;
    transform: translateY(-50%);
    left: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    display: block;
    background: ${themeGet('colors.lightTextColor', '#757575')};
  }
`;

export const ReadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 991.98px) {
    margin-top: 15px;
  }

  a {
    font-size: 24px;
    color: #fff;
    max-width: 80%;
    
    @media (max-width: 991.98px) {
      font-size: 13px;
      max-width: 100%;
      text-decoration: underline;
      color: #333;
    }
    &:hover {
      color: #fff;
      text-decoration: none;
      @media (max-width: 991.98px) {
        color: #333;
        text-decoration: underline;
      }
    }
    svg {
      margin-left: 5px;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const PostTitle = styled.h2`
  font-size: 20px;
  color: ${themeGet('colors.textColor', '#292929')};
  margin-bottom: 15px;
  text-transform: capitalize;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  a {
    color: ${themeGet('colors.textColor', '#292929')};
    &:hover{
      color: ${themeGet('colors.textColor', '#292929')};
      text-decoration: none;
    }
  }
  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

export const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: none;
  }

  a {
    display: block;
    margin-left: 20px;
    font-weight: 400;
    color: ${themeGet('primary', '#D10068')};
    @media (max-width: 990px) {
      margin-left: 25px;
    }
  }
`;

export const PostCat = styled.div`
    background-color: #333;
    border: 1px solid #fff;
    color: #fff;
    margin-bottom: 0;
    padding: 5px 20px;
    position: absolute;
    right: 15px;
    top: 15px;
`;

export const PostCats = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-end;
  height: 39px;
  overflow: hidden;

  a {
    display: block;
    margin-left: 20px;
    font-weight: 400;
    color: ${themeGet('colors.lightTextColor', '#757575')};
    text-transform: uppercase;

    &:hover{
      color: ${themeGet('colors.lightTextColor', '#757575')};
    }

    @media (max-width: 990px) {
      margin-left: 25px;
    }
  }
`;

export const PostExcerpt = styled.div`
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
`;