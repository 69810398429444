import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../../utils/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CustomLink from '../../../components/common/CustomLink';

const LocationHours = memo(({ slice }) => {
  const {
    items,
    primary: { lh_title, button_link, button_text, lh_meta_title, lh_map_image, lh_address },
    slice_type,
  } = slice;

  return (
    <section className={`container ${slice_type}`}>
      <div className="row">
        <div className="col">
          <p className="meta-title">{lh_meta_title}</p>
          <h3 className="title">{lh_title}</h3>
          <div className="table-time-opening">
            <table>
              {items.map(({ lh_day_name, lh_time_opening }, i) => (
                <tr>
                  <td>{lh_day_name}</td>
                  <td>{lh_time_opening}</td>
                </tr>
              ))}
            </table>
          </div>

          <div className="address">
            <RichText render={lh_address.richText} serializeHyperlink={serializeHyperlink} />
          </div>

          {button_text && (
            <button type="button" className="button btn-activeskin mb-3">
              <CustomLink link={button_link} text={button_text} />
            </button>
          )}

          <div className="social-icon">
            <a href="https://www.facebook.com/activeskin">
              <FacebookIcon fontSize="large" />
            </a>
            <a href="https://www.instagram.com/activeskin">
              <InstagramIcon fontSize="large" />
            </a>
          </div>
        </div>

        {lh_map_image?.gatsbyImageData && (
          <div className="col-md-8">
            <GatsbyImage image={getImage(lh_map_image)} alt={lh_map_image?.alt || 'activeskin'} />
          </div>
        )}
      </div>
    </section>
  );
});

export default LocationHours;

export const query = graphql`
  fragment SalonStaticPagesDataBodyLocationHours on PrismicSalonStaticPagesDataBodyLocationHours {
    primary {
      lh_title
      button_text
      button_link {
        url
        uid
        type
        link_type
      }
      lh_meta_title
      lh_address {
        richText
      }
      lh_map_image {
        alt
        gatsbyImageData
      }
    }

    items {
      lh_day_name
      lh_time_opening
    }
  }
`;
