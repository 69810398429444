import React, { memo } from 'react';
import loadable from '@loadable/component';

const FilterCollapse = loadable(() => import('../FilterCollapse'));

const FilterColour = ({
  metafield_key,
  metafields,
  _metafields,
  handleToggleFilter,
  handleCheckFilterActive,
  colour_filter_group,
  ...props
}) => {
  return (
    <FilterCollapse {...props}>
      <ul>
        {_metafields.map((metafield, metafieldIndex) => {
          const color = colour_filter_group?.filter(
            ({ colour_name }) => colour_name == metafield
          )?.[0]?.colour_code;

          return (
            <li onClick={() => handleToggleFilter(metafield_key, metafield)} key={metafieldIndex}>
              <span className={handleCheckFilterActive(metafield_key, metafield) ? 'active' : ''}>
                <span className="color" style={{ background: color || metafield }}></span>

                {metafield}
                <span className="num">({metafields[metafield].length})</span>
              </span>
            </li>
          );
        })}
      </ul>
    </FilterCollapse>
  );
};

export default memo(FilterColour);
