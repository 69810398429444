import { navigate } from 'gatsby-link';
import React, { memo, useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { getVariantByProductId, getVariantById } from '../../services/productVariant';
import { getModelUrl, checkProductHasCollection, overwriteHidePrice } from '../../utils/helpers';
import { getPwBrands } from '../../utils/product-password';

const ButtonAddToCart = memo((props) => {
  const { product, hide_price, setState: setStatePrice, purchase_method, no_dispatch } = props;
  const { addVariantToCart, isCartOpen, checkout, brands, setState: setStateStore } = useStore();

  let isGiftCards = product?.productType === 'Gift Cards';

  const [state, setState] = useState('Add');
  const [inventory, setInventory] = useState({
    policy: null,
    quantity: -1,
    comingSoon: null,
  });

  const isRidirectDetail = product?.collections
    ? checkProductHasCollection(product?.collections, 'title', [
        'Free Gifts',
        'Special Price',
        'Special Gifts',
      ])
    : product?.isRidirectDetail;

  const handleAddToCart = async () => {
    event.preventDefault();

    let password = getPwBrands(product?.vendor);

    if (
      (purchase_method === 'Password Protected' && !password) ||
      password !== brands?.[product?.vendor]
    ) {
      navigate(getModelUrl('buy', product.handle));
      return;
    }

    if (
      purchase_method === 'Contact to Order' ||
      purchase_method === 'Hide Price' ||
      purchase_method === 'Phone To Order' ||
      hide_price ||
      purchase_method === 'Book a Consult' ||
      isRidirectDetail
    ) {
      navigate(getModelUrl('buy', product.handle));
    } else if (state === 'Add') {
      if (product.variants.length > 1) {
        navigate(getModelUrl('buy', product.handle));
      } else {
        if (isGiftCards) {
          addVariantToCart(product, product.variants[0].storefrontId, 1);
        } else {
          let data;
          if (inventory.policy === null) {
            data = await handleGetVariantById();
            if (!data.active) return;
          } else {
            data = inventory;
          }
          const { policy, comingSoon, quantity } = data;

          const item = checkout.lineItems.find(
            (v) => v.variant.id === product.variants[0]?.shopifyId
          );
          if (quantity > 0 && quantity - Number(item?.quantity || 0) >= 1) {
            setState('Adding...');
            addVariantToCart(
              product,
              product.variants[0].storefrontId,
              1,
              false,
              'Ready to dispatch'
            );
            // } else {
            //   setStateStore((_state) => ({ ..._state, isCartOpen: true }));
            // }
          } else if (policy || comingSoon) {
            let status = 'Dispatches in 3 - 5 business days';
            if (no_dispatch) {
              if (no_dispatch.indexOf('business days') !== -1) {
                status = `Dispatches in ${no_dispatch}`;
              } else status = no_dispatch;
            }

            addVariantToCart(product, product.variants[0].storefrontId, 1, false, status);
          } else {
            navigate(getModelUrl('buy', product.handle));
          }
        }
      }
    }
  };

  async function handleGetVariantById() {
    return await getVariantById(product.variants[0].storefrontId).then(({ data }) => {
      const {
        inventoryPolicy,
        inventoryQuantity,
        product: _product,
        price,
        compareAtPrice,
      } = data?.productVariant || {};
      const active = _product?.status === 'ACTIVE';
      const policy = inventoryPolicy === 'CONTINUE';
      const comingSoon =
        active &&
        policy &&
        (no_dispatch?.indexOf('business days') !== -1 ||
          ['Pre-Order', 'Ready'].includes(no_dispatch));

      setStatePrice((_state) => ({
        ..._state,
        price,
        compareAtPrice,
        active,
        quantity: policy || comingSoon || isGiftCards ? 1 : 0,
        currentQuantity: inventoryQuantity,
        purchase_method: _product?.purchase_method?.value || null,
        hide_price: overwriteHidePrice(
          product.vendor,
          _product?.hide_price?.value == 'true',
          _product?.purchase_method?.value
        ),
        no_dispatch: _product?.no_dispatch?.value || '',
      }));

      setInventory({
        policy,
        quantity: inventoryQuantity,
        comingSoon,
      });

      return { active, policy, comingSoon, quantity: inventoryQuantity };
    });
  }

  useEffect(() => {
    const len = product?.variants?.length;
    if (product.shopifyId && len > 0 && product?.variants[0]?.storefrontId) {
      getVariantByProductId(product.shopifyId, 1, true).then((data) => {
        if (!data) {
          setStatePrice((_state) => ({
            ..._state,
            active: false,
          }));
          return;
        }

        const { quantityAvailable, price, compareAtPrice, product: _product } = data || {};
        setStatePrice((_state) => {
          const imageIds = _state?.images?.map((v) => v?.shopifyId) || [];
          const images = [..._state?.images];

          (_product?.images || []).map((img, index) => {
            if (!imageIds?.includes(img?.id)) {
              images?.splice(index, 0, img);
              product?.images?.splice(index, 0, img);
            }
          });

          return {
            ..._state,
            price: price?.amount,
            compareAtPrice: compareAtPrice?.amount,
            quantity: quantityAvailable,
            currentQuantity: quantityAvailable,
            purchase_method: _product?.purchase_method?.value || null,
            hide_price: overwriteHidePrice(
              product.vendor,
              _product?.hide_price?.value == 'true',
              _product?.purchase_method?.value
            ),
            no_dispatch: _product?.no_dispatch?.value || '',
            images,
          };
        });

        if (quantityAvailable <= 0 && len === 1) {
          handleGetVariantById();
        }
      });
    }
  }, [product]);

  useEffect(() => {
    if (isCartOpen && state === 'Adding...') {
      setTimeout(() => {
        setState('Added');
      }, 1000);
      return () => {
        setTimeout(() => {
          setState('Add');
        }, 3000);
      };
    }
  }, [isCartOpen, state]);

  return (
    <a
      className={`${state !== 'Add' ? 'active' : ''}`}
      href="#"
      onClick={() => handleAddToCart()}
      title="Add to Cart"
    >
      {state}
    </a>
  );
});

export default ButtonAddToCart;
