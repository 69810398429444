import { Link } from 'gatsby';
import React, { memo } from 'react';
import { getModelUrl } from '../../utils/helpers';

const BreadcrumbBrand = memo((props) => {
  const { brands, brand_current, current, className } = props;

  const links = () => {
    const dataLinks = [];

    brands?.forEach(({ brand }) => {
      if (brand && brand?.document && brand?.document?.data) {
        const {
          document: {
            data: { name, url },
          },
        } = brand;
        dataLinks.push({
          slug: `/${url}`,
          label: name,
        });
      }
    });

    brand_current?.slug && dataLinks.push(brand_current);

    return dataLinks;
  };

  return (
    <nav
      className={`main__archive-product__breadcrumb ${className || ''}`}
      aria-label="breadcrumb"
      itemScope
      itemtype="http://schema.org/Breadcrumb"
    >
      <ol
        className="breadcrumb bg-white pl-0"
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        <li
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to="/">
            <span itemProp="name">Home</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        <li
          itemScope
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemType="http://schema.org/ListItem"
        >
          <Link itemProp="item" to="/brands">
            <span itemProp="name">Brands</span>
          </Link>
          <meta itemProp="position" content="2" />
        </li>
        {links().map(({ label, slug }, index) => (
          <li
            key={index}
            className="breadcrumb-item"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link itemProp="item" to={slug}>
              <span itemProp="name">{label}</span>
            </Link>
            <meta itemProp="position" content={index + 3} />
          </li>
        ))}

        <li
          className="breadcrumb-item active d-md-block d-none"
          aria-current="page"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link to={getModelUrl('buy', current.slug)} itemProp="item" hidden>
            <span itemProp="name">{current.label}</span>
          </Link>
          <span>{current.label}</span>
          <meta itemProp="position" content={links().length + 3} />
        </li>
      </ol>
    </nav>
  );
});

export default BreadcrumbBrand;
