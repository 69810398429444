import React, { memo } from 'react';
import { formatPrice } from '../../utils/helpers';

const ProductPrice = memo(({ price, compareAtPrice }) => {
  price = Number(price);
  compareAtPrice = Number(compareAtPrice);

  if (compareAtPrice > 0 && compareAtPrice > price) {
    return (
      <>
        <span className="price">{formatPrice(price)}</span>
        <del className="price --del-price">{formatPrice(compareAtPrice)}</del>
      </>
    );
  }

  return <span className="price">{formatPrice(price)}</span>;
});

export default ProductPrice;
