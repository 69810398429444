import React from 'react';
// import { Link } from 'gatsby';
import { formatPrice } from './../../utils/helpers';

const LineItemFreeGift = (props) => {
  const { line_item, removeLineItemInCart } = props;

  //   const vendors = JSON.parse(
  //     line_item?.customAttributes?.filter(attr => attr.key === '_collections')[0]?.value || '[]'
  //   )?.filter(value => value?.indexOf('Brands/') === 0 && value?.split('/')?.length === 2);

  //   const vendor = vendors?.length > 0 ? vendors[0]?.split('/')[1] : '';

  const isFreeGift =
    line_item?.customAttributes?.findIndex(
      (attr) => attr?.key === '_isGift' && attr?.value == 1
    ) !== -1;

  return (
    <li className="Line-item free-gift">
      <div className="Line-item__img">
        {line_item?.variant?.image ? (
          <img src={line_item.variant.image.src} alt={`${line_item.title} product shot`} />
        ) : null}
      </div>
      <div className="Line-item__content">
        <div className="Line-item__content-row d-flex">
          <h4 className="Line-item__title">
            <span className="Line-item__title__brand">
              {isFreeGift ? 'Free Gift' : 'Special Gift'}
            </span>
            <span className="title-format">{line_item.title}</span>
            {/* <Link className="Line-item__title__brand" to={getModelUrl('brands', vendor, true)}>
              {vendor}
            </Link>
            {line_item.title
              .replace('FREE', '')
              .replace(vendor, '')
              .replace(vendor.toUpperCase(), '')}
            {line_item?.variant?.title != 'Default Title' && (
              <div className="Line-item__sub-title">
                <i>{line_item?.variant?.title}</i>
              </div>
            )} */}
          </h4>
          <div className="Line-item__price">
            {formatPrice(isFreeGift ? '0.0' : line_item?.variant?.price?.amount)}
          </div>
        </div>
        <div className="Line-item__content-row">
          <button className="Line-item__remove" onClick={() => removeLineItemInCart(line_item)}>
            X <u>Remove</u>
          </button>
          {/* <div className="Line-item__sub-title">Free Gift</div> */}
        </div>
      </div>
    </li>
  );
};

export default React.memo(LineItemFreeGift);
