import React, { useEffect, memo } from 'react';

const AfterPayment = memo(({ price }) => {
  useEffect(() => {
    setTimeout(() => {
      var css = `.afterpay-paragraph{display:flex;flex-wrap:wrap;width:105px;line-height:1.5 !important;position:relative}
            .afterpay-logo-link{margin-bottom: 5px}
            #logo-link{position:absolute;width:100%;height:100%;left:0;text-indent: 9999999px}
            .afterpay-main-text,span.afterpay-text2{order:1;font-size:10px;}`;
      var style = document.createElement('style');

      if (style?.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      document.querySelector('afterpay-placement')?.shadowRoot &&
        document.querySelector('afterpay-placement')?.shadowRoot?.appendChild(style);
    }, 100);
  }, [price]);

  return (
    <div className="after-payment">
      <afterpay-placement
        style={{ margin: 0 }}
        data-locale="en_US"
        data-currency="USD"
        data-amount={price}
        data-modal-theme="white"
        data-show-with="false"
        data-modal-link-style="learn-more-text"
      ></afterpay-placement>
    </div>
  );
});

export default AfterPayment;
