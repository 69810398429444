import * as React from "react"
import { RichText } from 'prismic-reactjs';
import OpeningHoursWrapper, {
    OpeningHoursTitle,
} from "./style"
import { serializeHyperlink } from "../../../../utils/helpers";
import loadable from "@loadable/component";

const CustomLink = loadable(() => import('./../../../../components/common/CustomLink'))

type OpeningHoursProps = {
    opening_hours_detail?: any
    opening_hours_title?: string
    button_book_now?: string
    button_link_book_now?: any
}

const OpeningHours: React.FunctionComponent<OpeningHoursProps> = React.memo(({
    opening_hours_detail,
    opening_hours_title,
    button_book_now,
    button_link_book_now }) => {

    return (
        <OpeningHoursWrapper className="container">

            <OpeningHoursTitle>{opening_hours_title}</OpeningHoursTitle>

            <RichText render={opening_hours_detail.richText} serializeHyperlink={serializeHyperlink} />

            <CustomLink className="btn-activeskin" link={button_link_book_now} text={button_book_now} />

        </OpeningHoursWrapper>
    )
})

export default OpeningHours
