import React from 'react';

const ActiveskinLogo = ({ white = false }) => (
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2865.89 336.68">
    <defs>
      <style>{white ? `.b{fill:#fff;}` : `.b{fill:#333;}`}</style>
    </defs>
    <path
      class="b"
      d="M1521.66,46.2v94.46h200.46v42.4h-200.44v107.18h228.68v42.39h-274.87V3.94h272.45V46.2h-226.28Z"
    />
    <path
      class="b"
      d="M0,332.8c2.92-5.83,5.65-11.31,8.39-16.78,16.46-32.81,32.93-65.62,49.4-98.43,25.06-49.93,50.12-99.86,75.2-149.78,10.28-20.46,20.61-40.89,30.83-61.38,.98-1.98,2.04-2.75,4.31-2.72,10.41,.14,20.82,.11,31.24,.02,1.89-.02,2.8,.6,3.62,2.31,27.16,56.19,54.38,112.34,81.59,168.5,25.1,51.81,50.18,103.62,75.27,155.43,.39,.8,.73,1.62,1.21,2.7-1.15,.05-2.01,.12-2.87,.12-15.16,0-30.33-.04-45.49,.06-2.14,.01-3.14-.68-4.05-2.57-14.8-30.69-29.71-61.34-44.5-92.04-.89-1.85-1.85-2.44-3.86-2.44-52.47,.06-104.93,.06-157.4-.01-2.08,0-2.99,.73-3.85,2.48-14.78,30.26-29.65,60.48-44.38,90.77-1.36,2.8-2.84,3.9-6.06,3.85-14.96-.2-29.92-.09-44.88-.09H0ZM242.35,193.36c-19.85-40.6-39.49-80.77-59.3-121.3-20.37,40.59-40.55,80.8-60.88,121.3h120.18Z"
    />
    <path
      class="b"
      d="M675.11,96.48c-7.94-10.68-16.66-20.17-26.85-28.26-16.16-12.83-34.77-19.94-54.96-23.01-28.98-4.4-57.85-3.84-86.21,4.1-31.94,8.95-57.44,26.78-74.18,55.92-9.8,17.06-14.12,35.71-15.06,55.23-1.12,23.32,1.75,45.99,12.16,67.16,14.78,30.04,38.95,49.19,70.67,58.98,23.78,7.35,48.2,8.61,72.87,6.42,17.24-1.53,33.87-5.45,49.43-13.21,18.21-9.09,33.03-22.36,46.07-37.76,.59-.69,1.17-1.39,1.77-2.07,.13-.14,.33-.22,.61-.4,13.11,7.85,26.27,15.72,39.88,23.86-3.45,3.9-6.67,7.71-10.08,11.34-31.38,33.36-69.63,53.79-115.15,59.58-40.06,5.1-79.54,2.23-117.33-13.09-35.81-14.53-63.55-38.42-81.21-73.16-10.45-20.56-15.27-42.63-16.42-65.55-1.61-31.92,2.46-62.87,17.01-91.78,16.58-32.95,42.57-56.18,75.67-71.77,21.7-10.22,44.73-15.5,68.54-17.69,19.48-1.79,38.94-1.8,58.37,.64,50.19,6.3,91.38,28.63,122.54,68.78,.43,.55,.78,1.17,1.27,1.9-13.09,7.92-26.12,15.8-39.41,23.84Z"
    />
    <path
      class="b"
      d="M1285.81,263.86c5.47-11.55,10.7-22.55,15.91-33.57,35.33-74.73,70.65-149.47,106.03-224.18,.46-.97,1.69-2.24,2.57-2.24,16.07-.14,32.14-.1,48.7-.1-.52,1.14-.91,2-1.31,2.86-50.96,107.82-101.92,215.63-152.82,323.47-.95,2.02-2,2.78-4.27,2.75-9.6-.13-19.21-.13-28.81,0-2.24,.03-3.36-.63-4.34-2.67-14.75-30.6-29.61-61.16-44.42-91.74-18.11-37.38-36.2-74.77-54.31-112.15-19.43-40.1-38.87-80.2-58.3-120.3-.3-.62-.52-1.27-.86-2.12,.93-.05,1.6-.11,2.27-.11,15.16,0,30.32,.02,45.49-.04,1.73,0,2.68,.48,3.46,2.09,19.39,40.12,38.85,80.21,58.28,120.31,18.95,39.1,37.88,78.21,56.82,117.32,3.21,6.62,6.43,13.23,9.91,20.4Z"
    />
    <path class="b" d="M898.68,332.64h-46.75V46.26h-126.3V3.99h298.69V46.02h-125.65V332.64Z" />
    <path
      class="b"
      d="M2583.71,3.78c6.46,0,12.71-.06,18.97,.07,.89,.02,2,.83,2.61,1.59,61.82,76.62,123.64,153.25,185.38,229.94,18.36,22.81,36.5,45.8,54.75,68.71,.68,.86,1.38,1.71,2.68,3.33V3.94h17.79V332.8c-5.26,0-10.48-.26-15.67,.09-3.93,.26-6.34-1.11-8.8-4.19-53.69-67.36-107.49-134.63-161.27-201.92-25.47-31.87-50.94-63.74-76.41-95.61-.69-.86-1.41-1.69-2.58-3.07V332.66h-17.46V3.78Z"
    />
    <path
      class="b"
      d="M2054.09,101.2h-17.25c-.56-4.57-.98-9.16-1.7-13.7-2.62-16.57-8.27-31.82-20.13-44.16-10.23-10.65-23.1-16.75-37.09-20.72-14.36-4.08-29.1-5.74-43.97-6.33-22.48-.89-44.84-.13-66.85,5.07-10.57,2.5-20.68,6.19-29.89,12.08-16.27,10.4-24.67,25.4-25.81,44.51-.94,15.76,2.81,30.06,13.96,41.8,7.3,7.68,16.25,12.89,26.05,16.7,18.05,7.02,36.9,11.02,55.86,14.57,30.07,5.62,60.06,11.57,89.18,21.2,11.22,3.71,22.2,8.02,32.26,14.38,18.05,11.41,28.14,27.93,31.27,48.83,2.24,15,1.66,29.81-4.38,44-6.99,16.43-19.42,27.79-34.65,36.38-18.38,10.37-38.3,16.1-59.11,18.66-29.69,3.66-59.3,2.82-88.61-3.47-19.83-4.26-38.56-11.26-54.78-23.85-20.14-15.63-31.08-36.5-34.27-61.5-1.09-8.57-1.14-17.28-1.69-26.2h18.54c0,5.6-.35,11.15,.06,16.65,1.58,20.92,8.61,39.53,24.12,54.29,12.31,11.71,27.25,18.55,43.41,23.06,16.54,4.62,33.47,6.44,50.58,7,22.01,.73,43.82-.56,65.16-6.46,11.74-3.25,22.92-7.81,32.99-14.8,18.99-13.19,27.27-31.46,25.91-54.37-.67-11.3-3.24-21.93-10.2-31.14-6.35-8.39-15.05-13.6-24.43-17.88-14.8-6.74-30.58-10.23-46.31-13.86-29.89-6.89-59.85-13.5-89.65-20.73-14.1-3.42-28.05-7.74-40.75-15.07-15.85-9.16-28.28-21.43-34.47-39.12-13.15-37.59,3.64-76.61,39.92-92.94,14.98-6.74,30.81-10.21,47.03-12.06,21.25-2.43,42.54-2.64,63.83-.62,20.64,1.96,40.7,6.23,59.19,16.12,25.02,13.38,39.68,34.29,44.69,62.01,1.16,6.43,1.48,13.01,2.15,19.53,.07,.67-.09,1.36-.15,2.17Z"
    />
    <path
      class="b"
      d="M2239.89,144.77c64.93,62.69,129.55,125.08,194.74,188.02-8.26,0-15.79,.05-23.31-.07-.84-.01-1.79-.83-2.48-1.5-19.66-18.9-39.28-37.83-58.92-56.74-26.63-25.63-53.28-51.23-79.93-76.85-14.19-13.65-28.38-27.3-42.58-40.96-.65-.63-1.31-1.25-2.12-2.03-7.17,5.62-14.3,11.21-21.43,16.81-16.68,13.1-33.34,26.24-50.07,39.27-1.61,1.26-2.21,2.51-2.2,4.52,.06,37.87,.04,75.74,.04,113.61v3.76h-17.45V3.99h17.44V191.31c6.05-4.58,11.58-8.76,17.1-12.95,75.82-57.65,151.63-115.31,227.49-172.89,1.31-.99,3.25-1.58,4.91-1.62,7.07-.18,14.15-.07,21.23-.07h2.87c.06,.2,.11,.39,.17,.59-61.72,46.72-123.45,93.45-185.48,140.41Z"
    />
    <path class="b" d="M1046.67,3.91h46.33V332.66h-46.33V3.91Z" />
    <path class="b" d="M2506.24,332.67h-17.21V3.92h17.21V332.67Z" />
  </svg>
);

export default ActiveskinLogo;
