import React, { memo } from 'react';
import { Link } from 'gatsby';
import { getOriginByUrl, loaded } from '../../utils/helpers';

const CustomLink = memo(({ link, text, children, className, title, isLink = true, ...props }) => {
  const urls = [
    process.env.GATSBY_URL,
    loaded ? location?.origin : '',
    'https://activeskingatsby.gatsbyjs.io',
    'https://gatsbycloud.activeskin.com.au',
    'https://gatsby.activeskin.com.au',
    'https://activeskin.com.au',
    'https://www.activeskin.com.au',
  ];

  const handleLinkCollection = (doc = {}) => {
    const { uid, document, data } = doc;
    if (document?.url) {
      return `${document?.url}/`;
    } else if (document?.data?.url) {
      return `${document?.data?.url}/`;
    } else if (data?.url) {
      return `${data?.url}/`;
    } else return `/${uid}/`;
  };

  const checkUrl = (url) => {
    if (url && url?.charAt(0) !== '/') {
      return '/' + url;
    }
    return url;
  };

  if (link?.type === 'category' || link?.type === 'brand') {
    link['url'] = handleLinkCollection(link);
  }

  if (link?.link_type === 'Document') {
    return (
      <Link
        to={checkUrl(link?.url) || '#'}
        className={className || ''}
        title={title || ''}
        activeClassName="active-link"
        {...props}
      >
        {text}
        {children}
      </Link>
    );
  }

  const originUrl = getOriginByUrl(link?.url);
  if (link?.url && urls.includes(originUrl)) {
    return (
      <Link
        to={link?.url?.replace(originUrl, '')}
        className={className || ''}
        title={title || ''}
        activeClassName="active-link"
        {...props}
      >
        {text}
        {children}
      </Link>
    );
  }

  if (!isLink && !link?.url) {
    return (
      <>
        {text}
        {children}
      </>
    );
  }

  return (
    <a className={className || ''} href={link?.url || '#'} title={title || ''} {...props}>
      {text}
      {children}
    </a>
  );
});

export default CustomLink;
