import React, { memo } from 'react';

const Video = memo(({ video, height = 500 }) => {
  return (
    <iframe
      style={{ width: '100vw', maxWidth: '100%' }}
      height={height}
      src={video?.embedUrl}
      title={video.alt}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  );
  // return (
  //   <video controls>
  //     <source src={v?.source?.url} type="video/mp4" />
  //   </video>
  // );
});

export default Video;
