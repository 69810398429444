import React from 'react';

const WhistlistIcon = ({ white = false, ...props }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2214 2302"
    {...props}
  >
    <defs>
      <style>{white && `.cls-1{fill:#fff;}`}</style>
    </defs>
    <path
      class={white ? 'cls-1' : ''}
      d="M1118.79,702.88c47.63-48.26,88.49-91.81,131.64-133,99.45-94.85,217.56-125,348.38-85.65C1728.18,523.2,1810.4,613,1839.24,745.18c27.66,126.7-8.92,236.93-99.88,329.59q-284.14,289.5-568,579.3c-11.3,11.47-27.42,20.67-42.93,24.91-21.74,5.94-39.11-5.69-54.89-21.92q-249.67-256.82-500.32-512.7c-24.13-24.68-49.24-48.43-73.12-73.36-99.24-103.58-131.38-225.48-86.54-361.57C458.18,574,556.12,494,696.88,472.31c102.34-15.73,196.31,10.76,274.88,79.46,45.94,40.16,87.69,85.14,131,128.26C1108.29,685.53,1111.91,692.93,1118.79,702.88Zm1.49,875.13c4-3.12,6.41-4.61,8.33-6.57,186.75-190.59,374.22-380.48,559.76-572.24,62.6-64.7,84.27-145.2,65.13-233.26-21.77-100.17-83.23-168.06-181.8-197.32s-186.44-5.54-260.47,65.31c-51.43,49.22-100.81,100.58-151.32,150.77-27.49,27.31-50,27-77.67-.63-50.94-50.78-101.08-102.38-152.83-152.32-90.37-87.24-226.5-99.48-329.47-30.89-136.57,91-157.47,283.22-42.43,402,96.56,99.71,195,197.61,292.13,296.78C939.7,1391.62,1029.12,1484.2,1120.28,1578Z"
    />
  </svg>
);

export default WhistlistIcon;
