import React, { memo } from 'react';
import { graphql, Link } from 'gatsby';
import { getImgCropShopify, getModelUrl } from '../../../utils/helpers';
import { formatPrice } from './../../../utils/helpers';
import ReactSlick from '../../../components/common/ReactSlick';
import ProductPriceLive from '../../../components/common/ProductPriceLive';

const SingleShopifyProduct = memo(({ slice, index, ...props }) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 992;

  const products = slice?.items.map(({ shopify_product }) => shopify_product);

  const settings = {
    slidesToShow: 1.5,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.6,
        },
      },
    ],
  };

  const _products = products.map(
    (v, i) =>
      v && (
        <div key={i} className="product w-100">
          {v.image && (
            <Link className="product--image w-100" to={getModelUrl('buy', v.handle)}>
              <img
                style={{ maxWidth: '100%', height: 'auto' }}
                className="m-auto"
                width={280}
                height={280}
                src={getImgCropShopify(v.image?.src, '280x280')}
                alt={v.title}
              />
            </Link>
          )}
          <p className="product--brand">
            <Link to={getModelUrl('brands', v.vendor, true)}>{v.vendor}</Link>
          </p>
          <p className="product--name px-lg-0">
            <Link to={getModelUrl('buy', v.handle)}>{v.title.replace(v.vendor || '', '')}</Link>
          </p>
          <ProductPriceLive product={v} />
        </div>
      )
  );

  return (
    <>
      {props[`before__${slice.slice_type}__${index}`]}
      {props[`before__${slice.slice_type}`]}
      <section className={`${slice.slice_type}`}>
        <div className={`${slice.slice_type}__title -title`}>
          <h2>Shop This Blog</h2>
        </div>

        <div className="products">
          {isMobile ? <ReactSlick {...settings}>{_products}</ReactSlick> : _products}
        </div>
      </section>
      {props[`after__${slice.slice_type}`]}
      {props[`after__${slice.slice_type}__${index}`]}
    </>
  );
});

export default SingleShopifyProduct;

export const query = graphql`
  fragment BlogPostNewDataBodySingleShopifyProduct on PrismicBlogPostNewDataBodySingleShopifyProduct {
    items {
      shopify_product {
        admin_graphql_api_id
        title
        handle
        vendor
        image {
          src
        }
        variants {
          admin_graphql_api_id
          price
          title
          inventory_quantity
        }
      }
    }
  }
`;
