import React, { useState, memo } from 'react';
import loadable from '@loadable/component';
import { MenuImage } from './MenuImage';
import { SliceZone } from './SliceZone';
import { loaded } from '../../utils/helpers';

const CustomLink = loadable(() => import('../common/CustomLink'));

const MenuItem = memo(({ menu }) => {
  const [state, setState] = useState(false);

  const handleTongleState = (state) => {
    if (typeof window !== `undefined` && window.innerWidth >= 992) {
      setState(state);
    }
  };

  const isPC = loaded && window.innerWidth >= 992;
  const hasChild = menu.body.length > 0;

  return (
    <li
      {...(state ? { id: 'header__main__nav__item--active' } : {})}
      className={`header__main__nav__item${state ? ' active' : ''}`}
      onMouseMove={() => handleTongleState(true)}
      onMouseLeave={() => handleTongleState(false)}
    >
      {!hasChild || (hasChild && isPC) ? (
        <CustomLink
          link={menu.top_level_menu_link}
          text={menu.top_level_menu_item}
          className={`header__main__nav__link ${hasChild ? '-has-child' : ''}`}
          style={menu?.color ? { color: `${menu.color}` } : {}}
        />
      ) : (
        <a
          className={`header__main__nav__link ${hasChild ? '-has-child' : ''}`}
          style={menu?.color ? { color: `${menu.color}` } : {}}
        >
          {menu.top_level_menu_item}
        </a>
      )}

      {hasChild > 0 && (
        <>
          {/* <span className="btn-toggle-sub-nav -bg-arrow-right" /> */}
          <div className="nav-content">
            <div className="container-xl">
              <div className="row">
                <div className="col-xl-2 col-lg-3 d-lg-none d-block">
                  <ul className="nav-content__nav">
                    <li className="nav-content__nav__item ">
                      <CustomLink
                        link={menu.top_level_menu_link}
                        text={`All ${menu.top_level_menu_item}`}
                        className={`nav-content__nav__item__link`}
                      />
                    </li>
                  </ul>
                </div>
                {menu.body.findIndex((v) => v.primary && v.primary.column === 'Column 1') !==
                  -1 && (
                  <div className="col-xl-2 col-lg-3">
                    <ul className="nav-content__nav">
                      <SliceZone
                        sliceZone={menu.body.filter(
                          (v) => v.primary && v.primary.column === 'Column 1'
                        )}
                      />
                    </ul>
                  </div>
                )}
                {menu.body.findIndex((v) => v.primary && v.primary.column === 'Column 2') !==
                  -1 && (
                  <div className="col-xl-2 col-lg-3">
                    <ul className="nav-content__nav">
                      <SliceZone
                        sliceZone={menu.body.filter(
                          (v) => v.primary && v.primary.column === 'Column 2'
                        )}
                      />
                    </ul>
                  </div>
                )}
                {menu.body.findIndex((v) => v.primary && v.primary.column === 'Column 3') !==
                  -1 && (
                  <div className="col-xl-2 col-lg-3">
                    <ul className="nav-content__nav">
                      <SliceZone
                        sliceZone={menu.body.filter(
                          (v) => v.primary && v.primary.column === 'Column 3'
                        )}
                      />
                    </ul>
                  </div>
                )}
                {menu.body.findIndex((v) => v.primary && v.primary.column === 'Column 4') !==
                  -1 && (
                  <div className="col-xl-2 col-lg-3">
                    <ul className="nav-content__nav">
                      <SliceZone
                        sliceZone={menu.body.filter(
                          (v) => v.primary && v.primary.column === 'Column 4'
                        )}
                      />
                    </ul>
                  </div>
                )}

                {menu.body.findIndex((v) => v.primary && v.slice_type === 'group_of_links_1') !==
                  -1 && (
                  <div className="col-xl-2 d-xl-block d-none">
                    <ul className="nav-content__nav --highlighted">
                      <SliceZone
                        sliceZone={menu.body.filter((v) => v.slice_type === 'group_of_links_1')}
                      />
                    </ul>
                  </div>
                )}

                {menu.mega_menu_images.length > 0 && (
                  <div
                    className={`col ${
                      menu.highlighted_style === 'Flex' ? 'd-lg-block d-none' : 'd-xl-block d-none'
                    }`}
                  >
                    <div
                      className={`nav-content__nav --menu-image ${
                        menu.highlighted_style === 'Flex' ? 'row' : ''
                      }`}
                    >
                      {menu.mega_menu_images.map((v, i) => (
                        <MenuImage key={i} slice={v} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </li>
  );
});

export default MenuItem;
