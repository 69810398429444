// SliceZone.js file

import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';

const CustomLink = loadable(() => import('../../components/common/CustomLink'));

export const MenuImage = React.memo(({ slice }) => {
  return (
    <div className="box col-xl col-lg-6">
      <CustomLink
        link={slice.mega_menu_image_link}
        text={
          <>
            <GatsbyImage
              image={slice.mega_menu_image.gatsbyImageData}
              alt={slice.mega_menu_image.alt || 'photo'}
              width={352}
            />
            <span className="text-bellow-brand-img">{slice.mega_menu_image_label}</span>
          </>
        }
      />
    </div>
  );
});
