import React from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import Navbar from './navbar/navbar';
import Footer from '../../layout/footer';
import ScrollUpButton from '../blog/scroll-up-button/scroll-up-button';
import ResetCss from '../blog/reset-css';
import { theme } from '../blog/theme';
import HeaderAlertBar from '../header_alert_b';
import HeaderSubNavigation from '../header_sub_navigation';
import { graphql, useStaticQuery } from 'gatsby';

const Layout = ({ className, children }) => {
  const data = useStaticQuery(query);

  return (
    <ThemeProvider theme={theme}>
      <div className={`wrapper ${className || ''}`}>
        <HeaderAlertBar />
        <header id="header" className="header">
          <ResetCss />
          <Sticky top={0} innerZ={9999} activeclassName="nav-sticky">
            <Navbar />
          </Sticky>
          <HeaderSubNavigation data={data} />
        </header>
        <main className="main">{children}</main>
        <Footer />
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </div>
    </ThemeProvider>
  );
};

export default Layout;

export const query = graphql`
  {
    prismicHeaderSubNavigation(uid: { eq: "salon-page" }) {
      data {
        hover_background_colour
        background_colour
        nav_group {
          hover_text
          nav_text
          nav_link {
            url
            uid
            link_type
            type
          }
        }
      }
    }
  }
`;
