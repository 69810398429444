import React, { memo } from 'react';
import loadable from '@loadable/component';

const FilterCollapse = loadable(() => import('../FilterCollapse'));

const FilterMore = ({
  metafield_key,
  metafields,
  _metafields,
  handleToggleFilter,
  handleCheckFilterActive,
  ...props
}) => {
  return (
    <FilterCollapse {...props}>
      <ul>
        {_metafields.map((metafield, metafieldIndex) => (
          <li onClick={() => handleToggleFilter(metafield_key, metafield)} key={metafieldIndex}>
            <span className={handleCheckFilterActive(metafield_key, metafield) ? 'active' : ''}>
              {metafield}
              <span className="num">({metafields[metafield].length})</span>
            </span>
          </li>
        ))}
      </ul>
    </FilterCollapse>
  );
};

export default memo(FilterMore);
