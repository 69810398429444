import React, { memo } from 'react';
import { Link } from 'gatsby';

const PromotionType = ({ product }) => {
  const data = [];
  const promotion_type =
    product?.metafields?.filter((metafield) => metafield.key === 'promotion_type')?.[0]?.value || '';
  const _new = promotion_type?.indexOf('NEW') !== -1;
  const _sale = promotion_type?.indexOf('SALE') !== -1;

  if (_new) {
    data.push(
      <li className="promotion-type -blue">
        <Link to="/new-arrivals">NEW</Link>
      </li>
    );
  }

  if (_sale) {
    data.push(
      <li className="promotion-type -pink">
        <Link to="/clearance">SALE</Link>
      </li>
    );
  }

  return data;
};

export default memo(PromotionType);
