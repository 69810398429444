import React from 'react';

const MinusIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="minusIconTitle"
    stroke="#000000"
    stroke-width="2"
    stroke-linecap="square"
    stroke-linejoin="miter"
    fill="none"
    color="#000000"
    {...props}
  >
    <title id="minusIconTitle">Minus</title> <path d="M20,12 L4,12" />
  </svg>
);

export default MinusIcon;
