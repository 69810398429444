import React, { useEffect, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ListVariants from './ListVariants';

const SelectVariant = (props) => {
  const box = useRef(null);
  const { setVariant, className, variant, product, images } = props;

  const titleVariant =
    variant?.selectedOptions?.filter((v) => v.name == 'Color')?.[0]?.value || variant?.title;

  let image =
    images.find((img) => img?.altText && img?.altText.trim() === `swatch_${titleVariant}`) ||
    undefined;
  if (!image) image = variant.image;

  function toggle(show = true) {
    if (document.getElementsByClassName('select-variants__list').length > 0)
      document.getElementsByClassName('select-variants__list')[0].style.display = show
        ? 'block'
        : 'none';
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        box.current &&
        !box.current.contains(event.target) &&
        document.getElementsByClassName('select-variants__list').length > 0 &&
        document.getElementsByClassName('select-variants__list')[0].offsetParent !== null
      ) {
        toggle(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={box}>
      <ListVariants
        {...props}
        setVariant={(variant) => {
          setVariant(variant);
          toggle(false);
        }}
      >
        <div
          className={`${className || 'select-variants'}__item-current item`}
          onClick={() => toggle()}
        >
          {image && (
            <div className="item__image">
              <GatsbyImage image={getImage(image)} alt={image.altText || variant.title} />
            </div>
          )}
          <span className="item__name">{titleVariant}</span>
        </div>
      </ListVariants>
    </div>
  );
};

export default SelectVariant;
