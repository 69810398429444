import * as React from 'react'
import TextBlock from './TextBlock'
import PromotionSlider from './PromotionSlider';
import BrandsBlock from './BrandsBlock';
import MapBlock from './MapBlock';
import './style.scss'

const SliceZone = React.memo(({ sliceZone, ...props }) => {

    const sliceComponents = {
        text_block: TextBlock,
        promotions_slider: PromotionSlider,
        brands_block: BrandsBlock,
        map_block: MapBlock,
    }

    const sliceZoneContent = sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]

        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`} />
        }
        return null
    })

    return sliceZoneContent
})

export default SliceZone