import React, { memo } from 'react';

const HeaderPage = () => {
  return (
    <div className="cart__header">
      <div className="row align-items-center">
        <div className="col-md-12">
          <h3>Cart Summary</h3>
          <hr className="mr-auto" />
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderPage);
