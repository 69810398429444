import React, { memo } from 'react';
import loadable from '@loadable/component';

const ReactSlick = loadable(() => import('../common/ReactSlick'));

const PromotionsFilter = memo((props) => {
  const { promotions, setFilter, filter } = props;

  const settings = {
    arrows: true,
    dots: false,
    slidesToShow: 6,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="main__archive-product m-0">
      <div className="sub-categories m-0">
        <div className="row">
          <div className="offset-xl-1 col-xl-10 px-0">
            {
              <ReactSlick {...settings}>
                {promotions?.map(({ title, category: { document, uid } }) => (
                  <div key={uid}>
                    <div
                      onClick={() => setFilter(uid)}
                      className={`sub-categories__item my-0 ${filter === uid && 'active'}`}
                    >
                      {title || document?.data?.name}
                    </div>
                  </div>
                ))}
              </ReactSlick>
            }
          </div>
        </div>
      </div>
    </div>
  );
});

export default PromotionsFilter;
