import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/blog/Layout'));
const Archive = loadable(() => import('../../containers/blog/archive'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicBlogCategory: {
      data: { meta_title, meta_description, meta_keywords },
    },
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: meta_keywords || [],
    location,
  };

  return <Seo {...seo} />;
};

const ArchiveBlog = memo((props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const { prismicBlogCategory, allPrismicBlogPostNew } = data;
  const { h1_header, category_name, url, ...dataPage } = prismicBlogCategory?.data || {};
  const links = [{ label: category_name, slug: url }];

  return (
    <Layout links={links} title={category_name} location={location}>
      <h1 hidden>{h1_header}</h1>
      <Archive
        data={{ ...dataPage, name: category_name, cuid: prismicBlogCategory?.uid }}
        posts={allPrismicBlogPostNew}
      />
    </Layout>
  );
});

export default ArchiveBlog;

export const query = graphql`
  query categoriesBlogQuery($uid: String!, $skip: Int = 0, $limit: Int = 21) {
    prismicBlogCategory(uid: { eq: $uid }) {
      uid
      url
      data {
        category_name
        description {
          richText
        }
        h1_header
        image {
          gatsbyImageData
          alt
        }
        meta_description
        meta_title
      }
    }
    allPrismicBlogPostNew(
      filter: { data: { category_group: { elemMatch: { category: { uid: { eq: $uid } } } } } }
      sort: { fields: data___published_date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          data {
            body {
              ... on PrismicBlogPostNewDataBodyPostContentFromWpBlog {
                primary {
                  post_content
                }
              }
            }
            category_group {
              category {
                document {
                  ... on PrismicBlogCategory {
                    data {
                      category_name
                    }
                  }
                }
              }
            }
            featured_image {
              gatsbyImageData
              alt
            }
            post_name
            secondary_title
          }
          uid
          url
          tags
          first_publication_date(formatString: "dddd M yy")
        }
      }

      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;
