import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import { getModelUrl } from '../../utils/helpers';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/blog/Layout'));
const Archive = loadable(() => import('../../containers/blog/archive'));

export const Head = ({ location, params, data, pageContext }) => {
  const seo = {
    title: pageContext.author,
    description: pageContext.author,
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const AuthorsBlog = memo((props) => {
  const {
    data,
    errors,
    pageContext: { uid, author },
    uri,
    location,
  } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const { allPosts } = data;
  const links = [{ label: author, slug: uri }];

  return (
    <Layout links={links} location={location}>
      <Archive data={{ name: author }} posts={allPosts} link={getModelUrl('blog', uid)} />
    </Layout>
  );
});

export default AuthorsBlog;

export const query = graphql`
  query authorsBlogQuery($uid: String!, $skip: Int = 0, $limit: Int = 21) {
    allPosts: allPrismicBlogPostNew(
      filter: { data: { author: { uid: { eq: $uid } } } }
      sort: { fields: data___published_date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          data {
            body {
              ... on PrismicBlogPostNewDataBodyPostContentFromWpBlog {
                primary {
                  post_content
                }
              }
            }
            category_group {
              category {
                document {
                  ... on PrismicBlogCategory {
                    data {
                      category_name
                    }
                  }
                }
              }
            }
            featured_image {
              gatsbyImageData
              alt
            }
            post_name
            secondary_title
          }
          uid
          url
          tags
          first_publication_date(formatString: "dddd M yy")
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;
