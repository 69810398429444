import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { mapNodesToData } from './../utils/helpers';
import Seo from '../components/common/Seo';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const BrandsContainer = loadable(() => import('../components/brands_page'));

export const Head = ({ location, params, data, pageContext }) => {
  const { brandsPage } = data;

  const { meta_title, meta_description } = brandsPage?.data || {};
  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const BrandsPage = (props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const { brandsPage, allPrismicBrand } = data;

  const { meta_title, meta_description, ...page } = brandsPage?.data || {};

  return (
    <Layout location={location}>
      <BrandsContainer brands={mapNodesToData(allPrismicBrand)} page={page} />
    </Layout>
  );
};

export default BrandsPage;

export const query = graphql`
query BrandsPage {
  brandsPage: prismicBrandsPage {
    data {
      meta_title
      meta_description
      h1_header
      brands_directory_header {
        richText
      }
      featured_brands_header
      featured_brands {
        featured_brand {
          url
          uid
          type
          link_type
          document {
            ... on PrismicBrand {
              url
            }
          }
        }
        featured_brand_image {
          gatsbyImageData
          alt
        }
      }
    }
  }

  allPrismicBrand(
    sort: {fields: data___name, order: ASC}
    filter: {data: {url: {regex: "/^(brands\\/)([\\w-]*)$/"}}}
  ) {
    nodes {
      data {
        name
        url
      }
    }
  }
}
`;
