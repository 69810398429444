import styled from "styled-components"

const OpeningHoursWrapper = styled.div`
    text-align: center;
    padding-top: 15px;
    padding-bottom: 60px;
    p {
        margin-bottom: 30px;
        font-size: 13px;
    }
`

export const OpeningHoursTitle = styled.h3`
    font-size: 20px;
    margin-bottom: 30px;
`

export default OpeningHoursWrapper
