import loadable from '@loadable/component';
import React, { memo } from 'react';
import { useStore } from '../../hooks';
import { getToken } from '../../services/customer';

const Breadcrumb = loadable(() => import('../common/Breadcrumb'));

const HeaderPage = () => {
  const { email } = getToken();
  const { swymLoading } = useStore();

  return (
    <div className="wishlist__header">
      <div className="row align-items-center">
        <div className="col-md-6">
          <Breadcrumb current="Wishlist" />
        </div>

        <div className="col-md-6 text-right">
          <h6 className="mb-0">{!swymLoading && (email ?? 'Guest Shopper')}</h6>
        </div>
        <hr className="my-0 mr-auto" style={{ width: 'calc(100% - 30px)' }} />
      </div>
    </div>
  );
};

export default memo(HeaderPage);
