import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Seo from '../../components/common/Seo';

const SEO = loadable(() => import('../../components/common/Seo'));
const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/salon/Layout'));
const TreatmentsPage = loadable(() => import('../../containers/salon/treatments-page'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicSalonTreatmentsPage: {
      data: { meta_title, meta_description },
    },
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    location,
  };

  return <Seo {...seo} />;
};

const TreatmentsStaicPage = memo((props) => {
  const { data, errors } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const { allPrismicSalonTreatmentPages, prismicSalonTreatmentsPage } = data;
  const { meta_title, meta_description, h1_title, ...dataPage } =
    prismicSalonTreatmentsPage?.data || {};

  return (
    <Layout>
      <h1 hidden>{h1_title}</h1>

      <TreatmentsPage
        data={{ name: 'Treatments', ...dataPage }}
        treatments={allPrismicSalonTreatmentPages?.nodes || []}
      />
    </Layout>
  );
});

export default TreatmentsStaicPage;

export const query = graphql`
  {
    allPrismicSalonTreatmentPages(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        url
        data {
          title
          avatar {
            gatsbyImageData
            alt
          }
        }
      }
    }

    prismicSalonTreatmentsPage {
      data {
        meta_title
        meta_description
        h1_title
        opening_hours_title
        opening_hours_detail {
          richText
        }
        button_book_now
        button_link_book_now {
          url
          uid
          type
          link_type
        }
      }
    }
  }
`;
