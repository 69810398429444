import styled from 'styled-components';

const SiteTitleWrapper = styled.div`
  text-align: center;
`;

export const Title = styled.span`
  display: inline-block;
  font-size: 34px;
  margin: 0 auto 15px;

  @media (max-width: 991.98px) {
    font-size: 30px;
  }
`;

export default SiteTitleWrapper;
