import React, { memo, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { getVariantByProductId } from '../../services/productVariant';
import { formatPrice } from '../../utils/helpers';

const ProductPriceLiveMain = ({ product }) => {
  const [state, setState] = useState({ price: -1, compareAtPrice: null });

  useEffect(() => {
    const id = product?.admin_graphql_api_id || product?.shopifyId || product?.id;
    if (id) {
      getVariantByProductId(id).then((data) => {
        if (!data) return;
        const { price, compareAtPrice } = data || {};
        setState({ price: price?.amount, compareAtPrice: compareAtPrice?.amount || 0 });
      });
    }
  }, []);

  if (state.price < 0) return null;

  return (
    <p className="product--price">
      {formatPrice(state.price)}{' '}
      {state.compareAtPrice > 0 && state.compareAtPrice > state.price && (
        <del>{formatPrice(state.compareAtPrice)}</del>
      )}
    </p>
  );
};

const ProductPriceLive = (props) => (
  <LazyLoad throttle={500} height={50} scroll={true}>
    <ProductPriceLiveMain {...props} />
  </LazyLoad>
);

export default memo(ProductPriceLive);
