import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import './style.scss';
import LazyLoad from 'react-lazyload';

// const BlogAuthor = loadable(() => import('./BlogAuthor'))
const ContentFromWpBlog = loadable(() => import('./ContentFromWpBlog'));
const TextBlock = loadable(() => import('./TextBlock'));
const SingleShopifyProduct = loadable(() => import('./SingleShopifyProduct'));
const PromotionalBanner = loadable(() => import('./PromotionalBanner'));
const BlogTags = loadable(() => import('./BlogTags'));
const LatestPosts = loadable(() => import('./LatestPosts'));
const CustomLink = loadable(() => import('./../../../components/common/CustomLink'));
const Embed = loadable(() => import('./Embed'));

const SliceZone = React.memo(({ sliceZone, post, links, latestPosts, tags, ...props }) => {
  const {
    data: { post_name, author, category_group, published_date },
  } = post || {};

  const [authorName, setAuthorName] = useState(
    `${author?.document?.data?.name} - ${author?.document?.data?.position}`
  );

  const sliceComponents = {
    promotional_banner: PromotionalBanner,
    single_shopify_product: SingleShopifyProduct,
    text_block: TextBlock,
    post_content_from_wp_blog: ContentFromWpBlog,
    embed: Embed,
  };

  const sliceZoneContentLeft = sliceZone
    .map((slice, index) => {
      if (slice.slice_type !== 'single_shopify_product') {
        const SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
          const dataProps = {
            [`before__${slice.slice_type}__${index}`]:
              props[`before__${slice.slice_type}__${index}`],
            [`before__${slice.slice_type}`]: props[`before__${slice.slice_type}`],
            [`after__${slice.slice_type}__${index}`]: props[`after__${slice.slice_type}__${index}`],
            [`after__${slice.slice_type}`]: props[`after__${slice.slice_type}`],
            index,
          };

          return (
            <SliceComponent
              {...dataProps}
              slice={slice}
              key={`slice-${index}`}
              category_group={category_group}
            />
          );
        }
      }

      return null;
    })
    .filter((v) => v !== null);

  const sliceZoneContentRight = sliceZone
    .map((slice, index) => {
      if (slice.slice_type === 'single_shopify_product') {
        const SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
          const dataProps = {
            [`before__${slice.slice_type}__${index}`]:
              props[`before__${slice.slice_type}__${index}`],
            [`before__${slice.slice_type}`]: props[`before__${slice.slice_type}`],
            [`after__${slice.slice_type}__${index}`]: props[`after__${slice.slice_type}__${index}`],
            [`after__${slice.slice_type}`]: props[`after__${slice.slice_type}`],
            index,
          };

          return <SliceComponent {...dataProps} slice={slice} key={`slice-${index}`} />;
        }
        return null;
      }

      return null;
    })
    .filter((v) => v !== null);

  // useEffect(() => {
  //     const checkExistAuthor = setInterval(() => {
  //         if (typeof document !== "undefined") {
  //             if (document.getElementsByClassName('saboxplugin-wrap').length > 0) {
  //                 setAuthorName(document.getElementsByClassName('saboxplugin-authorname')[0].innerText)
  //             }
  //             clearInterval(checkExistAuthor)
  //         }
  //     }, 500)
  // }, []);

  return (
    <div className="container">
      <div className="row">
        <div
          className={`col-lg-9 ${
            sliceZoneContentRight.length <= 0 ? 'm-auto' : 'col-md-8 pr-lg-5 pr-md-3'
          }`}
        >
          <section className="post__header">
            <h1 className="post__header__title">{post_name}</h1>
            <p className="post__header__meta-author">
              {author?.uid && (
                <span>
                  written by: <CustomLink link={author} className="author" text={authorName} />
                </span>
              )}
              <span className={`published-date ${author?.uid ? 'ml-3' : ''}`}>
                published on {published_date}
              </span>
            </p>
          </section>
        </div>
        {sliceZoneContentRight.length > 0 && (
          <div className="col-lg-3 col-md-4 pl-lg-5 pl-md-3"></div>
        )}
      </div>

      <div className="row">
        <div
          className={`col-lg-9 ${
            sliceZoneContentRight.length <= 0 ? 'm-auto' : 'col-md-8 pr-lg-5 pr-md-3'
          }`}
        >
          {sliceZoneContentLeft}
          {/* <BlogAuthor author={author} /> */}
        </div>
        {sliceZoneContentRight.length > 0 && (
          <div className="col-lg-3 col-md-4 pl-lg-5 pl-md-3">{sliceZoneContentRight}</div>
        )}
      </div>

      <LazyLoad throttle={500} height={500} scroll={true}>
        <div className="row">
          <div
            className={`col-lg-9 ${
              sliceZoneContentRight.length <= 0 ? 'm-auto' : 'col-md-8 pr-lg-5 pr-md-3'
            }`}
          >
            <BlogTags tags={tags} />
            <LatestPosts posts={latestPosts} />
          </div>
          {sliceZoneContentRight.length > 0 && (
            <div className="col-lg-3 col-md-4 pl-lg-5 pl-md-3"></div>
          )}
        </div>
      </LazyLoad>
    </div>
  );
});

export default SliceZone;
