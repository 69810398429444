import * as React from 'react';
import { Pagination as PaginationWrapper, PaginationItem } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { navigate } from 'gatsby';

type PaginationProps = {
  link?: string;
  currentPage: number;
  totalPage: number;
  className?: string;
};

const Pagination: React.FunctionComponent<PaginationProps> = ({
  link,
  currentPage,
  totalPage,
  className,
  ...props
}) => {

  const handleChangePage = (event, value) => {
    navigate(`${link}/page/${value}`)
  };

  return (
    <PaginationWrapper
      hidePrevButton={currentPage === 1}
      hideNextButton={currentPage >= totalPage}
      page={currentPage}
      count={totalPage}
      variant="outlined"
      shape="rounded"
      siblingCount={1}
      boundaryCount={0}
      onChange={handleChangePage}
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: KeyboardDoubleArrowLeftIcon, next: KeyboardDoubleArrowRightIcon }}
          {...item}
        />
      )}
    />
  );
};

export default React.memo(Pagination);
