import { storeFontApi, accessToken } from '../constants/shopify';

/**
 *
 * @param {string} productId
 * @param {number} first
 * @returns {boolean | {productType: string, vendor: string, variant: object, collections: Array}}
 */
export async function getProductById(productId, first = 1) {
  const query = `{
    product(id: "${productId}") {
      id
      productType
      vendor
      variants(first: ${first}) {
        nodes {
          id
          quantityAvailable
          compareAtPrice {
              amount
          }
          price {
              amount
          }
        }
      }
      
      collections (first: 50) {
        nodes {
            title
        }
      }
    }
  }`;

  const options = {
    method: 'post',
    headers: {
      'Content-type': 'application/graphql',
      'X-Shopify-Storefront-Access-Token': accessToken,
    },
    body: query,
  };

  return fetch(storeFontApi, options)
    .then((res) => res.json())
    .then((result) => {
      if (!result.data?.product) return false;

      return {
        productType: result.data?.product?.productType,
        vendor: result.data?.product?.vendor,
        variant: result.data?.product?.variants?.nodes[0] || {},
        collections: result.data?.product?.collections?.nodes || [],
      };
    });
}

/**
 *
 * @param {string} productId
 * @param {number} first
 * @returns object | Array
 */
export async function getVariantByProductId(productId, first = 1, hasImages = false) {
  const images = hasImages
    ? `images(first: 250, sortKey: POSITION) {
        nodes {
          id
          altText
          src
        }
      }`
    : '';

  const query = `{
    product(id: "${productId}") {
      no_dispatch: metafield(namespace: "detail", key: "no_dispatch") {
        value
      }
      purchase_method: metafield(namespace: "detail", key: "purchase_method") {
        value
      }
      hide_price: metafield(namespace: "price", key: "hide_price") {
        value
      }
      variants(first: ${first}) {
        nodes {
          id
          quantityAvailable
          compareAtPrice {
              amount
          }
          price {
              amount
          }
        }
      }
      ${images}
    }
  }`;

  const options = {
    method: 'post',
    headers: {
      'Content-type': 'application/graphql',
      'X-Shopify-Storefront-Access-Token': accessToken,
    },
    body: query,
  };

  return fetch(storeFontApi, options)
    .then((res) => res.json())
    .then((result) => {
      if (!result.data?.product) return false;
      else if (first === 1)
        return (
          {
            ...result.data?.product?.variants?.nodes[0],
            product: {
              no_dispatch: result.data?.product?.no_dispatch,
              purchase_method: result.data?.product?.purchase_method,
              hide_price: result.data?.product?.hide_price,
              images: result.data?.product?.images?.nodes || []
            },
          } || {}
        );
      else
        return (
          result.data?.product?.variants?.nodes?.map((v) => ({
            ...v,
            product: {
              no_dispatch: result.data?.product?.no_dispatch,
              purchase_method: result.data?.product?.purchase_method,
              hide_price: result.data?.product?.hide_price,
            },
          })) || []
        );
    });
}

/**
 *
 * @param {string} productVariantId
 * @param {boolean} video
 * @returns
 */
export const getVariantById = async (productVariantId, video = false) => {
  return await fetch(`/api/fetch-product-variant`, {
    method: `POST`,
    body: JSON.stringify({ productVariantId, video }),
    headers: {
      'content-type': `application/json`,
    },
  })
    .then((result) => result.json())
    .then((result) => result.data);
};

/**
 * reuqest product password
 *
 * @param {*} data
 * @returns
 */
export const requestProductPassword = async (data) => {
  return await fetch(`/api/rq-product-password`, {
    method: `POST`,
    body: JSON.stringify(data),
    headers: {
      'content-type': `application/json`,
    },
  }).then((result) => result.json());
};

export async function getProductImages(productId) {
  const query = `{
    product(id: "${productId}") {
      images(first: 250, sortKey: POSITION) {
        nodes {
          id
          altText
          src
        }
      }
    }
  }`;

  const options = {
    method: 'post',
    headers: {
      'Content-type': 'application/graphql',
      'X-Shopify-Storefront-Access-Token': accessToken,
    },
    body: query,
  };

  return fetch(storeFontApi, options)
    .then((res) => res.json())
    .then((result) => {
      if (!result.data?.product) return false;
      return result.data?.product?.images?.nodes;
    });
}
