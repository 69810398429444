import React, { memo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { checkExpried } from '../../utils/helpers';

const BannerActiveskin = memo(
  ({
    background_colour,
    banner_activeskin,
    theme,
    heading,
    sub_heading,
    start_date,
    end_date,
    logo,
  }) => {
    if (checkExpried(start_date, end_date)) return null;

    return (
      <div
        className={`archive-product__banner-activeskin banner-activeskin ${theme}`}
        style={{ backgroundColor: background_colour }}
      >
        <div className="banner-activeskin__image">
          {banner_activeskin?.gatsbyImageData && (
            <GatsbyImage
              image={banner_activeskin?.gatsbyImageData}
              loading="eager"
              alt={banner_activeskin?.alt || 'activeskin'}
            />
          )}
        </div>

        <div className="banner-activeskin__content">
          {logo?.gatsbyImageData && (
            <div className="banner-activeskin__logo">
              <GatsbyImage
                image={logo?.gatsbyImageData}
                loading="eager"
                alt={logo?.alt || 'activeskin'}
              />
            </div>
          )}
          <div className='banner-activeskin__text'>
            {heading && <div className="banner-activeskin__heading">{heading}</div>}
            {sub_heading && <div className="banner-activeskin__sub-heading">{sub_heading}</div>}
          </div>
        </div>
      </div>
    );
  }
);

export default BannerActiveskin;
