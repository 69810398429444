import React from 'react';

const ArrowRight = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 68 34"
    style={{ enableBackground: 'new 0 0 68 34' }}
    xmlSpace="preserve"
  >
    <style type="text/css" dangerouslySetInnerHTML={{ __html: '\n   .st0{fill:#333333;}\n' }} />
    <g>
      <g>
        <path
          className="st0"
          d="M67.4,16L67.4,16L52,0.6c-0.6-0.6-1.5-0.7-2.2-0.2c-0.6,0.6-0.7,1.5-0.2,2.2c0.1,0.1,0.1,0.1,0.2,0.2
		   l12.8,12.8H1.7c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h60.9L49.8,31.3c-0.6,0.6-0.7,1.5-0.2,2.2c0.6,0.6,1.5,0.7,2.2,0.2
		   c0.1-0.1,0.1-0.1,0.2-0.2l15.4-15.4C68,17.5,68,16.6,67.4,16z"
        />
      </g>
    </g>
  </svg>
);

export default ArrowRight;
