import { useStaticQuery, graphql } from 'gatsby';

export const useBrandsPassword = () => {
  const { allPrismicBrand } = useStaticQuery(query);
  const brands = {};
  allPrismicBrand?.nodes?.forEach(({ data }) => {
    brands[data?.name] = data?.password;
  });

  return { brands };
};

export default useBrandsPassword;

const query = graphql`
  query {
    allPrismicBrand(filter: { data: { password: { ne: null } } }) {
      nodes {
        data {
          name
          password
        }
      }
    }
  }
`;
