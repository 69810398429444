const api = process.env.GATSBY_SHOPIFY_API;
const storeUrl = process.env.GATSBY_SHOPIFY_STORE_URL;
const storeFontApi = `https://${storeUrl}/api/${api}/graphql.json`;
const multypassSecret = process.env.SHOPIFY_STORE_MULTIPASS_SECRET;

const domainCheckout = process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT;
const accessToken = process.env.GATSBY_SHOPIFY_STOREFONT_ACCESS_TOKEN;
const apiKey = process.env.SHOPIFY_ADMIN_KEY;
const adminPassword = process.env.SHOPIFY_ADMIN_PASSWORD;

module.exports = {
  api,
  storeUrl,
  storeFontApi,
  multypassSecret,
  domainCheckout,
  accessToken,
  apiKey,
  adminPassword,
};
