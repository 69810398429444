import React, { useState } from 'react'
import SlideDown from 'react-slidedown'
import { validateEmail } from '../../utils/helpers';

const NotifyMe = ({ product, variant }) => {

    const [state, setState] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const handleSendEmail = (e) => {
        if (e.type == "keypress" && e.key === "Enter" || e.type == 'click') {
            event.preventDefault();
            if (!validateEmail(email)) {
                setError("Invalid email, please try again.")
                return;
            };

            fetch(`/api/swym`, {
                method: `POST`,
                body: JSON.stringify({ type: "subscribe-alert-product-in-stock", email, product, variant }),
                headers: {
                    "content-type": `application/json`,
                },
            })
                .then(result => result.json())
                .then(res => {
                    console.log({ res })
                    setState(false);
                    setSuccess(true);
                }).catch(error => {
                    console.log({ error })
                })
            event.stopPropagation();
        }
    }

    return (
        <>
            <button
                type="button"
                className="button btn-cart btn-activeskin active"
                onClick={() => setState(true)}
            >
                Notify Me
            </button>
            <div className="clearfix" />
            <SlideDown>
                {state && !success && <div style={{ paddingTop: "20px" }}>
                    <form onSubmit={() => event.preventDefault()} method="POST" action="/api/swym" className='form-notify-me'>
                        <label className='form-notify-me__label'>Enter your email below to be notified as soon as this item is available to order.</label>
                        <div className='form-notify-me__inner'>
                            
                            <input onKeyPress={handleSendEmail} className='form-notify-me__input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='email address' />
                            <button onClick={handleSendEmail} className='form-notify-me__button btn-activeskin primary' type='button' >SEND</button>
                        </div>
                        {error && <div className='form-notify-me__response text-danger'>
                            <small>{error}</small>
                        </div>}

                    </form>
                </div>}
                {success && 
                <div style={{ paddingTop: "20px" }}>
                        <div className='form-notify-me'>
                            <div className='form-notify-me__inner'>
                                <span className="form-notify-me__span">Got it! We will notify you once this product is back in stock.</span>
                                <button disabled="disabled" className='form-notify-me__button btn-activeskin primary' type='button' >SENT</button>
                            </div>
                        </div>
                </div>
                
                }
            </SlideDown>
        </>
    )
}

export default React.memo(NotifyMe)