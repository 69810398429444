import React, { useState } from 'react';
import { MenuItem } from './navbar.style';
import loadable from '@loadable/component';

const CustomLink = loadable(() => import('../../common/CustomLink'))

const Menu: React.FunctionComponent<any> = React.memo(({
    className,
    link,
    text,
    children,
    ...props
}) => {

    const [state, setState] = useState(false);

    const handleAddClassOpen = () => {
        if (typeof window !== `undefined`) {
            setState(!state);
        }
    }

    return (
        <MenuItem className={`${className || ""} ${state ? "open" : ""}`}>
            <CustomLink className="menu-item__link" link={link} text={text} onClick={handleAddClassOpen} />
            {children}
        </MenuItem>
    )
})

export default Menu;
