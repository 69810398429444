import React, { memo } from 'react';
import useSiteMetadata from '../../hooks/use-site-metadata';

const Seo = memo(
  ({
    title,
    description,
    keywords = [],
    image,
    author,
    location,
    hideScript = false,
    children,
    ...props
  }) => {
    const site = useSiteMetadata();

    const seo = {
      title: title || site.title,
      description: description || site.description,
      url: `${site.siteUrl}${location.pathname}`,
      image: `${site.siteUrl}/assets/images/logo.jpg`,
    };

    return (
      <>
        <title>{seo.title}</title>
        <link rel="canonical" href={seo.url} />
        <meta name="description" content={seo.description} />
        {keywords.length > 0 && <meta name="keywords" content={keywords.join(', ')} />}
        <meta name="image" content={seo.image} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:type" content="website" />
        <meta property="og:image:alt" content={seo.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:image:alt" content={seo.description} />
        <meta name="twitter:creator" content={author?.name || 'Activeskin'} />
        <meta name="gatsby-theme" content="Activeskin Theme" />
        <meta name="oke:subscriber_id" content={process.env.GATSBY_OKENDO_TOKEN} />
        <meta
          name="google-site-verification"
          content="7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0"
        />
        <meta
          name="google-site-verification"
          content="7f2mtcG3Wihd8leMuNztRaB-E4XQMuDDGVICpA8iFG8"
        />

        {!hideScript && (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'NewsArticle',
              mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': seo?.url,
              },
              headline: seo?.title,
              image: [(image || seo.image).slice(0, (image || seo.image).indexOf('?'))],
              datePublished: props?.datePublished || new Date().toLocaleDateString('en-AU'),
              dateModified: props?.dateModified || new Date().toLocaleDateString('en-AU'),
              author: {
                '@type': 'Person',
                name: author?.name || 'Activeskin',
                url: `${site.siteUrl}${author?.url}` || seo.url,
              },
              publisher: {
                '@type': 'Organization',
                name: 'Activeskin',
                logo: {
                  '@type': 'ImageObject',
                  url: seo.image,
                },
              },
            })}
          </script>
        )}
        {children}
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-1043118985');`}
        </script>
      </>
    );
  }
);

export default Seo;
