import React, { memo, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import './style.scss';

const PromotionItem = loadable(() => import('./PromotionItem'));

const PromotionBlock = memo(({ slice, expired, promotions }) => {
  const {
    primary: { title, category, sort, is_feature },
    slice_type,
  } = slice;

  const items = promotions?.[category?.uid] || [];
  if (items?.length <= 0 && !is_feature) return null;

  const [state, setState] = useState([]);

  useEffect(() => {
    if (expired) {
      let blocks = items;

      if (is_feature) {
        blocks = Object.values(promotions)
          ?.flat()
          ?.filter((v) => v?.feature);
      }

      if (expired?.length) blocks = items.filter((item) => !expired.includes(item.text));

      if (!sort || sort === 'A-Z') {
        blocks = blocks.sort((a, b) => {
          const aName = a.brand?.document ? a.brand?.document?.data?.name.toUpperCase() : '';
          const bName = b.brand?.document ? b.brand?.document?.data?.name.toUpperCase() : '';
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });
      }

      setState(blocks);
    }
  }, [promotions, expired, is_feature]);

  if (state?.length < 1) return null;

  return (
    <section className={`${slice_type}`}>
      {(title || category?.document?.data?.name) && (
        <div className={`${slice_type}__title -title`}>
          <h3>{title || `${category?.document?.data?.name} Promotions`} </h3>
        </div>
      )}

      <div className={`${slice_type}__inner`}>
        <div className="row">
          {state.map((promotion, i) => (
            <PromotionItem key={i} {...promotion} slice_type={slice_type} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default PromotionBlock;

export const query = graphql`
  fragment PromotionsPageDataBodyPromotionsBlock on PrismicPromotionsPageDataBodyPromotionsBlock {
    primary {
      title
      sort
      is_feature
      category {
        uid
        document {
          ... on PrismicCategory {
            data {
              name
            }
          }
        }
      }
    }
  }
`;
