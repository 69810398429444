import { graphql, useStaticQuery } from 'gatsby';
import React, { memo } from 'react';

const StockStatus = memo((props) => {
  const { availableQty, quantity, no_dispatch, inventoryPolicy, setStockStatus } = props;
  const {
    prismicStoreConfiguration: { data },
  } = useStaticQuery(query);
  const { accordion_stock_status } = data;

  if (availableQty > 0) {
    setStockStatus('Ready to dispatch');
    return (
      <AccordianStatus
        status="In stock"
        text={
          accordion_stock_status.find((v) => v.status.toLowerCase() === 'in stock')?.display_text ||
          ''
        }
        text2={
          accordion_stock_status.find((v) => v.status.toLowerCase() === '3 - 5 business days')
            ?.display_text || ''
        }
        inventoryPolicy={inventoryPolicy}
        stock={availableQty}
        qty={quantity}
        setStockStatus={setStockStatus}
      />
    );
  } else {
    if (!inventoryPolicy) {
      return <div className="section-dispatch out-of-stock">Out of stock</div>;
    } else if (no_dispatch) {
      if (no_dispatch.indexOf('business days') !== -1) {
        setStockStatus(`Dispatches in ${no_dispatch}`);
        return (
          <AccordianStatus
            status={`Dispatches in ${no_dispatch}`}
            inventoryPolicy={inventoryPolicy}
            text2={
              accordion_stock_status.find(
                (v) =>
                  v.status.toLowerCase() === no_dispatch ||
                  v.status.toLowerCase() === no_dispatch.toLowerCase() ||
                  v.status === no_dispatch
              )?.display_text || ''
            }
          />
        );
      } else {
        setStockStatus(no_dispatch);
        return <div className="section-dispatch no-dispatch">{no_dispatch}</div>;
      }
    }

    setStockStatus('Dispatches in 3 - 5 business days');
    return (
      <AccordianStatus
        status="Dispatches in 3 - 5 Business Days"
        inventoryPolicy={inventoryPolicy}
        text2={
          accordion_stock_status.find((v) => v.status.toLowerCase() === '3 - 5 business days')
            ?.display_text || ''
        }
      />
    );
  }
});

export default StockStatus;

const AccordianStatus = memo(
  ({ status, text, text2, stock, inventoryPolicy, qty, setStockStatus }) => {
    const inStock = stock && stock >= qty;
    let flag = 1;
    let style = {};

    if (!inStock) {
      if (inventoryPolicy) {
        flag = 2;
      } else {
        flag = 0;
        style.color = '#FA8D7C';
      }
    }

    if (stock && flag === 2) {
      setStockStatus('Dispatches in 3 - 5 business days');
    }

    return (
      <div className="section-dispatch">
        <p className="d-block mb-0">
          {(!stock || !flag || flag == 1) && (
            <React.Fragment>
              {status}{' '}
              {stock && (
                <small className={`text-qty ml-4`} style={style}>
                  {inStock ? (
                    <i>
                      {stock >= 10
                        ? '10+ in stock'
                        : `${stock <= 3 ? `Only ${stock}` : stock} in stock`}
                    </i>
                  ) : (
                    `Only ${stock} in stock`
                  )}
                </small>
              )}
            </React.Fragment>
          )}

          {stock && flag === 2 && (
            <React.Fragment>Dispatches in 3 - 5 Business Days</React.Fragment>
          )}
        </p>

        {flag > 0 && (
          <p style={{ fontSize: '14px' }} className="mb-0 pt-2">
            {flag === 1 ? text : text2}
          </p>
        )}
      </div>
    );
  }
);

const query = graphql`
  {
    prismicStoreConfiguration {
      data {
        accordion_stock_status {
          status
          display_text
        }
      }
    }
  }
`;
