import { getCookie, loaded, setCookie } from './helpers';

export function getPwBrands(key = '') {
  if (!loaded) return {};
  let brands = JSON.parse(localStorage?.getItem('gatsby-pw-brands') || '{}');

  if (key) return brands?.[key];

  return brands;
}

export function setPwBrands(brand, password) {
  if (!loaded) return {};
  let brands = getPwBrands();
  brands[brand] = password;

  localStorage?.setItem('gatsby-pw-brands', JSON.stringify(brands));
  return brands;
}

export function hasPwBrand(brand, _password) {
  if (!loaded) return false;
  let password = getPwBrands(brand);
  return password && password === _password;
}
