import React, { createContext, useState } from 'react';
import { loaded } from './../utils/helpers';
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_INDEX_PREFIX } from '../constants/algolia';

const isMobile = loaded && window.innerWidth < 992;

const initValuesContext = {
  searching: isMobile,
  query: false,
  indexName: `${ALGOLIA_INDEX_PREFIX}activeskin`,
};

const _algoliasearch = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

export const SearchContext = createContext(initValuesContext);

const SearchProvider = ({ children }) => {
  const [state, setState] = useState(initValuesContext);

  const searchClient = {
    search(requests) {
      if (
        requests.every(({ params }) => !params.query) ||
        requests.every(({ params }) => params.query.trim() === '')
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            facets: {},
            hitsPerPage: 3,
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return _algoliasearch.search(requests);
    },
  };

  return (
    <SearchContext.Provider
      value={{
        ...state,
        setState,
      }}
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={state.indexName}
        onSearchStateChange={({ query }) => {
          if (query && query?.trim() !== '') {
            if (!state.query) setState((s) => ({ ...s, query: true }));
          } else if (state.query) setState((s) => ({ ...s, query: false }));
        }}
      >
        {children}
      </InstantSearch>
    </SearchContext.Provider>
  );
};
export default SearchProvider;
