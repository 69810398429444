import * as React from "react"
import loadable from "@loadable/component";

const Treatments = loadable(() => import("./treatments"))
const TreatmentsPageWrapper = loadable(() => import("./style"))
const OpeningHours = loadable(() => import('./opening-hours'))
const Breadcrumb = loadable(() => import('./Breadcrumb'))

type TreatmentsPageProps = {
  treatments?: []
  data?: {
    name?: string
    opening_hours_detail?: any
    opening_hours_title?: string
    button_book_now?: string
    button_link_book_now?: any
  }
}

const TreatmentsPage: React.FunctionComponent<TreatmentsPageProps> = React.memo(({ data: { name, ...props }, treatments }) => {

  return (
    <TreatmentsPageWrapper>
      <div className="container">
        <Breadcrumb />
      </div>

      <Treatments title={name || ""} treatments={treatments} />
      <OpeningHours {...props} />
    </TreatmentsPageWrapper>
  )
})

export default TreatmentsPage
