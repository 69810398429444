import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../../utils/helpers'
import CustomLink from './../../../components/common/CustomLink';

const TextBlock = memo(({ slice }) => {

  const { primary: { title, content, button_text, button_link }, slice_type } = slice

  return (
    <section className={slice_type}>
      <h3 className="title">{title}</h3>
      <div className="content">
        <RichText render={content.richText} serializeHyperlink={serializeHyperlink} />
      </div>
      {button_text && <CustomLink className='btn-activeskin' link={button_link} text={button_text} />}
    </section>
  )
})

export default TextBlock

export const query = graphql`
  fragment SalonTreatmentPagesDataBodyTextBlock on PrismicSalonTreatmentPagesDataBodyTextBlock {
    primary {
      title
      content {
        richText
      }
      button_text
      button_link {
        url
        uid
        type
        link_type
      }
    }
  }
`