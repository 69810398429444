import React, { memo, useEffect, useState, useRef } from 'react';
// import { StringParam, useQueryParams, withDefault, NumberParam } from 'use-query-params';
import loadable from '@loadable/component';
import GatsbyImage from '../common/GatsbyImage';

const SliceZone = loadable(() => import('./SliceZone'));

const StaticPage = memo(({ page }) => {
  // const [dataForm, setDataForm] = useState({ first_name: '', email: '' });
  const box = useRef(null);

  // const [params] = useQueryParams({
  //   pageNewsletter: withDefault(NumberParam, 0),
  //   result: withDefault(StringParam, ''),
  // });

  const { uid, type, data } = page;

  const {
    banner_image,
    h1_title,
    embed_newsletter_signup,
    thead,
    body,
    text_before_table,
    text_after_table,
    body1,
  } = data;

  useEffect(() => {
    if (embed_newsletter_signup && box?.current) {
      const script = document.createElement('script');

      script.src =
        '//mail.activeskin.com.au/resources/sharing/embed.js?sharing=lp-embed&domain=mail.activeskin.com.au&id=5RQP-87A%2Falist-signup%22';
      box?.current?.appendChild(script);
    }
  }, [embed_newsletter_signup, box]);

  return (
    <div id={uid} className={`page ${type} page-${uid}`}>
      <div className="page__banner container-xl">
        {banner_image?.gatsbyImageData && (
          <div className="page__banner__image d-none d-md-block">
            <GatsbyImage image={banner_image?.gatsbyImageData} alt={banner_image.alt || h1_title} />
          </div>
        )}
        {banner_image?.thumbnails?.mobile?.gatsbyImageData && (
          <div className="page__banner__image d-md-none --mobile">
            <GatsbyImage
              image={banner_image?.thumbnails?.mobile?.gatsbyImageData}
              alt={banner_image?.thumbnails?.mobile?.alt || h1_title}
            />
          </div>
        )}

        <div className="page__banner__title">
          <h1>{h1_title}</h1>
        </div>
      </div>

      <SliceZone
        uid={uid}
        sliceZone={body1}
        thead={thead}
        body={body}
        text_before_table={text_before_table}
        text_after_table={text_after_table}
      />

      <div ref={box}></div>
    </div>
  );
});

export default StaticPage;
