import React, { memo } from 'react';
import { Link } from 'gatsby';

function CartEmpty({ handleCartClose }) {

    return (
        <>
            <header className="Cart__header">
                <h6 className='mb-0 text-uppercase'>Your cart</h6>
                <button onClick={handleCartClose} className="Cart__close mr-0 pr-0">
                    ×
                </button>
            </header>

            <div className='Cart__empty'>
                <div className='Cart__empty__title -title'>
                    <h3>Your Bag Is Empty! <br /> Let's Fix That</h3>
                </div>

                <div className='Cart__empty__btns'>
                    <div>
                        <button className='btn-activeskin'>
                            <Link to="/new-arrivals">Shop New Arrivals</Link>
                        </button>
                    </div>
                    <div>
                        <button className='btn-activeskin'>
                            <Link to="/skincare">Shop Skincare</Link>
                        </button>
                    </div>
                    <div>
                        <button className='btn-activeskin'>
                            <Link to="/makeup">Shop Makeup</Link>
                        </button>
                    </div>
                    <div>
                        <button className='btn-activeskin'>
                            <Link to="/best-sellers">Shop Best Sellers</Link>
                        </button>
                    </div>
                </div>

            </div>
        </>
    );
}

export default memo(CartEmpty);
