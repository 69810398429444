import React, { useState, memo, useEffect } from 'react';
import { ArrayParam, withDefault } from 'use-query-params';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import {
  checkExpried,
  configOkeReview,
  formatNameSubBrand,
  loaded,
  textCapitalize,
} from '../../utils/helpers';
import { useStore } from '../../hooks';

const Sidebar = loadable(() => import('./Sidebar'));
const Breadcrumb = loadable(() => import('../archive-product/Breadcrumb'));
const SortBy = loadable(() => import('../archive-product/SortBy'));
const GridProducts = loadable(() => import('../archive-product/GridProducts'));
const FilterCollapse = loadable(() => import('../archive-product/FilterCollapse'));
const Desciption = loadable(() => import('../archive-product/Description'));
const PromoImage = loadable(() => import('../archive-product/PromoImage'));
const Pagination = loadable(() => import('../archive-product/Pagination'));
const CategoryImage = loadable(() => import('../archive-product/CategoryImage'));
const BreadcrumbBrand = loadable(() => import('../archive-product/BreadcrumnBrand'));
const SlidebarScrollmagic = loadable(() => import('../archive-product/SidebarScrollmagic'));
const SubCategorySlider = loadable(() => import('../archive-product/SubCategorySlider'));
const BannerActiveskin = loadable(() => import('../archive-product/BannerActiveskin'));

const ArchiveProduct = memo((props) => {
  const { dataFilter, siteConfig } = useStore();

  const {
    promotion,
    state: { dataPage, products, loading },
    customOrderBy,
    hideSortby = [],
  } = props;

  const { data = {} } = dataPage;

  const { filter_group } = dataFilter?.data || {};
  const { products_per_page } = siteConfig?.data || {};
  const _promotion =
    data?.promotion_image && data?.promotion_image?.gatsbyImageData ? data : promotion;

  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const [sortBy, setSortBy] = useState(customOrderBy || ['best_sellers', 'Best Sellers']);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginate, setPaginate] = useState({ total: 0, per_page: 0 });
  const [newProducts, setNewProducts] = useState(products);
  const [dataFilters, setDataFilters] = useState([]);
  const [initQuery, setInitQuery] = useState({});

  useEffect(() => {
    configOkeReview();
  }, []);

  useEffect(() => {
    const sortText = (a, b) => {
      const _a = textCapitalize(a, false);
      const _b = textCapitalize(b, false);
      if (_a > _b) return 1;
      else if (_a < _b) return -1;
      else return 0;
    };

    if (Object.keys(dataFilter).length > 0) {
      const brands = [];
      const _initQuery = {};
      const _dataFilters =
        filter_group?.map((v, _index) => {
          let metafield_value = {};
          const { metafield_key } = v;

          _initQuery[metafield_key] = withDefault(ArrayParam, []);

          let _metafields;

          if (metafield_key == 'brand' || metafield_key == 'price') {
            _metafields = [];
          } else {
            _metafields = products
              .map((product) => {
                if (_index === 0) brands.push(product.vendor);

                return product.metafields.filter((metafield) => metafield.key === metafield_key);
              })
              .filter((metafield) => metafield.length > 0)
              .map((metafields) =>
                metafields.map((metafield) => {
                  try {
                    return JSON.parse(metafield.value || '[]').join('|');
                  } catch (error) {
                    // console.log(error);
                    // console.log(metafield);
                    return metafield;
                  }
                })
              )
              .join('|')
              .split('|')
              .filter((metafield) => metafield.trim() != '');
          }

          _metafields.sort(sortText);

          for (let i = 0; i < _metafields.length; i++) {
            const key = _metafields[i];
            metafield_value[key] = metafield_value[key] ? metafield_value[key] + 1 : 1;
          }

          return {
            ...v,
            metafield_value: Object.keys(metafield_value),
            total: Object.values(metafield_value),
          };
        }) || [];

      const _dataBrandsFilter = {};

      _initQuery['brands'] = withDefault(ArrayParam, []);

      brands.sort(sortText);

      for (let i = 0; i < brands.length; i++) {
        const key = brands[i];
        _dataBrandsFilter[key] = _dataBrandsFilter[key] ? _dataBrandsFilter[key] + 1 : 1;
      }

      setDataFilters(
        _dataFilters.map((v) => {
          if (v.metafield_key == 'brand') {
            const metafield_value = Object.keys(_dataBrandsFilter);
            if (metafield_value?.length === 1 && metafield_value[0] === data?.name) {
              v.metafield_value = [];
              v.total = 0;
            } else {
              v.metafield_value = Object.keys(_dataBrandsFilter);
              v.total = Object.values(_dataBrandsFilter);
            }
          }
          return v;
        })
      );

      setInitQuery(_initQuery);
    }
  }, [filter_group]);

  useEffect(() => {
    let perPage = products_per_page;
    // if (!checkExpried(_promotion.p_start_date, _promotion.p_end_date)) {
    //   perPage =
    //     typeof window !== 'undefined' && window.innerWidth < 992
    //       ? _promotion?.promotion_image && _promotion?.promotion_image?.gatsbyImageData
    //         ? products_per_page + 1
    //         : products_per_page
    //       : _promotion?.promotion_image && _promotion?.promotion_image?.gatsbyImageData
    //       ? products_per_page + 3
    //       : products_per_page;
    // }

    const totalPage = Math.ceil(newProducts.length / perPage);

    setPaginate({ total: totalPage, per_page: perPage });
  }, [newProducts]);

  return (
    <div className="main__archive-product archive-product page container-xl">
      {data?.name &&
        (data?.parent_categories ? (
          <Breadcrumb
            className="d-block d-lg-none"
            current={data?.name}
            uid={data?.url}
            seo={true}
            categories={data?.parent_categories || []}
          />
        ) : (
          <BreadcrumbBrand
            className="d-block d-lg-none"
            current={data?.name}
            uid={data?.url}
            seo={true}
            categories={data?.parent_brand || []}
          />
        ))}

      <div className="row">
        <div className="col-md-12 px-0 px-lg-3">
          {data?.display_activeskin
            ? data?.banner_activeskin &&
              data?.banner_activeskin?.gatsbyImageData && <BannerActiveskin {...data} />
            : data?.display &&
              data?.banner &&
              data?.banner?.gatsbyImageData && (
                <CategoryImage link={data?.link} image={data?.banner} />
              )}
        </div>
      </div>

      <div className="row d-block d-lg-none">
        <div className="col-md-12 text-center">
          <div className="title">
            <h2>{data?.h1_title}</h2>
          </div>
        </div>
      </div>

      <div className="row align-items-center toolbar-pc mx-0">
        <div className="col-4 pl-0">
          {data?.parent_categories ? (
            <Breadcrumb
              className="d-none d-lg-block"
              current={data?.name}
              uid={data?.url}
              categories={data?.parent_categories || []}
            />
          ) : (
            <BreadcrumbBrand
              className="d-none d-lg-block"
              current={data?.name}
              uid={data?.url}
              categories={data?.parent_brand || []}
            />
          )}
        </div>

        <div className="col-4">
          {!loading && (
            <div className="total-product text-center">{newProducts.length} products found</div>
          )}
        </div>

        <div className="col-4 pr-0">
          <SortBy
            state={sortBy}
            default_sort={data?.default_sort}
            setState={setSortBy}
            customOrderBy={customOrderBy}
            hideSortby={hideSortby}
          />
        </div>
      </div>

      <div className="row align-items-center toolbar">
        <div className="col-6">
          <div className="title">
            <h1>{data?.h1_title}</h1>
          </div>
        </div>

        {/* <div className="col-6">
          {paginate.total > 1 && (
            <div className="pagination justify-content-end">
              <Pagination
                paginate={paginate}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div> */}
      </div>
      {/* {dataFilters?.length > 0 && ( */}
      <div className="row">
        <aside id="sidebar" className="col-xl-2 col-lg-3">
          <SlidebarScrollmagic>
            <div id="sidebar-content">
              <div id="sidebar-breadcrumb" className="d-none">
                {data?.parent_categories ? (
                  <Breadcrumb
                    className="d-none d-lg-block"
                    current={data?.name}
                    uid={data?.url}
                    categories={data?.parent_categories}
                  />
                ) : (
                  <BreadcrumbBrand
                    className="d-none d-lg-block"
                    current={data?.name}
                    uid={data?.url}
                    categories={data?.parent_brand}
                  />
                )}
              </div>
              <Sidebar
                showSidebarMobile={showSidebarMobile}
                setShowSidebarMobile={setShowSidebarMobile}
                products={products}
                setNewProducts={setNewProducts}
                setCurrentPage={setCurrentPage}
                dataFilters={dataFilters}
                initQuery={initQuery}
                dataFilter={dataFilter}
              >
                {data?.subcategories && data?.subcategories?.length > 0 && (
                  <FilterCollapse
                    className={loaded && window.innerWidth < 992 ? '--checkbox' : '--link'}
                    show={true}
                    title="Category"
                  >
                    <ul>
                      {data?.subcategories
                        ?.sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        ?.map(({ name, url }, categoryIndex) => (
                          <li key={categoryIndex}>
                            <span>
                              <Link to={`/${url}`}>{formatNameSubBrand(name, data?.name)}</Link>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </FilterCollapse>
                )}
              </Sidebar>
            </div>
          </SlidebarScrollmagic>
        </aside>
        <section id="main-content" className="main__archive-product__content col-xl-10 col-lg-9">
          <div className="toolbar-mobile">
            <div className="btn-filter" onClick={() => setShowSidebarMobile(true)}>
              Filter
            </div>

            <SortBy
              state={sortBy}
              default_sort={data?.default_sort}
              setState={setSortBy}
              customOrderBy={customOrderBy}
              hideSortby={hideSortby}
            />

            <div className="page">
              <div className="page__total">
                {!loading && <p> {newProducts.length} products found</p>}
              </div>
            </div>
          </div>
          <div className="grid row">
            {!checkExpried(_promotion.p_start_date, _promotion.p_end_date) &&
              _promotion?.promotion_image &&
              _promotion?.promotion_image?.gatsbyImageData && (
                <PromoImage
                  link={_promotion?.promotion_link}
                  image={_promotion?.promotion_image}
                  lifestyle_image={_promotion?.promotion_lifestyle_image}
                  sub_title={_promotion?.promotion_sub_title}
                  tag={_promotion?.promotion_tag}
                  title={_promotion?.promotion_title}
                  button_text={_promotion?.promotion_button_text}
                />
              )}

            {paginate.per_page > 0 && (
              <GridProducts
                sortBy={sortBy}
                products={newProducts}
                currentPage={currentPage}
                totalItem={paginate.per_page}
              />
            )}
          </div>

          {paginate.total > 1 && (
            <div className="pagination justify-content-end">
              <Pagination
                paginate={paginate}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}

          <Desciption data={data?.bottom_description} />
        </section>
      </div>
      {/* )} */}
    </div>
  );
});

export default ArchiveProduct;
