import React, { memo } from 'react';
import Seo from '../components/common/Seo';
import loadable from '@loadable/component';
import '../components/cart-page/styles.scss';

const Layout = loadable(() => import('../layout'));
const HeaderPage = loadable(() => import('../components/cart-page/HeaderPage'));
const MainPage = loadable(() => import('../components/cart-page/MainPage'));

export const Head = ({ location, params, data, pageContext }) => {
  const seo = {
    hideScript: true,
    location,
  };

  return <Seo {...seo}></Seo>;
};

const CartPage = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="page cart-page cart">
        <div className="container-xl">
          <HeaderPage />
          <MainPage />
        </div>
      </div>
    </Layout>
  );
};

export default memo(CartPage);
