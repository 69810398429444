import React, { useState, useEffect, memo } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { loaded } from '../../utils/helpers';

const SlidebarScrollmagic = memo(({ products, children }) => {
  const [duration, setDuration] = useState(0);
  const [num, setNum] = useState(0);

  // useEffect(() => {
  //     if (typeof document !== "undefined" && document?.getElementsByClassName("scrollmagic-pin-spacer")?.length > 0) {
  //         document.getElementsByClassName("scrollmagic-pin-spacer")[0].style.paddingTop = 0;
  //         document.getElementsByClassName("scrollmagic-pin-spacer")[0].style.paddingBottom = 0;
  //     }
  // }, [duration])

  useEffect(() => {
    if (num === 1) {
      document.getElementsByClassName('scrollmagic-pin-spacer')[0].style.paddingTop = 0;
      document.getElementsByClassName('scrollmagic-pin-spacer')[0].style.paddingBottom = 0;

      let sidebarContent = document.getElementById('sidebar-content').clientHeight;
      let _duration = document.getElementById('main-content').clientHeight - sidebarContent;
      if (_duration !== duration) setDuration(_duration);

      setTimeout(() => {
        let scrollmagic_pin = document.getElementsByClassName('scrollmagic-pin-spacer');
        if (scrollmagic_pin.length > 0)
          document.getElementsByClassName(
            'scrollmagic-pin-spacer'
          )[0].style.paddingBottom = `${_duration}px`;
      }, 200);
    }
  }, [products]);

  useEffect(() => {
    function handleChangeDuration() {
      let scoll = this.scrollY || 0;
      if ((window?.innerWidth || 0) >= 992) {
        let main = document.getElementById('main-content').offsetTop || 0;
        if (scoll > main + 58) {
          document.getElementById('sidebar-breadcrumb').classList.add('d-lg-block');
        } else {
          document.getElementById('sidebar-breadcrumb').classList.remove('d-lg-block');
        }

        let footer = document?.getElementById('footer')
          ? document.getElementById('footer').offsetTop - window.innerHeight
          : window.innerHeight;

        if (scoll >= footer) {
          let sidebarContent, _duration;
          if (num === 0) {
            setNum(-1);
            sidebarContent = document.getElementById('sidebar-content').clientHeight;
            _duration = document.getElementById('main-content').clientHeight - sidebarContent;
            setDuration(_duration);
          } else if (num === 1) {
            setNum(-1);
            document.getElementsByClassName('scrollmagic-pin-spacer')[0].style.paddingTop = 0;
            document.getElementsByClassName('scrollmagic-pin-spacer')[0].style.paddingBottom = 0;

            sidebarContent = document.getElementById('sidebar-content').clientHeight;
            _duration = document.getElementById('main-content').clientHeight - sidebarContent;
            if (_duration !== duration) setDuration(_duration);

            setTimeout(() => {
              let scrollmagic_pin = document.getElementsByClassName('scrollmagic-pin-spacer');
              if (scrollmagic_pin.length > 0)
                document.getElementsByClassName(
                  'scrollmagic-pin-spacer'
                )[0].style.paddingTop = `${_duration}px`;
            }, 200);
          }
        } else if (num === -1) {
          setNum(1);
        }
      }
    }

    window?.addEventListener('scroll', handleChangeDuration);

    return () => {
      window?.removeEventListener('scroll', handleChangeDuration);
    };
  }, [num]);

  return (
    <Controller>
      <Scene
        duration={duration}
        pin="#sidebar-content"
        triggerElement="#sidebar"
        triggerHook={0}
        enabled={loaded && (window?.innerWidth || 0) >= 992}
      >
        {children}
      </Scene>
    </Controller>
  );
});

export default SlidebarScrollmagic;
