import React, { memo, useEffect, useRef, useState } from 'react';

const FormGroup = memo(({ className, children, ...props }) => {
    const [state, setState] = useState(false)
    const box = useRef(null)

    function clickActive() {
        if (!state) setState(true)
    }

    useEffect(() => {

        function handleClickOutside(event) {
            if (box.current && !box.current.contains(event.target)) {
                setState(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [box])

    return (
        <div className={`form-group ${className || ""} ${state ? 'active' : ''}`} ref={box} onClick={clickActive} >
            {children}
        </div>
    )
})

export default FormGroup
