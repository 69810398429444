import React from 'react';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import ScrollUpButton from './scroll-up-button/scroll-up-button';
import ResetCss from './reset-css';
import { theme } from './theme';
import NavbarCategories from './navbar-categories';
import SiteTitle from './site-title';
import MainLayout from '../../layout';
import { Link } from 'gatsby';

const Layout = ({ children, links = [], location }) => {
  const len = links?.length || 0;

  return (
    <ThemeProvider theme={theme}>
      <ResetCss />
      <MainLayout location={location}>
        <nav className="blog__breadcrumb container-xl py-lg-3 py-md-2" aria-label="breadcrumb">
          <ol className="breadcrumb bg-white pl-0">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/blog/">THE ACTIVE EDIT</Link>
            </li>
            {links?.map(({ label, slug }, index) => {
              if (len === index + 1) {
                return (
                  <li key={index} className="breadcrumb-item active" aria-current="page">
                    {label}
                  </li>
                );
              }
              return (
                <li key={index} className="breadcrumb-item">
                  <Link to={slug}>{label}</Link>
                </li>
              );
            })}
          </ol>
        </nav>

        <SiteTitle title={'The Active Edit'} />

        <NavbarCategories />

        {children}

        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </MainLayout>
    </ThemeProvider>
  );
};

export default Layout;
