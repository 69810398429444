/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './static/assets/vendors/animate/animate.min.css';
import './static/assets/vendors/bootstrap/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-slidedown/lib/slidedown.css';
import 'flickity/css/flickity.css';
import './src/styles/style.scss';
import * as React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

import PageTemplate from './src/templates/page';
import CollectionTemplate from './src/templates/collection-product';
import BrandProductTemplate from './src/templates/brand-product';
import SinglePostTemplate from './src/templates/blog/single';
import StoreProvider from './src/context/store-context';
import SearchProvider from './src/context/search-context';

const loadableReady = require('@loadable/component').loadableReady;

export const disableCorePrefetching = () => true;

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};

/**
 * wrapRootElement
 */
export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver: require('./src/utils/linkResolver').linkResolver,
        componentResolver: componentResolverFromMap({
          static_page: PageTemplate,
          category: CollectionTemplate,
          brand: BrandProductTemplate,
          blog_post_new: SinglePostTemplate,
        }),
      },
    ]}
  >
    <StoreProvider>
      <SearchProvider>{element}</SearchProvider>
    </StoreProvider>
  </PrismicPreviewProvider>
);

export const onServiceWorkerUpdateReady = () => {
  if (typeof window !== 'undefined') {
    // const answer = window.confirm(
    //   `This application has been updated. ` +
    //     `Reload to display the latest version?`
    // )

    // if (answer === true) {
    window?.location?.reload();
    // }
  }
};
