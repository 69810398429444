exports.linkResolverRichtext = (doc) => {
  slug = doc.slug.replaceAll('--', '-').replaceAll('---', '-');
  switch (doc.type) {
    case 'page':
    case 'static_page':
      return `/${doc.uid}/`;

    case 'brand':
      return doc.uid.replace('brands-', '/brands/').replace(`-${slug}`, `/${slug}/`);

    case 'category':
      return '/' + doc.uid.replace(`-${slug}`, `/${slug}/`);

    case 'blog_post_new':
    case 'blog_category':
    case 'blog_concern':
    case 'blog_ingredient':
    case 'blog_author':
    case 'blog_brand':
      return `/blog/${doc.uid}/`;

    case 'home_page':
      return `/`;

    case 'brands_page':
      return `/brands/`;

    case 'salon_treatments_page':
      return `/salon/treatments/`;

    case 'salon_treatment_pages':
      return `/salon/treatments/${doc.uid}/`;

    case 'salon_static_pages':
      return `/salon/${doc.uid}/`;

    case 'home_salon':
      return `/salon/`;
    case 'promotions_page':
      return `/promotions/`;

    default:
      return `/${doc.uid}/`;
  }
};
