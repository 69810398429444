import React, { memo } from 'react';
import loadable from '@loadable/component';
import Seo from '../components/common/Seo';

const Layout = loadable(() => import('../layout'));
const Alist = loadable(() => import('../components/static_page/alist'));

export const Head = ({ location, params, data, pageContext }) => {
  const seo = {
    title: 'Activeskin A-List',
    description:
      "Activeskin is Australia's destination for all your favourite cosmetics online. Shop now pay later with Afterpay. Free Delivery in Australia on orders over $50.",
    location,
  };

  return <Seo {...seo} />;
};

const AlistPage = memo(({ location }) => {
  return (
    <Layout location={location}>
      <Alist />
    </Layout>
  );
});

export default AlistPage;
