import React, { useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

const MapBlock = React.memo(({ slice, ...props }) => {
  const box = useRef(null);
  const { image } = slice.primary;

  useEffect(() => {
    if (box?.current) {
      const script = document.createElement('script');

      script.src =
        '//r3.dotdigital-pages.com/resources/sharing/embed.js?sharing=lp-embed&domain=r3.dotdigital-pages.com&id=5RQT-5X1%2Fheading%22';
      box?.current?.appendChild(script);
    }
  }, []);

  return (
    <>
      <div ref={box} className="main__newsletter" style={{ padding: '0 0 45px 0' }} />
      <div
        className="main__map-block container-xl text-center"
        style={{ maxWidth: '800px', paddingBottom: '30px' }}
      >
        {image?.gatsbyImageData && (
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || 'activeskin'} />
        )}
      </div>
    </>
  );
});

export default MapBlock;

export const query = graphql`
  fragment HomeSalonDataBodyMapBlock on PrismicHomeSalonDataBodyMapBlock {
    primary {
      image {
        gatsbyImageData
        alt
      }
    }
  }
`;
