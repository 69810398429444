import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react'
import loadable from "@loadable/component";
import Lazyload from 'react-lazyload';
import { graphql } from 'gatsby';

const CustomLink = loadable(() => import('../../../components/common/CustomLink'))
const ReactSlick = loadable(() => import('./../../../components/common/ReactSlick'))

const PromotionSlider = React.memo(({ slice, ...props }) => {

    const { items } = slice

    const settings = {
        dots: false,
        slidesToShow: 5,
        infinite: false,
        swipeToSlide: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 329,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }

    return (
        <Lazyload throttle={500} height={300} scroll={true}>
            <div className="main__promotion -bg-color">
                <div className="container-xl">
                    <ReactSlick
                        {...settings}
                        className="main__promotion__owl-carousel"
                    >
                        {
                            items?.map(({ promotion_image, title, promotion_link, short_description }, i) => (
                                <div key={i} className="item">
                                    <CustomLink
                                        link={promotion_link}
                                        text={
                                            <div>
                                                <GatsbyImage image={promotion_image.gatsbyImageData} alt={promotion_image.alt || title} />
                                                <span className="item__label"><span>{title}</span></span>
                                            </div>
                                        }
                                    />
                                    <div className="item__content">
                                        <h3>
                                            <CustomLink
                                                link={promotion_link}
                                                text={short_description}
                                            />
                                        </h3>
                                    </div>
                                </div>
                            ))
                        }
                    </ReactSlick>
                </div>
            </div>
        </Lazyload>
    )
})

export default PromotionSlider

export const query = graphql`
  fragment HomeSalonDataBodyPromotionsSlider on PrismicHomeSalonDataBodyPromotionsSlider {
    items {
        promotion_image {
        gatsbyImageData
        alt
        }
        promotion_link {
        link_type
        type
        uid
        url
        }
        short_description
        title
    }
  }
`