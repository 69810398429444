import React, { memo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useStore } from '../../hooks';
import ProductBestSeller from './ProductBestSeller';

const BestSellers = memo(() => {
  const { checkout } = useStore();
  const [state, setState] = useState([]);

  const { prismicCartBestSellers } = useStaticQuery(query);
  const {
    data: { title, products },
  } = prismicCartBestSellers || { data: {} };

  function checkExistCart(title) {
    const index = checkout?.lineItems?.findIndex((product) => product?.title === title);

    if (index === -1) return false;
    return true;
  }

  const collections = [
    {
      title: 'All Products',
    },
    {
      title: 'Best Sellers',
    },
    {
      title: 'Sitewide Promotion',
    },
  ];

  const _products = products
    ?.filter(({ product }) => !checkExistCart(product?.title) && !state.includes(product.id))
    .filter((v, i) => i < 3)
    .map((v) => ({
      ...v,
      product: {
        ...v.product,
        collections: [...collections, { title: `Brands/${v.product.vendor}` }],
      },
    }));

  if (_products?.length <= 0) return null;

  return (
    <div className="Cart__best-sellers">
      <div className="Cart__best-sellers__title -title">
        <h3>{title}</h3>
      </div>

      <div className="Cart__best-sellers__products">
        {_products?.map(({ product, image }, index) => (
          <ProductBestSeller key={index} product={product} image={image} setState={setState} />
        ))}
      </div>
    </div>
  );
});

export default BestSellers;

const query = graphql`
  {
    prismicCartBestSellers {
      data {
        title
        products {
          product {
            id
            prismicId
            admin_graphql_api_id
            handle
            title
            body_html
            vendor
            product_type
            images {
              src
            }
            variants {
              id
              admin_graphql_api_id
              inventory_quantity
              price
            }
          }
          image {
            alt
            gatsbyImageData
          }
        }
      }
    }
  }
`;
