import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

const acc = {
  email: process.env.GATSBY_FIREBASE_AUTH_EMAIL,
  password: process.env.GATSBY_FIREBASE_AUTH_PASSWORD,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const firestore = () => firebase.firestore();
export const auth = () => firebase.auth();

export const getCheckoutId = async (email) => {
  try {
    if (!acc?.email || !acc?.password || !config?.apiKey || !config?.authDomain) return false;
    await auth().signInWithEmailAndPassword(acc?.email, acc?.password);
    const snapshot = await firestore().collection('checkout').where('email', '==', email).get();
    const data = snapshot.docs.map((doc) => doc.data()).find((v) => v.email === email);
    if (data) return data?.id;
    return false;
  } catch (error) {
    console.log('getCheckoutId', error);
    return false;
  }
};

export const setCheckoutId = async (data) => {
  try {
    if (!acc?.email || !acc?.password || !config?.apiKey || !config?.authDomain) return false;
    await auth().signInWithEmailAndPassword(acc?.email, acc?.password);
    await firestore().collection('checkout').add(data);
  } catch (error) {
    console.log('setCheckoutId', error);
  }
};
