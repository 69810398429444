import React, { memo } from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/salon/Layout'));
const SliceZone = loadable(() => import('../../containers/salon/static-pages/SliceZone'));
const Banner = loadable(() => import('../../containers/salon/static-pages/Banner'));
const Breadcrumb = loadable(() => import('../../containers/salon/static-pages/Breadcrumb'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicSalonStaticPages: {
      data: { meta_title, meta_description },
    },
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const SingleTreatments = memo((props) => {
  const { data, errors } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const {
    prismicSalonStaticPages: {
      uid,
      data: { body, title },
    },
  } = data;

  return (
    <Layout>
      <div className={`salon-static-page static-page salon-static-page__${uid}`}>
        <div className="container">
          <Breadcrumb label={title} />
        </div>

        <Banner title={title} />

        <SliceZone sliceZone={body} />
      </div>
    </Layout>
  );
});

export default SingleTreatments;

export const query = graphql`
  query querySalonStaticPages($uid: String!) {
    prismicSalonStaticPages(uid: { eq: $uid }) {
      uid
      url
      data {
        title
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...SalonStaticPagesDataBodyTextBlock
          ...SalonStaticPagesDataBodyLocationHours
          ...SalonStaticPagesDataBodyBannerFooter
          ...SalonStaticPagesDataBodyEmbedBookNow
        }
      }
    }
  }
`;
