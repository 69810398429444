export const getDiscount = async (code) => {
  return await fetch(`/api/discount`, {
    method: `POST`,
    body: JSON.stringify({ code }),
    headers: {
      'content-type': `application/json`,
    },
  })
    .then((result) => result.json())
    .then((result) => result.data);
};
